import React, { Component } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { ImSpinner8 } from "react-icons/im";

// Redux
import { connect } from "react-redux";

// Components
import PauseResumeMembershipBtn from "../minis/pauseResumeMembershipBtn";

// Images
import C4yImageWidget from "./c4yImageWidget";

export class WelcomeWidget extends Component {
  constructor() {
    super();
    this.state = {
      dayTime: "React",
      welcomeStatement: "Hallo,",
      greeting: "Wir hoffen es geht Ihnen gut.",
      loadingStats: true,
      stats: {},
    };
  }

  componentDidMount() {
    const getTimeOfDay = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "morning";
      } else if (hour < 18) {
        return "afternoon";
      } else {
        return "evening";
      }
    };
    let welcomeStatement = "";
    let greeting = "";
    switch (getTimeOfDay()) {
      case "morning":
        welcomeStatement = "Guten Morgen";
        greeting = "Wir wünschen Ihnen das Beste für den heutigen Tag.";
        break;
      case "afternoon":
        welcomeStatement = "Guten Tag";
        greeting = "Wir hoffen es geht Ihnen gut.";
        break;
      case "evening":
        welcomeStatement = "Guten Abend";
        greeting = "Wir hoffen Sie hatten einen angenehmen Tag.";
        break;
      default:
        welcomeStatement = "Hallo";
        greeting = "Wir hoffen es geht Ihnen gut.";
    }
    this.setState({
      dayTime: getTimeOfDay(),
      welcomeStatement: welcomeStatement,
      greeting: greeting,
    });

    // load stats if admin
    const membership = this.props.user.credentials.infos.membership;
    if (membership === "admin") {
      axios.get("/admin/stats").then((res) => {
        this.setState({ stats: res.data, loadingStats: false });
      });
    }
  }

  render() {
    const {
      user: {
        loading,
        accStatus,
        credentials: { profile, infos },
      },
    } = this.props;
    const user = this.props.user.credentials;

    // Different content for different users
    let welcomeStatement = this.state.welcomeStatement;
    let welcomeStatementName =
      this.state.welcomeStatement +
      ", " +
      profile.basics.firstname +
      " " +
      profile.basics.lastname;

    const userInactive = (
      <div>
        <p>
          {this.state.greeting} Schließen Sie die unten stehenden Schritte ab,
          um Ihre Mitgliedschaft zu aktivieren.
        </p>
      </div>
    );

    const membershipExpired = (
      <div>
        <p>
          Ihre Mitgliedschaft ist abgelaufen. Verlängern Sie jetzt Ihre
          Mitgliedschaft, um weiterhin von unseren Dienstleistungen zu
          profitieren.
        </p>
      </div>
    );

    const goldActiveNoSuggestions = (
      <div>
        <p>
          {this.state.greeting} Schon bald werden Sie neue
          Date&#8209;Einladungen erhalten, seien Sie gespannt!
        </p>
      </div>
    );

    const goldOpenSuggestions = (
      <div>
        <p>
          Wir haben Matches speziell und individuell für Sie gefunden. Seien Sie
          gespannt und sehen Sie sich diese direkt an.
        </p>
      </div>
    );

    const goldActiveSentInvitation = (
      <div>
        <p>
          {this.state.greeting} Sie haben eine Date-Einladung versendet. Haben
          Sie noch etwas Geduld, schon bald erhalten Sie eine Rückmeldung.
        </p>
      </div>
    );

    const goldDatePaused = (
      <div>
        <p>
          Herzlichen Glückwunsch! Sie haben ein Date. Wir werden Sie in Kürze
          mit dem weiteren Vorgehen bei Ihnen melden.
          <br />
          <br />
          Wir haben Ihre Mitgliedschaft vorerst pausiert. Das bedeutet, dass Sie
          keine weiteren Matches erhalten werden. Sie können Ihre Mitgliedschaft
          jedoch jederzeit wieder fortsetzen.
        </p>
        <PauseResumeMembershipBtn resumeMembership className="mt-10" />
      </div>
    );

    const silverActiveNoInvitations = (
      <div>
        <p>
          {this.state.greeting} Schon bald werden Sie neue
          Date&#8209;Einladungen erhalten, seien Sie gespannt!
        </p>
        {user.silver && user.silver.acceptedInvitations.length > 0 && (
          <PauseResumeMembershipBtn pauseMembership className="mt-10" />
        )}
      </div>
    );

    const silverPendingInvitations = (
      <div>
        <p>
          Sie haben offene Date-Einladungen. Schauen Sie sich diese an und
          entscheiden Sie sich für oder gegen ein Date.
        </p>
      </div>
    );

    const silverActivePaused = (
      <p>
        {this.state.greeting} Ihre Mitgliedschaft ist derzeit pausiert. Sie
        können diese jederzeit fortsetzen.
        <PauseResumeMembershipBtn resumeMembership className="mt-10" />
      </p>
    );

    // Set welcome text
    let userContent = <div>Wir hoffen es geht Ihnen gut.</div>;
    if (accStatus === "inactive") {
      welcomeStatement = welcomeStatementName;
      userContent = userInactive;
    } else if (accStatus === "expired") {
      welcomeStatement = welcomeStatementName;
      userContent = membershipExpired;
    } else if (accStatus === "goldActiveNoMatches") {
      welcomeStatement = welcomeStatementName;
      userContent = goldActiveNoSuggestions;
    } else if (accStatus === "goldActiveMatches") {
      welcomeStatement =
        "Sie haben Matches, " +
        profile.basics.firstname +
        " " +
        profile.basics.lastname;
      userContent = goldOpenSuggestions;
    } else if (accStatus === "goldActiveSentInvitation") {
      welcomeStatement = welcomeStatementName;
      userContent = goldActiveSentInvitation;
    } else if (accStatus === "goldActiveDatePaused") {
      welcomeStatement =
        "Sie haben ein Date, " +
        profile.basics.firstname +
        " " +
        profile.basics.lastname;
      userContent = goldDatePaused;
    } else if (accStatus === "silverActiveNoInvitations") {
      welcomeStatement = welcomeStatementName;
      userContent = silverActiveNoInvitations;
    } else if (accStatus === "silverActivePendingInvitations") {
      welcomeStatement =
        "Sie haben Date-Einladungen, " +
        profile.basics.firstname +
        " " +
        profile.basics.lastname;
      userContent = silverPendingInvitations;
    } else if (accStatus === "silverActivePaused") {
      welcomeStatement = welcomeStatementName;
      userContent = silverActivePaused;
    }

    return (
      <div>
        {!loading && (
          <div className="w-full bg-secondary flex md:flex-row flex-col-reverse rounded-2xl overflow-hidden">
            <div className="md:w-1/2 w-full p-14">
              <h1 className="mb-7">{welcomeStatement}</h1>
              {infos.membership !== "admin" ? (
                <div>
                  {userContent}
                  <div
                    id="membershipInfo"
                    className="mt-10 flex flex-col lg:flex-row items-start lg:items-center lg:gap-10 gap-5"
                  >
                    <div className="border-primary border-2 rounded-full py-2 px-4">
                      {infos.membership === "gold" ? "Gold" : "Silber"}{" "}
                      Mitgliedschaft
                    </div>
                    <div className="inline-flex items-center">
                      <div
                        className={`w-3 h-3 mr-3 rounded-full ${
                          !infos.active && "bg-red-500"
                        } ${infos.active && infos.paused && "bg-yellow-500"} ${
                          infos.active &&
                          !infos.paused &&
                          infos.canReceive &&
                          "bg-green-500"
                        } ${
                          infos.active &&
                          !infos.paused &&
                          !infos.canReceive &&
                          "bg-yellow-500"
                        } `}
                      />
                      {!infos.active && "Inaktiv"}
                      {infos.active && infos.paused && "Pausiert"}
                      {infos.active &&
                        !infos.paused &&
                        infos.canReceive &&
                        "Aktiv"}
                      {infos.active &&
                        !infos.paused &&
                        !infos.canReceive &&
                        "Aktiv, keine neuen Matches"}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div id="overviewContainer" className="mt-8">
                    <div
                      id="totalUsers"
                      className="flex flex-col [@media(min-width:1140px)]:flex-row justify-between mb-10 gap-y-1"
                    >
                      <p>
                        <span className="mr-4">Nutzer gesamt</span>
                        <span className="font-semibold">
                          {this.state.loadingStats ? (
                            <ImSpinner8 className="animate-spin text-xl mx-auto inline" />
                          ) : (
                            this.state.stats.users
                          )}
                        </span>
                      </p>
                      <p>
                        <span className="mr-4">gold</span>
                        <span className="font-semibold">
                          {this.state.loadingStats ? (
                            <ImSpinner8 className="animate-spin text-xl mx-auto inline" />
                          ) : (
                            this.state.stats.gold
                          )}
                        </span>
                      </p>
                      <p>
                        <span className="mr-4">silber</span>
                        <span className="font-semibold">
                          {this.state.loadingStats ? (
                            <ImSpinner8 className="animate-spin text-xl mx-auto inline" />
                          ) : (
                            this.state.stats.silver
                          )}
                        </span>
                      </p>
                    </div>
                    <p className="mb-3">
                      <span className="mr-4">Ausstehende Approvals</span>
                      <span className="font-semibold">
                        {this.state.loadingStats ? (
                          <ImSpinner8 className="animate-spin text-xl mx-auto inline" />
                        ) : (
                          this.state.stats.waiting
                        )}
                      </span>
                    </p>
                    <p>
                      <span className="mr-4">Matches</span>
                      <span className="font-semibold">
                        {this.state.loadingStats ? (
                          <ImSpinner8 className="animate-spin text-xl mx-auto inline" />
                        ) : (
                          this.state.stats.matches
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="border-primary border-2 rounded-full mt-10 py-2 px-4 inline-block">
                    Administrator*in
                  </div>
                </div>
              )}
            </div>
            <C4yImageWidget
              dayTime={this.state.dayTime}
              className="md:w-1/2 w-full bg-cover bg-center md:h-auto h-64"
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

WelcomeWidget.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(WelcomeWidget);
