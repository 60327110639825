import React, { Component } from "react";

import bgImg from "../assets/images/standart-bg.jpg";

export class BackgroundImg extends Component {
  render() {
    let bgImgContainer = {
      height: "80%",
      width: "100%",
      overflow: "hidden",
      position: "absolute",
      zIndex: "-1",
      bottom: "0px",
    };

    const bgImgFrame = {
      minWidth: "100vw",
      height: "100%",
    };

    let bgImgStyle = {
      minHeight: "100%",
      minWidth: "100vw",
      position: "relative",
      top: "100%",
      transform: "translateY(-70%)",
      zIndex: "-2",
      opacity: "1",
    };

    const gradientBG = {
      background:
        "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: "-1",
    };

    if (this.props.fixedPos) {
      bgImgContainer.height = "110vh";
      bgImgContainer.bottom = "auto";
      bgImgContainer.top = "0px";
    }
    if (this.props.opacity) {
      bgImgStyle.opacity = this.props.opacity;
    }
    return (
      <div style={bgImgContainer}>
        <div style={gradientBG}></div>
        <div style={bgImgFrame}>
          <img
            className="max-w-none"
            style={bgImgStyle}
            src={bgImg}
            alt="Hintergrund"
          />
        </div>
      </div>
    );
  }
}

export default BackgroundImg;
