import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";

import NotificationDialog from "../notificationDialog.jsx";

export class PauseResumeMembershipBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  render() {
    this.sendCheckNotification = () => {
      const resume = {
        heading: "Mitgliedschaft fortsetzen?",
        text: "Möchten Sie Ihre Mitgliedschaft jetzt fortsetzen? Sie können Ihre Mitgliedschaft jederzeit wieder pausieren.",
        acceptText: "Fortsetzen",
      };
      const pause = {
        heading: "Mitgliedschaft pausieren?",
        text: "Möchten Sie Ihre Mitgliedschaft jetzt pausieren? Sie können Ihre Mitgliedschaft jederzeit wieder fortsetzen.",
        acceptText: "Pausieren",
      };

      let notificationContent = resume;
      if (this.props.pauseMembership) notificationContent = pause;

      this.setState({
        notification: {
          show: true,
          heading: notificationContent.heading,
          text: notificationContent.text,
          acceptText: notificationContent.acceptText,
          cancelText: "abbrechen",
          type: "check",
          onSuccess: () => {
            this.setInterruption();
          },
        },
      });
    };

    this.setInterruption = () => {
      let url = "/user/resumemembership";
      if (this.props.pauseMembership) url = "/user/pausemembership";

      axios
        .post(url)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          alert(
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
          );
          this.setState({
            notification: {
              show: false,
            },
          });
        });
    };

    return (
      <div className={this.props.className}>
        <button
          onClick={() => this.sendCheckNotification()}
          className="outlineBtn bg-primary hover:bg-accent text-white hover:text-white"
        >
          {this.props.pauseMembership
            ? "Mitgliedschaft pausieren"
            : "Mitgliedschaft jetzt fortsetzen"}
        </button>
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
      </div>
    );
  }
}

PauseResumeMembershipBtn.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PauseResumeMembershipBtn);
