import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/userActions";

//UI Stuff
import logo from "../assets/images/c4y_logo.svg";
import { FiSettings, FiLogOut, FiMenu, FiX } from "react-icons/fi";
import { ImSpinner8 } from "react-icons/im";

const Navbar = (props) => {
  let [open, setOpen] = useState(false);
  let [navLoading, setNavLoading] = useState(false);
  const location = useLocation();

  let navbarType = "signup";
  if (!props.type) {
    if (props.user.authenticated && !props.user.loading) {
      navbarType = props.user.credentials.infos.membership;
    }
  } else {
    navbarType = props.type;
  }

  function handleLogout() {
    setNavLoading(true);
    setTimeout(() => {
      props.logoutUser();
    }, Math.random() * (1200 - 300) + 300);
  }

  //Navabar Styling settings
  const navList = `md:flex md:items-center md:pb-0 z-50 text-center pb-5 absolute md:static mt-5 md:mt-0 left-0 w-full md:w-auto md:pl-0 pl-9 bg-secondary md:bg-transparent transition-all duration-700 ease-in-out ${
    open ? "top-0 opacity-100" : "top-[-300px] opacity-0"
  } md:opacity-100`;
  const navItem =
    "md:ml-10 text-x1 md:my-0 my-5 hover:text-accent transition-color duration-200";
  const navListSimple =
    "flex items-center pb-0 transition-all duration-700 ease-in-out";
  const activeNavLink = "text-accent";

  return (
    <div className={`w-full absolute ${open ? "z-40" : "z-10"}`}>
      {navLoading && (
        <div className="darkOverlay">
          <div>
            <div className="flex bg-secondary p-14 gap-5 rounded-3xl shadow-3xl justify-center items-center">
              <ImSpinner8 className="animate-spin text-xl mx-auto" />
              <p>Sie werden ausgeloggt...</p>
            </div>
          </div>
        </div>
      )}

      <div
        className={`${
          navbarType === "gold" || navbarType === "silver"
            ? "block"
            : "flex flex-col"
        } md:flex md:flex-row items-center justify-between py-8 md:px-14 px-8`}
      >
        <div>
          {navbarType === "login" || navbarType === "signup" ? (
            <a href="https://www.chosenforyou.de/">
              <img src={logo} alt="logo" className="w-28" />
            </a>
          ) : (
            <Link to="/">
              <img src={logo} alt="logo" className="w-28" />
            </Link>
          )}
        </div>
        {(navbarType === "gold" ||
          navbarType === "silver" ||
          navbarType === "admin") && (
          <>
            <div
              onClick={() => setOpen(!open)}
              className="cursor-pointer absolute text-3xl right-8 top-11 md:hidden"
            >
              {open ? <FiX /> : <FiMenu />}
            </div>

            <div
              className={
                "md:z-0 md:w-auto w-full md:h-auto h-72 md:static absolute left-0 md:mt-0 top-28 overflow-hidden"
              }
            >
              {/* gold nav */}
              {navbarType === "gold" && (
                <ul className={navList}>
                  <li className={navItem}>
                    <NavLink
                      to={"/"}
                      end
                      className={(navData) =>
                        navData.isActive || location.pathname === "/dashboard"
                          ? activeNavLink
                          : ""
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  {!props.user.credentials.infos.paused && (
                    <li className={navItem}>
                      <NavLink
                        to={"/matches"}
                        className={(navData) =>
                          navData.isActive ? activeNavLink : ""
                        }
                      >
                        {props.user.accStatus === "goldActiveSentInvitation"
                          ? "Date-Einladung"
                          : "Matches"}
                      </NavLink>
                    </li>
                  )}
                  <li className={navItem}>
                    <NavLink
                      to={"/profile"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      Profil
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/settings"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      <FiSettings className="mx-auto" />
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <Link>
                      <FiLogOut className="mx-auto" onClick={handleLogout} />
                    </Link>
                  </li>
                </ul>
              )}

              {/* silver nav */}
              {navbarType === "silver" && (
                <ul className={navList}>
                  <li className={navItem}>
                    <NavLink
                      to={"/"}
                      end
                      className={(navData) =>
                        navData.isActive || location.pathname === "/dashboard"
                          ? activeNavLink
                          : ""
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/invitations"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      Einladungen
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/profile"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      Profil
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/settings"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      <FiSettings className="mx-auto" />
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <Link>
                      <FiLogOut className="mx-auto" onClick={handleLogout} />
                    </Link>
                  </li>
                </ul>
              )}

              {/* admin nav */}
              {navbarType === "admin" && (
                <ul className={navList}>
                  <li className={navItem}>
                    <NavLink
                      to={"/"}
                      end
                      className={(navData) =>
                        navData.isActive || location.pathname === "/dashboard"
                          ? activeNavLink
                          : ""
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/admin/approvals"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      Approvals
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/admin/users"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      User
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <NavLink
                      to={"/admin/matches"}
                      className={(navData) =>
                        navData.isActive ? activeNavLink : ""
                      }
                    >
                      Matches
                    </NavLink>
                  </li>
                  <li className={navItem}>
                    <Link>
                      <FiLogOut className="mx-auto" onClick={handleLogout} />
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </>
        )}

        {/* login nav */}
        {navbarType === "login" && (
          <ul className={navListSimple}>
            <Link to={"/signup"}>
              <button className="mt-8 md:mt-0 outlineBtn">
                Jetzt Bewerben
              </button>
            </Link>
          </ul>
        )}

        {/* signup nav */}
        {navbarType === "signup" && (
          <ul className={navListSimple}>
            <Link to={"/login"}>
              <button className="hidden md:block outlineBtn">Login</button>
            </Link>
          </ul>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapActionsToProps = { logoutUser };

Navbar.protoTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(Navbar);
