import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/userActions";

// Components
import NotificationDialog from "../components/notificationDialog.jsx";

// Ui
import { HiOutlineChevronDown } from "react-icons/hi";
import { ImSpinner8 } from "react-icons/im";

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membershipStatus: "",
      loading: false,
      email: this.props.user.credentials.infos.email,
      gender: this.props.user.credentials.profile.matching.gender,
      desired: this.props.user.credentials.profile.matching.desiredGender,
      relationForm:
        this.props.user.credentials.profile.matching.desiredRelation,
      errors: {},

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  componentDidMount() {
    // get membership status
    const membership = this.props.user.credentials.infos.membership;
    const active = this.props.user.credentials.infos.active;
    const paused = this.props.user.credentials.infos.paused;
    let membershipStatus = membership + ": nicht aktiv";
    if (active) membershipStatus = membership + ": aktiv";
    if (paused) membershipStatus = membership + ": pausiert";
    this.setState({ membershipStatus: membershipStatus });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {},
    });
  };

  handleDesireSelection = (event) => {
    var desireList = this.state[event.target.name];
    if (event.target.checked) {
      if (!desireList.includes(event.target.value)) {
        desireList.push(event.target.value);
      }
    } else {
      if (desireList.includes(event.target.value)) {
        desireList = desireList.filter((item) => item !== event.target.value);
      }
    }
    this.setState({
      [event.target.name]: desireList,
      errors: {},
    });
  };

  handleForgotPasswordSubmit = () => {
    this.setState({ notification: { show: true, type: "loading" } });
    const userData = {
      email: this.props.user.credentials.infos.email,
    };
    axios
      .post("/accounts/requestPasswordReset", userData)
      .then((res) => {
        this.setState({
          notification: {
            show: true,
            heading: "Passwort zurücksetzen",
            text: "Wir haben Ihnen soeben eine E-Mail mit weiteren Anweisungen geschickt. Es kann einige Minuten dauern, bis Sie die E-Mail erhalten.",
            acceptText: "Alles klar",
            type: "info",
            onSuccess: () => {
              //this.props.logoutUser();
              this.setState({ notification: { show: false } });
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
        alert("Fehler beim Passwort zurücksetzen");
      });
  };

  handleDeleteAccount = () => {
    //Show notification
    this.setState({
      notification: {
        show: true,
        heading: "Mitgliedschaft wirklich kündigen?",
        text: "Wenn Sie Ihren Account löschen, kündigen Sie hierdurch auch unverzüglich Ihre Mitgliedschaft und Sie verlieren sofort alle Vorteile Ihrer Mitgliedschaft. Ihre Mitgliedschaft kann nicht mehr reaktiviert werden.\n\nMöchten Sie wirklich fortfahren?",
        acceptText: "Ja, Mitgliedschaft kündigen",
        acceptColor: "red",
        cancelText: "Nein, doch nicht",
        type: "check",
        onSuccess: () => {
          this.setState({ notification: { show: true, type: "loading" } });
          // set timer for 4 seconds
          setTimeout(() => {
            this.setState({
              notification: {
                show: true,
                heading: "Account wirklich löschen?",
                text: "Sind Sie sich ganz sicher, dass Sie Ihren Account löschen möchten? Hierdurch werden alle Ihre Daten unwiderruflich gelöscht. Ihre Mitgliedschaft wird ebenfalls sofort gekündigt. Möchten Sie ihren Account wirklich löschen?\n\nACHTUNG: Diese Aktion kann nicht rückgängig gemacht werden.",
                acceptText: "Ja, Account löschen",
                acceptColor: "red",
                cancelText: "Nein, doch nicht",
                type: "warning",
                onSuccess: () => {
                  console.log("delete account");
                  this.setState({
                    notification: { show: true, type: "loading" },
                  });
                  axios.delete("/user/delete").then((res) => {
                    this.setState({
                      notification: {
                        show: true,
                        heading: "Account gelöscht",
                        text: "Ihr Chosen For You Account inklusive aller Daten wurde erfolgreich und irreversibel gelöscht.",
                        acceptText: "Alles klar",
                        type: "info",
                        onSuccess: () => {
                          this.props.logoutUser();
                        },
                      },
                    });
                  });
                },
              },
            });
          }, 4000);
        },
      },
    });
  };

  render() {
    const { errors } = this.state;
    const { active, paused, activeUntil } = this.props.user.credentials.infos;

    // handle save settings
    this.saveSettings = () => {
      let error = "";
      if (this.state.desired.length === 0) {
        error = "Bitte wählen Sie mindestens ein gesuchtes Geschlecht aus.";
      }
      if (this.state.relationForm.length === 0) {
        error = "Bitte wählen Sie mindestens eine gesuchte Beziehungsform aus.";
      }
      if (error === "") {
        this.setState({ notification: { show: true, type: "loading" } });
        const userData = {
          email: this.state.email,
          matching: {
            gender: this.state.gender,
            desiredGender: this.state.desired,
            desiredRelation: this.state.relationForm,
          },
        };
        axios
          .post("/user/setsettings", userData)
          .then((res) => {
            this.setState({
              notification: {
                show: true,
                heading: "Einstellungen gespeichert",
                text: "Ihre Einstellungen wurden erfolgreich gespeichert.",
                acceptText: "Alles klar",
                type: "info",
                onSuccess: () => window.location.reload(),
              },
            });
          })
          .catch((err) => {
            this.setState({
              notification: {
                show: true,
                heading: "Fehler",
                text: "Beim Speichern Ihrer Einstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                acceptText: "Alles klar",
                type: "info",
                onSuccess: window.location.reload(),
              },
            });
          });
      } else {
        this.setState({
          errors: { message: error },
        });
      }
    };

    // format date
    this.formatDate = function (isoString) {
      if (isoString === "unlimited") return "3 Partnervorschläge erhalten.";
      const date = new Date(isoString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    return (
      <div className="max-w-xl bg-white p-16 rounded-xl shadow-3xl mx-auto">
        <h1 className="mb-12 text-3xl">Einstellungen</h1>
        {this.state.loading ? (
          <ImSpinner8 className="animate-spin text-xl mx-auto" />
        ) : (
          <div>
            <div className="mb-10">
              <h2 className="font-bold mb-2">Ihre Mitgliedschaft</h2>
              <p>{this.state.membershipStatus}</p>
              {active && !paused && (
                <p className="mt-1">läuft bis {this.formatDate(activeUntil)}</p>
              )}
            </div>
            <div id="reset passwort" className="mb-14">
              <h2 className="font-bold mb-8">Passwort ändern</h2>
              <button
                className="outlineBtn inline hover:cursor-pointer"
                onClick={() => this.handleForgotPasswordSubmit()}
              >
                Passwort jetzt zurücksetzen
              </button>
            </div>
            {/*
            <div id="reset passwort" className="mb-14">
              <h2 className="font-bold mb-6">E-Mail Adresse</h2>
              <input
                id="email"
                name="email"
                type="email"
                className={`loginInput ${errors.email ? "border-error" : ""}`}
                value={this.state.email}
                onChange={(e) => this.handleChange(errors, e)}
              />
            </div>
            */}
            <div id="about">
              <h2 className="font-bold mb-8">Über mich</h2>
              <div className="input-wrapper">
                <label htmlFor="gender">Mein Geschlecht</label>
                <div className="relative">
                  <select
                    id="gender"
                    name="gender"
                    className="loginSelect"
                    value={this.state.gender}
                    onChange={(e) => this.handleChange(errors, e)}
                  >
                    <option value="f">weiblich</option>
                    <option value="m">männlich</option>
                    <option value="d">divers</option>
                  </select>
                  <HiOutlineChevronDown className="absolute right-6 top-1/2 -translate-y-1/2 text-xl" />
                </div>
              </div>

              <div className="mt-10 flex flex-col gap-8">
                <label htmlFor="desired" className="-mb-1">
                  Gesuchtes Geschlecht
                </label>
                <div className="checkboxBtns">
                  <li>
                    <input
                      name="desired"
                      id="female"
                      value="f"
                      type="checkbox"
                      checked={this.state.desired.includes("f")}
                      onChange={(e) => this.handleDesireSelection(e)}
                    />
                    <label htmlFor="female">weiblich</label>
                  </li>

                  <li>
                    <input
                      name="desired"
                      id="male"
                      value="m"
                      type="checkbox"
                      checked={this.state.desired.includes("m")}
                      onChange={(e) => this.handleDesireSelection(e)}
                    />
                    <label htmlFor="male">männlich</label>
                  </li>

                  <li>
                    <input
                      name="desired"
                      id="diverse"
                      value="d"
                      type="checkbox"
                      checked={this.state.desired.includes("d")}
                      onChange={(e) => this.handleDesireSelection(e)}
                    />
                    <label htmlFor="diverse">divers</label>
                  </li>
                </div>

                <label htmlFor="desired">Gesuchtes Beziehungsform</label>
                <div className="checkboxBtns">
                  <li className="w-full">
                    <input
                      name="relationForm"
                      id="firme"
                      value="firme"
                      type="checkbox"
                      checked={this.state.relationForm.includes("firme")}
                      onChange={(e) => this.handleDesireSelection(e)}
                    />
                    <label htmlFor="firme" className="w-full mb-5 text-center">
                      feste Beziehung
                    </label>
                  </li>
                  <li className="w-full">
                    <input
                      name="relationForm"
                      id="loose"
                      value="loose"
                      type="checkbox"
                      checked={this.state.relationForm.includes("loose")}
                      onChange={(e) => this.handleDesireSelection(e)}
                    />
                    <label htmlFor="loose" className="w-full mb-5 text-center">
                      langfristige Liaison
                    </label>
                  </li>
                </div>
              </div>
            </div>
            {this.state.errors.message && (
              <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                <p>{this.state.errors.message}</p>
              </div>
            )}
            <div className="mt-14">
              <div
                className="outlineBtn px-12 inline hover:cursor-pointer text-center"
                onClick={() => this.saveSettings()}
              >
                speichern
              </div>
            </div>
            <hr className="border-primary mt-28 mb-5 w-full" />
            <h2 className="font-bold mb-8">Gefahrenbereich</h2>
            <p>Mitgliedschaft kündigen und Account löschen</p>
            <div
              onClick={() => this.handleDeleteAccount()}
              className="outlineBtn bg-red-500 border-red-500 hover:bg-red-600 hover:border-red-600 text-white hover:text-white hover:cursor-pointer text-center mt-5"
            >
              Account löschen
            </div>
          </div>
        )}
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapActionsToProps = { logoutUser };

export default connect(mapStateToProps, mapActionsToProps)(Settings);
