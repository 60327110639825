import React, { Component } from "react";
import { ImSpinner8 } from "react-icons/im";
import { AiOutlineHeart } from "react-icons/ai";

class NotificationDialog extends Component {
  render() {
    const {
      type,

      heading,
      text,

      acceptText,
      cancelText,
      acceptColor,
      cancelColor,

      userImg,
      userImg2,

      onSuccess,
      onCancel,
    } = this.props;

    const acceptBtnColorVariants = {
      red: "hover:bg-red-500 hover:border-red-500",
      green: "hover:bg-green-500 hover:border-green-500",
    };
    const cancelBtnColorVariants = {
      red: "hover:border-red-500 hover:text-red-500",
      green: "hover:border-green-500 hover:text-green-500",
    };

    return (
      <div className="darkOverlay z-50">
        <div className="bg-secondary max-w-3xl max-h-full overflow-y-scroll p-14 text-center rounded-2xl shadow-3xl">
          {type === "info" && (
            <div>
              <h1 className="mb-6">{heading}</h1>
              <p className="whitespace-pre-line">{text}</p>
              <button className="outlineBtn mt-10" onClick={onSuccess}>
                {acceptText}
              </button>
            </div>
          )}
          {type === "letsMatch" && (
            <div>
              <div className="flex gap-5 justify-center mb-10">
                <img
                  src={userImg}
                  alt="Profilbild"
                  className="w-32 h-32 rounded-full"
                />
                <img
                  src={userImg2}
                  alt="Profilbild"
                  className="w-32 h-32 rounded-full"
                />
              </div>
              <h1 className="mb-6">{heading}</h1>
              <p className="whitespace-pre-line">{text}</p>
              <div className="flex gap-5 mt-10 justify-center">
                <button
                  onClick={onSuccess}
                  className={`outlineBtn text-white bg-primary hover:text-white ${
                    acceptColor
                      ? acceptBtnColorVariants[acceptColor]
                      : "hover:bg-accent"
                  }`}
                >
                  {acceptText}
                </button>
                {cancelText && (
                  <button
                    onClick={onCancel}
                    className={`outlineBtn ${
                      cancelColor && cancelBtnColorVariants[cancelColor]
                    }`}
                  >
                    {cancelText}
                  </button>
                )}
              </div>
            </div>
          )}
          {type === "userRelated" && (
            <div className="flex flex-col md:flex-row gap-10">
              {userImg && (
                <img
                  src={userImg}
                  alt="Profilbild"
                  className="rounded-full mx-auto md:mx-0 w-32 h-32 bg-gray-300"
                />
              )}
              <div className="md:text-left">
                <h1 className="mb-6">{heading}</h1>
                <p className="whitespace-pre-line">{text}</p>
                <div className="flex gap-5 mt-10 justify-center md:justify-start">
                  <button
                    onClick={onSuccess}
                    className={`outlineBtn text-white bg-primary hover:text-white ${
                      acceptColor
                        ? acceptBtnColorVariants[acceptColor]
                        : "hover:bg-accent"
                    }`}
                  >
                    {acceptText}
                  </button>
                  {cancelText && (
                    <button
                      onClick={onCancel}
                      className={`outlineBtn ${
                        cancelColor && cancelBtnColorVariants[cancelColor]
                      }`}
                    >
                      {cancelText}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {type === "acceptedInvitation" && (
            <div className="text-center">
              <div className="text-4xl">
                <AiOutlineHeart className="mx-auto" />
              </div>
              <h1 className="mb-6 mt-8">{heading}</h1>
              <p className="whitespace-pre-line">{text}</p>
              <button
                className={`outlineBtn block mx-auto mt-14 text-white bg-primary hover:text-white ${
                  acceptColor
                    ? acceptBtnColorVariants[acceptColor]
                    : "hover:bg-accent"
                }`}
                onClick={onSuccess}
              >
                {acceptText}
              </button>
              <button
                className={`outlineBtn mt-5 block mx-auto ${
                  cancelColor && cancelBtnColorVariants[cancelColor]
                }`}
                onClick={onCancel}
              >
                {cancelText}
              </button>
            </div>
          )}
          {type === "check" && (
            <div className="text-center">
              <h1 className="mb-6">{heading}</h1>
              <p className="whitespace-pre-line">{text}</p>
              <div className="mt-14 flex justify-center gap-6">
                <button
                  className={`outlineBtn text-white bg-primary hover:text-white ${
                    acceptColor
                      ? acceptBtnColorVariants[acceptColor]
                      : "hover:bg-accent"
                  }`}
                  onClick={onSuccess}
                >
                  {acceptText}
                </button>
                <button
                  className={`outlineBtn ${
                    cancelColor && cancelBtnColorVariants[cancelColor]
                  }`}
                  onClick={onCancel}
                >
                  {cancelText}
                </button>
              </div>
            </div>
          )}
          {type === "warning" && (
            <div className="text-center">
              <h1 className="mb-6 text-red-500">{heading}</h1>
              <p className="whitespace-pre-line">{text}</p>
              <div className="mt-14">
                <div className="mb-7">
                  <button
                    className={`outlineBtn text-white bg-primary hover:text-white ${
                      cancelColor
                        ? acceptBtnColorVariants[acceptColor]
                        : "hover:bg-accent"
                    }`}
                    onClick={onCancel}
                  >
                    {cancelText}
                  </button>
                </div>
                <button
                  className={"text-red-500 hover:text-red-700 text-xs"}
                  onClick={onSuccess}
                >
                  {acceptText}
                </button>
              </div>
            </div>
          )}
          {type === "loading" && (
            <ImSpinner8 className="animate-spin text-xl mx-auto" />
          )}
        </div>
      </div>
    );
  }
}

export default NotificationDialog;
