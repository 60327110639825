import React, { Component } from "react";
import Dashboard from "./dashboard";
import NotificationDialog from "../components/notificationDialog";
import { Navigate } from "react-router-dom";

export class DashboardNotification extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      title: "Es ist ein Fehler aufgetreten",
      message:
        "Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
      button: "Alles klar",
      navigateTo: "",
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    //paid successfully notification
    if (urlParams.has("paymentSuccessful")) {
      this.setState({
        show: true,
        title: "Bezahlung erfolgreich",
        message: "Ihre Zahlung war erfolgreich. Vielen Dank!",
        button: "Zurück zum Dashboard",
      });
    } else if (urlParams.has("renewalSuccessful")) {
      this.setState({
        show: true,
        title: "Bezahlung erfolgreich",
        message:
          "Vielen Dank, dass Sie Ihre Mitgliedschaft verlängert haben. Sie können nun weiterhin von allen Funktionen profitieren.",
        button: "Zurück zum Dashboard",
      });
    } else if (urlParams.has("paymentCanceled")) {
      this.setState({
        show: true,
        title: "Bezahlung abgebrochen",
        message:
          "Um Ihre Mitgliedschaft zu aktivieren, müssen Sie die Bezahlung abschließen.",
        button: "Zurück zum Dashboard",
      });
    } else if (urlParams.has("error")) {
      this.setState({
        show: true,
        title: "Es ist ein Fehler aufgetreten",
        message:
          "Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
        button: "Alles klar",
      });
    }
    window.history.pushState(null, null, "/");
  }

  render() {
    const { show, title, message, button } = this.state;

    return (
      <div>
        {this.state.navigateTo && (
          <Navigate to={this.state.navigateTo} replace={true} />
        )}
        {show && (
          <NotificationDialog
            type="info"
            heading={title}
            text={message}
            acceptText={button}
            onSuccess={() => this.setState({ navigateTo: "/dashboard" })}
          />
        )}
        <Dashboard />
      </div>
    );
  }
}

export default DashboardNotification;
