import React, { Component } from "react";

import ProfileinfoIcon from "../../assets/icons/profileinfoIcon";
import { ImSpinner8 } from "react-icons/im";

export class ToDoWidget extends Component {
  render() {
    return (
      <div className="bg-secondary rounded-2xl p-12 h-full text-center flex  justify-center items-center hover:cursor-pointer hover:scale-[1.02] transition-all ease-in-out duration-200">
        <div>
          {!this.props.loading ? (
            <div>
              <ProfileinfoIcon
                icon={this.props.icon}
                className="h-8 w-8 mb-8 ml-auto mr-auto"
              />
              <p className="text-bol font-bold">{this.props.title}</p>
              <p className="mt-5">{this.props.text}</p>
            </div>
          ) : (
            <ImSpinner8 className="animate-spin text-xl mx-auto" />
          )}
        </div>
      </div>
    );
  }
}

export default ToDoWidget;
