import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";

import NotificationDialog from "../components/notificationDialog.jsx";

//Redux
import { connect } from "react-redux";

// UI
import { ImSpinner8 } from "react-icons/im";
import { HiOutlineTrash } from "react-icons/hi";

export class MatchBar extends Component {
  constructor() {
    super();

    this.state = {
      user01id: "",
      user02id: "",
      loadingUser01: false,
      loadingUser02: false,
      loadedUser01: false,
      loadedUser02: false,
      user01: {},
      user02: {},
      bottomPos: "-bottom-48",

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const userId = name === "user01id" ? "User01" : "User02";
    const user = name === "user01id" ? "user01" : "user02";
    const loadingUser = `loading${userId}`;
    const loadedUser = `loaded${userId}`;

    this.setState({ [name]: value });

    if (value.length === 6) {
      this.setState({ [loadingUser]: true });
      axios.get(`/admin/matchprev/${value.toLowerCase()}`).then((res) => {
        if (res.data.name) {
          this.setState({
            [user]: res.data,
            [loadedUser]: true,
            [loadingUser]: false,
          });
        } else {
          this.setState({ [loadingUser]: false });
        }
      });
    } else if (value.length < 6) {
      this.setState({ [loadingUser]: false, [loadedUser]: false });
    }
  };

  handleDrop(event) {
    event.preventDefault();
    const userId = event.dataTransfer.getData("text/plain");
    this.setState({ [event.target.name]: userId });
    // trigger handleInputChange function
    this.handleInputChange({
      target: { name: event.target.name, value: userId },
    });
  }

  render() {
    const {
      ui: { matchBar },
    } = this.props;

    const matchingFiledBase = "rounded-full h-12 ";
    const inputStyle =
      matchingFiledBase +
      "w-full sm:w-[40vw] 900:!w-52 text-center bg-gray-100 outline-accent";
    const previewStyle =
      matchingFiledBase + "flex items-center gap-6 bg-secondary relative";

    this.removeFromMatch = (userId) => {
      const loadingUser = `loading${
        userId.charAt(0).toUpperCase() + userId.slice(1, -2)
      }`;
      const loadedUser = `loaded${
        userId.charAt(0).toUpperCase() + userId.slice(1, -2)
      }`;
      const user = userId === "user01id" ? "user01" : "user02";

      this.setState({
        [userId]: "",
        [user]: {},
        [loadingUser]: false,
        [loadedUser]: false,
        loadingMatch: false,
      });
    };

    this.showHideMatchBar = () => {
      if (this.state.bottomPos === "bottom-0") {
        this.setState({ bottomPos: "-bottom-48" });
      } else {
        this.setState({ bottomPos: "bottom-0" });
      }
    };

    this.letsMatch = () => {
      if (
        this.state.loadedUser01 &&
        this.state.loadedUser02 &&
        this.state.loadingMatch
      ) {
        axios
          .post("/admin/applysuggestion", {
            user01id: this.state.user01id,
            user02id: this.state.user02id,
          })
          .then((res) => {
            this.setState({
              user01id: "",
              user02id: "",
              loadingUser01: false,
              loadingUser02: false,
              loadedUser01: false,
              loadedUser02: false,
              user01: {},
              user02: {},
              loadingMatch: false,

              notification: {
                show: true,
                heading: "Match erfolgreich!",
                text: "Die beiden Nutzer wurden erfolgreich gematcht.",
                acceptText: "Ok",
                type: "info",
                onSuccess: () => {
                  this.setState({
                    notification: { show: false },
                    loadingMatch: false,
                  });
                },
              },
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loadingMatch: false });
            alert(
              "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuche es erneut."
            );
          });
      } else {
        this.setState({ loadingMatch: false });
        alert(
          "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuche es erneut."
        );
      }
    };

    this.checkMatch = () => {
      if (
        this.state.loadedUser01 &&
        this.state.loadedUser02 &&
        !this.state.loadingMatch
      ) {
        if (this.state.user01.membership !== this.state.user02.membership) {
          this.setState({ loadingMatch: true });
          // match users
          axios
            .post("/admin/checksuggestion", {
              user01id: this.state.user01id,
              user02id: this.state.user02id,
            })
            .then((res) => {
              let notificationHeading =
                this.state.user01.name +
                " und " +
                this.state.user02.name +
                " matchen?";
              let notificationText =
                "Bist du sicher, dass " +
                this.state.user01.name +
                " und " +
                this.state.user02.name +
                " gematched werden sollen?\n\nDieser Vorgang kann nicht rückgängig gemacht werden und der Gold-Nutzer bekommt das Match angezeigt.";
              let notificationAcceptText = "Let's Match";
              let notificationAcceptColor = "green";
              // check if users matched before
              if (res.data.matchedBefore) {
                notificationHeading = "ACHTUNG!";
                notificationText =
                  "Diese beiden Nutzer haben sich bereits gematched. Möchtest du trotzdem fortfahren?\n\n" +
                  this.state.user01.name +
                  " und " +
                  this.state.user02.name +
                  " werden dann erneut gematched.\n\nDieser Vorgang kann nicht rückgängig gemacht werden und der Gold-Nutzer bekommt das Match angezeigt.";
                notificationAcceptText = "Erneut Matchen";
                notificationAcceptColor = "red";
              }

              // show notification
              this.setState({
                notification: {
                  show: true,
                  type: "letsMatch",
                  userImg: this.state.user01.imageUrl,
                  userImg2: this.state.user02.imageUrl,
                  heading: notificationHeading,
                  text: notificationText,
                  acceptText: notificationAcceptText,
                  acceptColor: notificationAcceptColor,
                  cancelText: "abbrechen",
                  onSuccess: () => {
                    this.setState(
                      { notification: { show: true, type: "loading" } },
                      () => {
                        this.letsMatch();
                      }
                    );
                  },
                },
              });
            })
            .catch((err) => {
              console.log(err.response.data.error);
              // show notification
              this.setState({
                notification: {
                  show: true,
                  heading: "Fehler",
                  text: err.response.data.error,
                  acceptText: "Ok",
                  type: "info",
                  onSuccess: () => {
                    this.setState({
                      notification: { show: false },
                      loadingMatch: false,
                    });
                  },
                },
              });
            });
        } else {
          // show notification
          this.setState({
            notification: {
              show: true,
              heading: "Fehler",
              text: "Es können nur Gold und Siber Mitglieder untereinander gematcht werden.",
              acceptText: "Ok",
              type: "info",
              onSuccess: () => {
                this.setState({ notification: { show: false } });
              },
            },
          });
        }
      }
    };

    return (
      <div>
        {matchBar && (
          <div>
            <div
              className={`fixed z-10 bg-white px-10 pb-5 sm:py-5 900:!p-2 flex flex-col sm:flex-row w-full 900:w-11/12 max-w-6xl left-0 900:left-1/2 ${this.state.bottomPos} sm:bottom-0 900:!bottom-8 900:-translate-x-1/2 rounded-t-3xl 900:rounded-full justify-between items-center shadow-3xl transition-all duration-200 ease-in-out`}
            >
              <div
                className="w-full sm:hidden py-5"
                onClick={() => this.showHideMatchBar()}
              >
                <div className="bg-gray-300 w-8 h-1 rounded-full mx-auto" />
              </div>
              <h2 className="hidden sm:block font-cabinet text-xl font-bold 900:ml-5">
                Let's Match
              </h2>
              <div className="w-full sm:w-auto flex flex-col 900:flex-row items-center gap-3 sm:gap-1 900:!gap-7">
                {this.state.loadedUser01 || this.state.loadingUser01 ? (
                  <div>
                    {this.state.loadingUser01 ? (
                      <div className={`${inputStyle} flex items-center`}>
                        <ImSpinner8 className="animate-spin text-xl mx-auto" />
                      </div>
                    ) : (
                      <div className={previewStyle}>
                        <img
                          src={this.state.user01.imageUrl}
                          className="rounded-full h-11 ml-1"
                          alt="match preview"
                        />
                        <p className="mr-6">{this.state.user01.name}</p>
                        <div
                          className="absolute flex text-white text-xl justify-center items-center left-0 top-0 w-full h-full bg-primary rounded-full opacity-0 hover:opacity-90 hover:cursor-pointer"
                          onClick={() => this.removeFromMatch("user01id")}
                        >
                          <HiOutlineTrash />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <input
                    name="user01id"
                    className={inputStyle}
                    maxLength="6"
                    value={this.state.user01id}
                    onDrop={this.handleDrop.bind(this)}
                    placeholder="Profil-ID #1"
                    onChange={(e) => this.handleInputChange(e)}
                  ></input>
                )}

                <p className="hidden sm:block">+</p>

                {this.state.loadedUser02 || this.state.loadingUser02 ? (
                  <div>
                    {this.state.loadingUser02 ? (
                      <div className={`${inputStyle} flex items-center`}>
                        <ImSpinner8 className="animate-spin text-xl mx-auto" />
                      </div>
                    ) : (
                      <div className={previewStyle}>
                        <img
                          src={this.state.user02.imageUrl}
                          className="rounded-full h-11 ml-1"
                          alt="match preview"
                        />
                        <p className="mr-6">{this.state.user02.name}</p>
                        <div
                          className="absolute flex text-white text-xl justify-center items-center left-0 top-0 w-full h-full bg-primary rounded-full opacity-0 hover:opacity-90 hover:cursor-pointer"
                          onClick={() => this.removeFromMatch("user02id")}
                        >
                          <HiOutlineTrash />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <input
                    name="user02id"
                    className={inputStyle}
                    maxLength="6"
                    value={this.state.user02id}
                    onDrop={this.handleDrop.bind(this)}
                    placeholder="Profil-ID #2"
                    onChange={(e) => this.handleInputChange(e)}
                  ></input>
                )}
              </div>
              <button
                className="mt-3 sm:mt-0 w-full sm:w-auto h-12 px-8 bg-accent text-white rounded-full transition-all duration-200 ease-in-out"
                onClick={() => this.checkMatch()}
              >
                {this.state.loadingMatch ? (
                  <ImSpinner8 className="animate-spin text-xl mx-auto" />
                ) : (
                  <div>Match &#8594;</div>
                )}
              </button>
            </div>

            {this.state.notification.show && (
              <NotificationDialog
                type={this.state.notification.type}
                userImg={this.state.notification.userImg}
                userImg2={this.state.notification.userImg2}
                heading={this.state.notification.heading}
                text={this.state.notification.text}
                okText={this.state.notification.okText}
                cancelText={this.state.notification.cancelText}
                acceptText={this.state.notification.acceptText}
                acceptColor={this.state.notification.acceptColor}
                onSuccess={this.state.notification.onSuccess}
                onCancel={() =>
                  this.setState({
                    notification: { show: false },
                    loadingMatch: false,
                  })
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ui: state.UI,
});

MatchBar.protoTypes = {
  ui: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(MatchBar);
