import React, { Component } from "react";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import { AdminMatchesList } from "../components/adminMatchesList.jsx";

export class AllMatches extends Component {
  render() {
    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        <Navbar />
        <Container>
          <h1 className="mb-7 mt-7 text-3xl">Matches</h1>
          <AdminMatchesList />
        </Container>
        <BackgroundImg />
      </div>
    );
  }
}

export default AllMatches;
