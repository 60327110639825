//user reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_IMAGE = "UPDATE_IMAGE";
export const LOADING_USER = "LOADING_USER";
export const SET_SCHEDULED_TALK = "SET_SCHEDULED_TALK";
export const SET_ACC_STATUS = "SET_ACC_STATUS";
//ui reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SHOW_MATCH_BAR = "SHOW_MATCH_BAR";
export const HIDE_MATCH_BAR = "HIDE_MATCH_BAR";
//data reducer types
