import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import NotificationDialog from "../notificationDialog.jsx";

import { IoClose } from "react-icons/io5";
import { ImSpinner8 } from "react-icons/im";

export class GoldInvitationResponses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingResponses: true,
      acceptedHeading: "",
      accepted: [],
      declinedHeading: "",
      declined: [],
      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  updateResponses = () => {};

  componentDidMount() {
    // get all responses
    axios
      .get("/user/goldmatches")
      .then((res) => {
        let acceptedHeading = "";
        if (0 < res.data.accepted.length) {
          if (1 < res.data.accepted.length) {
            acceptedHeading = "Ihre Dates";
          } else {
            acceptedHeading = "Ihr Date";
          }
        }
        let declinedHeading = "";
        if (0 < res.data.declined.length) {
          if (0 < res.data.accepted.length) {
            declinedHeading = "Weitere Rückmeldung";
          } else {
            declinedHeading = "Rückmeldung";
          }
          if (1 < res.data.declined.length) {
            if (0 < res.data.accepted.length) {
              declinedHeading = "Weitere Rückmeldungen";
            } else {
              declinedHeading = "Rückmeldungen";
            }
          }
        }
        this.setState({
          loadingResponses: false,
          acceptedHeading: acceptedHeading,
          accepted: res.data.accepted,
          declinedHeading: declinedHeading,
          declined: res.data.declined,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(
          "Es ist ein Fehler aufgetreten: Bitte versuchen Sie es später erneut."
        );
      });
  }

  render() {
    // get age from birthday
    this.getAge = function (birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    this.removeResponseCheck = (userId) => {
      this.setState({
        notification: {
          show: true,
          heading: "Date-Hinweis entfernen",
          text: "Möchten Sie diesen Hinweis wirklich entfernen?\n\nAchtung: Hierdurch ziehen Sie Ihre Date-Einladung nicht zurück. Ihnen wird lediglich der Hinweis nicht mehr angezeigt.",
          acceptText: "Alles klar",
          cancelText: "abbrechen",
          type: "check",
          onSuccess: () => {
            this.removeResponse(userId, "accepted");
          },
        },
      });
    };

    this.removeResponse = (userId, responseType = "declined") => {
      let accepted = false;
      if (responseType === "accepted") {
        accepted = true;
      }
      let date = "";
      if (responseType === "accepted") {
        date = this.state.accepted.filter((user) => user.handle === userId)[0]
          .date;
      } else {
        date = this.state.declined.filter((user) => user.handle === userId)[0]
          .date;
      }

      axios
        .post("/user/removegoldmatchnotification", {
          userId: userId,
          accepted: accepted,
          date: date,
        })
        .then((res) => {
          const newList = [];
          // remove user from accpeted or declined list
          if (responseType === "accepted") {
            this.state.accepted.forEach((user) => {
              if (user.handle !== userId) {
                newList.push(user);
              }
            });
            this.setState({
              accepted: newList,
              notification: {
                show: false,
              },
            });
          } else {
            this.state.declined.forEach((user) => {
              if (user.handle !== userId) {
                newList.push(user);
              }
            });
            this.setState({
              declined: newList,
              notification: {
                show: false,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert(
            "Es ist ein Fehler aufgetreten: Bitte versuchen Sie es später erneut."
          );
          this.setState({
            notification: {
              show: false,
            },
          });
        });
    };

    return (
      <div>
        {this.state.loadingResponses && (
          <div className="py-20 flex gap-5 items-center">
            <ImSpinner8 className="animate-spin text-xl" />
            <p className="text-lg">Ihre Rückmeldungen werden geladen...</p>
          </div>
        )}
        {!this.state.loadingResponses && 0 < this.state.accepted.length && (
          <div className="mb-14">
            <h1 className="text-3xl mb-8">{this.state.acceptedHeading}</h1>
            <div className="flex flex-col gap-5">
              {this.state.accepted.map((silver) => {
                return (
                  <div
                    className="bg-secondary rounded-xl relative hover:scale-[1.02] transition-all ease-in-out duration-200"
                    key={silver.handle}
                  >
                    <Link to={`/match/${silver.handle}`}>
                      <img
                        src={silver.imageUrl}
                        alt="profile"
                        className="block xs:absolute top-0 left-0 h-full rounded-xl"
                      />
                      <div className="flex flex-col lg:flex-row text-center xs:text-left pb-8 pt-5 xs:py-5 xs:pr-5 xs:pl-36 lg:!pl-24 lg:!p-5 lg:gap-5">
                        <p>
                          <b>{silver.name}</b>
                        </p>
                        <p>{this.getAge(silver.birthday)} Jahre</p>
                        <p>{silver.job}</p>
                      </div>
                    </Link>
                    <div
                      className="absolute text-2xl px-6 py-6 xs:py-0 xs:h-full rounded-xl xs:rounded-none xs:rounded-r-xl top-0 right-0  flex items-center hover:cursor-pointer hover:bg-accent hover:text-white"
                      onClick={() => this.removeResponseCheck(silver.handle)}
                    >
                      <IoClose />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!this.state.loadingResponses && 0 < this.state.declined.length && (
          <div className="mb-14">
            <h1 className="text-3xl mb-4">{this.state.declinedHeading}</h1>
            <p className="mb-8">
              Leider
              {1 < this.state.declined.length
                ? " haben folgende Mitglieder "
                : " hat folgendes Mitglied "}
              nicht rechtzeitig auf Ihre Date-Einladung reagiert oder diese
              nicht agenommen.
            </p>
            <div className="flex flex-col gap-5">
              {this.state.declined.map((silver) => {
                return (
                  <div className="bg-gray-200 rounded-xl relative grayscale">
                    <img
                      src={silver.imageUrl}
                      alt="profile"
                      className="block xs:absolute top-0 left-0 h-full rounded-xl"
                    />
                    <div className="flex flex-col lg:flex-row text-center xs:text-left pb-8 pt-5 xs:py-5 xs:pr-5 xs:pl-36 lg:!pl-24 lg:!p-5 lg:gap-5">
                      <p>
                        <b>{silver.name}</b>
                      </p>
                      <p>{this.getAge(silver.birthday)} Jahre</p>
                      <p>{silver.job}</p>
                    </div>
                    <div
                      className="absolute text-2xl px-6 py-6 xs:py-0 xs:h-full rounded-xl xs:rounded-none xs:rounded-r-xl top-0 right-0  flex items-center hover:cursor-pointer hover:bg-accent hover:text-white"
                      onClick={() => this.removeResponse(silver.handle)}
                    >
                      <IoClose />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
      </div>
    );
  }
}

export default GoldInvitationResponses;
