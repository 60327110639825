import React, { Component } from "react";
import Cropper from "react-easy-crop";
import axios from "axios";

import { generateImage } from "../util/cropImage";

//Redux
import { connect } from "react-redux";
import { updateUserImage } from "../redux/actions/userActions";

//Icons
import { IconContext } from "react-icons";
import { HiOutlinePencil } from "react-icons/hi";
import { ImSpinner8 } from "react-icons/im";

export class ProfileImage extends Component {
  constructor() {
    super();
    this.state = {
      hover: false,
      image: null,
      croppedArea: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      error: {},
      uploading: false,
    };
  }

  uploadImage = () => {
    this.setState({ uploading: true });
    generateImage(this.state.image, this.state.croppedArea).then((image) => {
      const formData = new FormData();
      formData.append("newProfileImage", image, "image.jpg");

      axios
        .post("/user/image", formData)
        .then((res) => {
          this.setState({ uploading: false, image: null });
          const updatedProfileInformation = {
            imageUrl: res.data.imageUrl,
            completedProfile: res.data.completedProfile,
          };
          this.props.updateUserImage(updatedProfileInformation);
        })
        .catch((err) => {
          this.setState({ uploading: false, error: err.response.data });
        });
    });
  };

  handleEditImage = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  handleSelectFile = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ image: reader.result })
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  render() {
    let { size } = this.props;
    if (size === undefined) {
      size = "auto";
    }

    const setCrop = (crop) => {
      this.setState({ crop });
    };
    const setZoom = (zoom) => {
      this.setState({ zoom });
    };
    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
      this.setState({ croppedArea: croppedAreaPixels });
    };

    return (
      <div>
        {this.state.image && (
          <div id="cropperLayover" className="darkOverlay">
            <div className="w-full max-w-md max-h-fit bg-white p-5 rounded-xl relative overflow-hidden">
              <h1 className="text-2xl mb-3">Profilbild zuschneiden</h1>
              <div className="w-full h-[0px] pb-[100%] relative bg-gray-600">
                <Cropper
                  image={this.state.image}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  cropShape="round"
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              {0 < Object.keys(this.state.error).length && (
                <div className="bg-error text-white rounded-lg p-6 mt-4">
                  <p>{Object.values(this.state.error)[0]}</p>
                </div>
              )}
              <div className="flex flex-col 2xs:flex-row justify-between items-center 2xs:items-end mt-7">
                <div
                  onClick={this.uploadImage}
                  className="outlineBtn hover:cursor-pointer"
                >
                  {this.state.uploading ? (
                    <span>
                      <ImSpinner8 className="animate-spin text-xl inline" />
                      <p className="inline ml-3">Uploading...</p>
                    </span>
                  ) : (
                    "Bild verwenden"
                  )}
                </div>
                <div
                  onClick={() => {
                    this.setState({ image: null });
                  }}
                  className="hover:cursor-pointer hover:text-accent 2xs:mt-0 mt-4"
                >
                  abbrechen
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`overflow-hidden bg-gray-200 ${
            this.props.edge ? "" : "rounded-full"
          } ${this.props.className}`}
          style={{ width: size, height: size }}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          onClick={this.handleEditImage}
        >
          <img
            src={this.props.profileImage}
            alt="Profilbild"
            className={`${
              this.props.editable && this.state.hover
                ? "cursor-pointer blur-xl"
                : ""
            }`}
          />
          {this.props.editable && this.state.hover && (
            <IconContext.Provider value={{ size: "30%" }}>
              <div>
                <HiOutlinePencil className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer text-white stroke-1" />
              </div>
            </IconContext.Provider>
          )}
          {this.props.editable && (
            <input
              type="file"
              accept="image/*"
              id="imageInput"
              hidden="hidden"
              onChange={this.handleSelectFile}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profileImage: state.user.credentials.profile.basics.imageUrl,
});
const mapActionsToProps = { updateUserImage };

export default connect(mapStateToProps, mapActionsToProps)(ProfileImage);
