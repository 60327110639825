import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { ImSpinner8 } from "react-icons/im";

export class Container extends Component {
  render() {
    const loading = this.props.loading;

    let width = "max-w-[1400px]";
    if (this.props.fullWidth) {
      width = "w-full";
    }

    return (
      <div>
        {loading ? (
          <div className="w-screen h-screen flex items-center">
            <ImSpinner8 className="animate-spin text-xl mx-auto" />
          </div>
        ) : (
          <div className={`pt-40 pb-24 md:px-14 px-7 ${width} mx-auto`}>
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

export default connect(mapStateToProps)(Container);
