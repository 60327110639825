import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import AuthRoute from "./util/AuthRoute";
import jwtDecode from "jwt-decode";
import axios from "axios";

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";

// User Pages
import Dashboard from "./pages/dashboard";
import DashboardNotification from "./pages/dashboardNotification";
import Matches from "./pages/matches";
import Invitations from "./pages/invitations";
import UsersProfile from "./pages/usersProfile";
import Profile from "./pages/profile";
import EditProfile from "./pages/editProfile";
import Settings from "./pages/settings";
// Admin Pages
import Approvals from "./pages/approvals";
import Users from "./pages/users";
import AdminUser from "./pages/adminUser";
import AllMatches from "./pages/allMatches";
import MatchBar from "./components/matchBar";
// Public Pages
import Login from "./pages/login";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/signup";
// Components
import Footer from "./components/footer";

import Accounts from "./util/accouts";

axios.defaults.baseURL =
  "https://europe-west1-chosenforyou-8f7c2.cloudfunctions.net/api";
//axios.defaults.baseURL = "http://localhost:5000/chosenforyou-8f7c2/europe-west1/api";

const token = localStorage.getItem("FBIdToken");
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <MatchBar />
        <Routes>
          {/* Auth Routes */}
          <Route element={<AuthRoute reqAuth={true} />}>
            <Route element={<Dashboard />} path="/" exact />
            <Route element={<Navigate to="/" />} path="/dashboard" exact />
          </Route>
          {/* End of Auth Routes */}

          {/* User Routes */}
          <Route element={<AuthRoute reqAuth={true} userRoute />}>
            <Route
              element={<DashboardNotification />}
              path="/dashboard/notif"
              exact
            />
            <Route element={<Matches />} path="/matches" exact />
            <Route element={<Matches />} path="/matches/:handle" exact />
            <Route element={<Invitations />} path="/invitations" exact />
            <Route
              element={<UsersProfile />}
              path="/invitations/:handle"
              exact
            />
            <Route element={<UsersProfile />} path="/match/:handle" exact />
            <Route element={<Profile />} path="/profile" exact />
            <Route element={<EditProfile />} path="/profile/edit" exact />
            <Route element={<Settings />} path="/settings" exact />
          </Route>
          {/* End of User Routes */}

          {/* Admin Routes */}
          <Route element={<AuthRoute reqAuth={true} adminRoute />}>
            <Route element={<Approvals />} path="/admin/approvals" exact />
            <Route element={<Users />} path="/admin/users" exact />
            <Route element={<AdminUser />} path="/admin/:handle" exact />
            <Route element={<AllMatches />} path="/admin/matches" exact />
          </Route>
          {/* End of Admin Routes */}

          {/* Public Routes */}
          <Route element={<AuthRoute reqAuth={false} />}>
            <Route element={<Login />} path="/login" exact />
            <Route element={<Signup />} path="/signup" exact />
            <Route element={<ResetPassword />} path="/resetPassword" exact />
          </Route>
          {/* End of Public Routes */}
          <Route element={<Accounts />} path="/accounts" exact />
        </Routes>
      </Router>
      <Footer />
      <div className="bg-white fixed w-[300vw] h-[300vh] -left-[100vw] -top-[100vh] -z-50" />
    </Provider>
  );
}

export default App;
