import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  UPDATE_USER,
  LOADING_USER,
  UPDATE_USER_IMAGE,
  SET_SCHEDULED_TALK,
  SET_ACC_STATUS,
} from "../types";

const initialState = {
  authenticated: false,
  loading: false,
  credentials: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          infos: {
            ...state.credentials.infos,
            ...action.payload.profileData.userInfos,
            completedProfile: action.payload.completedProfile,
          },
          profile: {
            ...state.credentials.profile,
            ...action.payload.profileData,
            basics: {
              ...state.credentials.profile.basics,
              ...action.payload.profileData.basics,
            },
          },
        },
      };
    case UPDATE_USER_IMAGE:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          infos: {
            ...state.credentials.infos,
            completedProfile: action.payload.completedProfile,
          },
          profile: {
            ...state.credentials.profile,
            basics: {
              ...state.credentials.profile.basics,
              imageUrl: action.payload.imageUrl,
            },
          },
        },
      };
    case SET_SCHEDULED_TALK:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          infos: {
            ...state.credentials.infos,
            scheduledTalk: action.payload.scheduledTalk,
          },
        },
      };
    case SET_ACC_STATUS:
      return {
        ...state,
        accStatus: action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
