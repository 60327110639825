import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";

// Components
import NotificationDialog from "../components/notificationDialog.jsx";

// Redux
import { connect } from "react-redux";

import { ImSpinner8 } from "react-icons/im";

export class SilverInvitationsList extends Component {
  constructor() {
    super();
    this.state = {
      filter: "unanswered",
      loadingInvitations: true,
      pendingInvitations: [],
      pendingCountdowns: {},
      acceptedInvitations: [],
      displayedInvitations: null,

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
        onCancel: () => {},
      },
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateCountdown(), 30000);

    //load invitations
    axios
      .get("/user/invitations")
      .then((res) => {
        this.setState(
          {
            pendingInvitations: res.data.pendingInvitations,
            acceptedInvitations: res.data.acceptedInvitations,
            displayedInvitations: res.data.pendingInvitations,
          },
          () => {
            this.setState({ loadingInvitations: false }, () => {
              this.updateCountdown();
            });
          }
        );
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  removeTimedOutInvitation(handle) {
    axios.post("/user/declineinvitation", { handle: handle }).then((res) => {
      window.location.reload();
    });
  }

  updateCountdown() {
    if (
      this.state.loadingInvitations === false &&
      this.state.filter === "unanswered" &&
      this.state.pendingInvitations.length > 0
    ) {
      let pendingCountdowns = {};
      for (let i = 0; i < this.state.pendingInvitations.length; i++) {
        const currentTimestamp = Date.now();
        const requestedAtTimestamp = new Date(
          this.state.pendingInvitations[i].validUntil
        ).getTime();
        const diffTimestamp = requestedAtTimestamp - currentTimestamp;
        // check if diffTimestamp is negative
        if (diffTimestamp < 0) {
          pendingCountdowns[this.state.pendingInvitations[i].handle] =
            "0d 0h 0m";
          this.removeTimedOutInvitation(
            this.state.pendingInvitations[i].handle
          );
        } else {
          const days = Math.floor(diffTimestamp / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (diffTimestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (diffTimestamp % (1000 * 60 * 60)) / (1000 * 60)
          );

          pendingCountdowns[
            this.state.pendingInvitations[i].handle
          ] = `${days}d ${hours}h ${minutes}m`;
        }
      }
      this.setState({ pendingCountdowns: pendingCountdowns });
    }
  }

  render() {
    const invitationFilterItemStyle =
      "hover:cursor-pointer hover:text-accent transition duration-200";

    this.acceptInvitationCheck = (handle) => {
      const user = this.state.displayedInvitations.find(
        (invitation) => invitation.handle === handle
      );
      this.setState({
        notification: {
          show: true,
          heading: "Date-Einladung annehmen?",
          text:
            "Möchten Sie die Date-Einladung von " +
            user.name +
            " annehmen? Sobald Sie die Einladung angenommen haben wird " +
            user.name +
            " informiert und wir werden uns bald mit Ihnen in Verbindung setzen.",
          acceptText: "Einladung annehmen",
          cancelText: "abbrechen",
          userImg: user.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.handleAcceptInvitation(handle);
          },
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
        },
      });
    };

    this.handleAcceptInvitation = (handle) => {
      const user = this.state.displayedInvitations.find(
        (invitation) => invitation.handle === handle
      );
      this.setState(
        {
          notification: {
            show: true,
            type: "loading",
          },
        },
        () => {
          axios
            .post("/user/acceptInvitation", { handle: handle })
            .then((res) => {
              this.setState({
                notification: {
                  show: true,
                  heading: "Herzlichen Glückwunsch!",
                  text:
                    "Sie werden auf ein Date mit " +
                    user.name +
                    " gehen.\nWir werden uns bald mit weiteren Information und einer persönlichen Abstimmung zu Ihrem Date bei Ihnen melden.\n\nMöchten Sie weiterhin Date-Einladungen erhalten oder Ihre Mitgliedschaft vorerst pausieren? Sie können Ihre Mitgliedschaft jederzeit wieder aktivieren.",
                  acceptText: "Mitgliedschaft pausieren",
                  cancelText: "Ich bin offen für weitere Date-Einladungen",
                  type: "acceptedInvitation",
                  onCancel: () => {
                    window.location.reload();
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        notification: {
                          show: true,
                          type: "loading",
                        },
                      },
                      () => {
                        axios.post("/user/pausemembership").then((res) => {
                          this.setState({
                            notification: {
                              show: true,
                              heading: "Vielen Dank!",
                              text: "Ihre Mitgliedschaft wurde erfolgreich pausiert. Sie können Ihre Mitgliedschaft jederzeit wieder aktivieren.",
                              acceptText: "Ok",
                              type: "info",
                              onSuccess: () => {
                                window.location.reload();
                              },
                            },
                          });
                        });
                      }
                    );
                  },
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                notification: {
                  show: false,
                },
              });
              alert("Es ein Fehler aufgetreten: " + err.response.data.error);
            });
        }
      );
    };

    this.declineInvitationCheck = (handle) => {
      const user = this.state.displayedInvitations.find(
        (invitation) => invitation.handle === handle
      );
      this.setState({
        notification: {
          show: true,
          heading: "Date-Einladung ablehnen?",
          text:
            "Möchten Sie die Date-Einladung von " +
            user.name +
            " ablehnen? Sobald Sie die Einladung abgelehnt haben wird " +
            user.name +
            " informiert und Sie werden keine Möglichkeit zur weiteren Kontaktaufnahme haben.",
          acceptText: "Einladung ablehnen",
          acceptColor: "red",
          cancelText: "abbrechen",
          userImg: user.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.handleDeclineInvitation(handle);
          },
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
        },
      });
    };

    this.handleDeclineInvitation = (handle) => {
      const user = this.state.displayedInvitations.find(
        (invitation) => invitation.handle === handle
      );
      this.setState(
        {
          notification: {
            show: true,
            type: "loading",
          },
        },
        () => {
          axios
            .post("/user/declineinvitation", { handle: handle })
            .then((res) => {
              this.setState({
                notification: {
                  show: true,
                  heading: "Einladung abgelehnt",
                  text:
                    "Sie haben die Einladung von " + user.name + " abgelehnt.",
                  acceptText: "ok",
                  type: "info",
                  onSuccess: () => {
                    let pendingInvitations = this.state.pendingInvitations;
                    pendingInvitations = pendingInvitations.filter(
                      (invitation) => invitation.handle !== handle
                    );
                    this.setState({
                      pendingInvitations: pendingInvitations,
                      displayedInvitations: pendingInvitations,
                      notification: {
                        show: false,
                      },
                    });
                  },
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                notification: {
                  show: false,
                },
              });
              alert("Es ein Fehler aufgetreten: " + err.response.data.error);
            });
        }
      );
    };

    this.checkSortOut = (handle) => {
      const user = this.state.displayedInvitations.find(
        (invitation) => invitation.handle === handle
      );
      this.setState({
        notification: {
          show: true,
          heading: "Profil aussortieren?",
          text:
            "Möchten Sie das Profil von " +
            user.name +
            " aussortieren?\n\n Hinweis: Ihre Date-Zusage wird hierdurch nicht zurückgezogen. Lediglich das Profil wird aus Ihrer Zusagen-Liste entfernt.",
          acceptText: "Profil aussortieren",
          acceptColor: "red",
          cancelText: "abbrechen",
          userImg: user.imageUrl,
          type: "userRelated",
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
          onSuccess: () => {
            this.handleSortOut(handle);
          },
        },
      });
    };

    this.handleSortOut = (handle) => {
      this.setState(
        {
          notification: {
            show: true,
            type: "loading",
          },
        },
        () => {
          axios
            .post("/user/sortoutmatch", { handle: handle })
            .then((res) => {
              let acceptedInvitations = this.state.acceptedInvitations;
              acceptedInvitations = acceptedInvitations.filter(
                (invitation) => invitation.handle !== handle
              );
              this.setState({
                acceptedInvitations: acceptedInvitations,
                displayedInvitations: acceptedInvitations,
                notification: {
                  show: false,
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                notification: {
                  show: false,
                },
              });
              alert("Es ein Fehler aufgetreten: " + err.response.data.error);
            });
        }
      );
    };

    // get age from birthday
    this.getAge = function (birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    return (
      <div>
        {this.props.user.loading === false && (
          <div>
            {this.props.user.credentials.infos.membership !== "silver" && (
              <Navigate to="/" />
            )}
          </div>
        )}
        <div id="invitationsFilterBar" className="flex gap-10">
          <div
            className={`${invitationFilterItemStyle} ${
              this.state.filter === "unanswered" && "text-accent"
            }`}
            onClick={() =>
              this.setState({
                filter: "unanswered",
                displayedInvitations: this.state.pendingInvitations,
              })
            }
          >
            Unbeantwortet
          </div>
          <div
            className={`${invitationFilterItemStyle} ${
              this.state.filter === "accepted" && "text-accent"
            }`}
            onClick={() =>
              this.setState({
                filter: "accepted",
                displayedInvitations: this.state.acceptedInvitations,
              })
            }
          >
            Zugesagt
          </div>
        </div>

        <div id="invitationsList" className="mt-10">
          {this.state.loadingInvitations ? (
            <ImSpinner8 className="animate-spin text-xl" />
          ) : (
            <div>
              {this.state.filter === "unanswered" &&
              this.props.user.accStatus === "silverActivePaused" ? (
                <p className="max-w-3xl">-</p>
              ) : (
                <div>
                  {this.state.displayedInvitations.length === 0 ? (
                    <div>
                      {this.state.filter === "unanswered"
                        ? "Sie haben aktuell keine unbeantworteten Einladungen."
                        : "Sie haben noch keine Einladungen angenommen."}
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-10">
                      {this.state.displayedInvitations.map((invitation) => (
                        <div
                          key={invitation.handle}
                          className="bg-secondary mt-14 rounded-3xl w-full sm:w-auto hover:scale-[1.02] transition-all ease-in-out duration-200"
                        >
                          <Link to={`/invitations/${invitation.handle}`}>
                            <div className="text-center px-10 pb-11 -mt-10 sm:min-w-[23rem]">
                              <img
                                className="w-32 rounded-full mx-auto border-secondary border-4 mb-6"
                                src={invitation.imageUrl}
                                alt="profile"
                              ></img>
                              <p>
                                <b>
                                  {invitation.name},{" "}
                                  {this.getAge(invitation.birthday)}
                                </b>
                              </p>
                              <p>{invitation.job}</p>
                              {this.state.filter === "unanswered" && (
                                <p className="mt-5">
                                  Einladung gilt noch{" "}
                                  <b>
                                    {
                                      this.state.pendingCountdowns[
                                        invitation.handle
                                      ]
                                    }
                                  </b>
                                </p>
                              )}
                            </div>
                          </Link>
                          {this.state.filter === "unanswered" && (
                            <div>
                              <p
                                className="hover:cursor-pointer hover:text-red-400 text-xs text-center px-10 mb-5"
                                onClick={() =>
                                  this.declineInvitationCheck(invitation.handle)
                                }
                              >
                                Einladung ablehnen
                              </p>
                              <div
                                className="p-4 bg-primary text-white text-center rounded-b-3xl hover:cursor-pointer hover:bg-accent"
                                onClick={() =>
                                  this.acceptInvitationCheck(invitation.handle)
                                }
                              >
                                Einladung annehmen
                              </div>
                            </div>
                          )}
                          {this.state.filter === "accepted" && (
                            <div
                              className="p-4 bg-primary text-white text-center rounded-b-3xl hover:cursor-pointer hover:bg-red-500"
                              onClick={() =>
                                this.checkSortOut(invitation.handle)
                              }
                            >
                              aussortieren
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            userImg={this.state.notification.userImg}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            okText={this.state.notification.okText}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={this.state.notification.onCancel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

SilverInvitationsList.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SilverInvitationsList);
