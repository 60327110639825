import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import ProfileCard from "../components/profileCard.jsx";
import NotificationDialog from "../components/notificationDialog.jsx";

//Redux
import { connect } from "react-redux";
import { showMatchBar, hideMatchBar } from "../redux/actions/userActions";

// UI
import { ImSpinner8 } from "react-icons/im";

export class AdminUser extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      profileHandle: "",
      adminNotes: "",
      currentlySavedAdminNotes: "",
      savedAdminNotes: "default",
      adminNotesChangeTimeout: null,
      resetAdminNotesColorTimeout: null,
      user: {},
      relations: [],

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        onSuccess: () => {},
      },
    };
  }

  loadProfile = (profileHandle) => {
    window.scrollTo(0, 0);
    //get user information
    axios
      .get(`/admin/user/${profileHandle}`)
      .then((res) => {
        // set user status
        const infos = res.data.infos;
        let status = "aktiv";
        if (infos.active === false) {
          let reasons = "";
          if (infos.adminApproved === false) {
            reasons += "Bestätigung fehlt, ";
          }
          if (infos.completedProfile === false) {
            reasons += "Profil unvollständig, ";
          }
          if (infos.paid === false) {
            reasons += "Zahlung fehlt, ";
          }
          if (infos.scheduledTalk === false) {
            reasons += "kein Gesprächstermin, ";
          }
          status = "inaktiv: " + reasons.slice(0, -2);
        } else {
          // show match bar
          this.props.showMatchBar();
        }
        if (infos.paused === true) {
          status = "pausiert";
        }
        res.data.infos.status = status;

        // get relations
        let relations = null;
        if (infos.membership === "gold") {
          relations = res.data.gold.assignedSuggestions;
        } else if (infos.membership === "silver") {
          relations = Object.keys(res.data.silver.pendingInvitations);
        }
        // get profile images of relations
        if (relations.length > 0) {
          axios
            .post("/admin/user/relationImgs", {
              relations: relations,
            })
            .then((res) => {
              this.setState({
                relations: res.data,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }

        this.setState({
          user: res.data,
          profileHandle: profileHandle,
          loading: false,
          adminNotes: res.data.admin.adminNotes,
          currentlySavedAdminNotes: res.data.admin.adminNotes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    //get profile handle from url
    const url = window.location.href;
    const profileHandle = url.split("/").pop();
    this.loadProfile(profileHandle);
  }

  componentWillUnmount() {
    // hide match bar
    this.props.hideMatchBar();
  }

  handleAdminNoteChange = (event) => {
    clearTimeout(this.state.adminNotesChangeTimeout);
    this.setState({
      adminNotesChangeTimeout: setTimeout(this.updateAdminNotes, 5000),
      adminNotes: event.target.value,
      savedAdminNotes: "false",
    });
  };
  updateAdminNotes = () => {
    clearTimeout(this.state.adminNotesChangeTimeout);
    if (this.state.adminNotes !== this.state.currentlySavedAdminNotes) {
      axios
        .post(`/admin/user/notes/${this.state.profileHandle}`, {
          adminNotes: this.state.adminNotes,
        })
        .then((res) => {
          this.setState(
            {
              savedAdminNotes: "true",
              currentlySavedAdminNotes: this.state.adminNotes,
            },
            () => {
              clearTimeout(this.state.resetAdminNotesColorTimeout);
              this.setState({
                resetAdminNotesColorTimeout: setTimeout(() => {
                  this.setState({
                    savedAdminNotes: "default",
                  });
                }, 1000),
              });
            },
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    // get variables from state
    const { loading, user } = this.state;

    // update approvals
    this.updateApprovals = (
      status,
      userId,
      userName,
      userImg,
      membership,
      email,
    ) => {
      const acceptNotification = {
        heading: userName + " wirklich freischalten?",
        text:
          "Möchten Sie " +
          userName +
          " wirklich freischalten? " +
          userName +
          " kann ab dann Mitglied bei Chosen For You werden. \n\nDieser Vorgang kann nicht rückgängig gemacht werden.",
        acceptText: "akzeptieren",
        acceptColor: "green",
      };
      const declineNotification = {
        heading: userName + " wirklich ablehnen?",
        text:
          "Möchten Sie " +
          userName +
          " wirklich ablehnen? " +
          userName +
          " kann dann nicht mehr Mitglied bei Chosen For You werden. Siber-Mitglieder erhalten außerdem eine E-Mail, dass Ihre Bewerbung abgelehnt wurde. \n\nDieser Vorgang kann im Zweifel aber rückgängig gemacht werden.",
        acceptText: "ablehnen",
        acceptColor: "red",
      };
      let notificationText = acceptNotification;
      if (status === "decline") notificationText = declineNotification;

      this.setState({
        notification: {
          show: true,
          type: "userRelated",
          heading: notificationText.heading,
          text: notificationText.text,
          acceptText: notificationText.acceptText,
          cancelText: "abbrechen",
          acceptColor: notificationText.acceptColor,
          userImg: userImg,
          onSuccess: () => {
            this.setState({
              notification: { show: false },
            });
            let endpoint;
            if (status === "approve") endpoint = "/admin/approvals/approve";
            else if (status === "decline")
              endpoint = "/admin/approvals/decline";
            axios
              .post(endpoint, {
                userId: userId,
                membership: membership,
                email: email,
              })
              .then((res) => {
                this.setState((prevState) => ({
                  user: { ...prevState.user, approvalRequired: false },
                }));
              })
              .catch((err) => {
                alert(
                  "Es ist ein unerwarteter Fehler aufgetreten. \n\n Bitte versuchen Sie es später erneut.",
                );
                console.log(err);
              });
          },
        },
      });
    };

    // get age from birthdate
    const getAge = (birthday) => {
      if (birthday) {
        const today = new Date();
        const birthDate = new Date(birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      } else {
        return "-";
      }
    };

    // convert array to coma separated string
    const arrayToString = (array) => {
      if (array) {
        let string = '"';
        array.forEach((item) => {
          string += item + ", ";
        });
        return string.substring(0, string.length - 2) + '"';
      } else {
        return "-";
      }
    };

    // check if empty or undefined
    const checkEmpty = (value) => {
      if (value === "" || value === undefined) {
        return "-";
      } else {
        return value;
      }
    };

    // download profile as csv
    this.downloadProfile = () => {
      const profile = user.profile;
      const profileData = {
        "Profil-ID": user.infos.handle,
        Vorname: profile.basics.firstname,
        Nachname: checkEmpty(profile.basics.lastname),
        Geburtsdatum: checkEmpty(profile.basics.birthday),
        Alter: getAge(profile.basics.birthday),
        Beruf: checkEmpty(profile.basics.job),
        Wohnort: checkEmpty(profile.protected.city),
        Beziehungsstatus: checkEmpty(profile.info.relationship),
        Figur: checkEmpty(profile.info.shape),
        Größe: checkEmpty(profile.info.height + " cm"),
        Aussehen: checkEmpty(profile.info.type),
        Augenfarbe: checkEmpty(profile.info.eyes),
        Haarfarbe: checkEmpty(profile.info.hair),
        Raucher: checkEmpty(profile.info.smoking),
        Alkohol: checkEmpty(profile.info.drinking),
        Kinder: checkEmpty(profile.basics.children),
        Kinderwunsch: checkEmpty(profile.basics.wantChildren),
        Haustiere: checkEmpty(profile.basics.pets),
        "Sportliche Interessen": checkEmpty(profile.basics.sport),
        Sprachen: checkEmpty('"' + profile.basics.languages + '"'),
        "Weitere Interessen": arrayToString(profile.basics.interests),
        "Über mich": checkEmpty(profile.basics.bio),
        "höchster Bildungsabschluss": checkEmpty(profile.protected.education),
        "Gehalt jährlich": checkEmpty(profile.protected.salary),
        Vermögen: checkEmpty(profile.protected.money),
        Geschlecht: checkEmpty(profile.matching.gender),
        "gesuchtes Geschlecht": arrayToString(profile.matching.desiredGender),
        "gesuchte Beziehung": arrayToString(profile.matching.desiredRelation),
        Notizen: checkEmpty(this.state.adminNotes),
      };

      const csvContent =
        "data:text/csv;charset=utf-8," +
        Object.entries(profileData)
          .map(([key, value]) => `${key},${value}`)
          .join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", user.infos.handle + ".csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    // request deletion of profile
    this.requestDeletion = () => {
      //Show notification
      this.setState({
        notification: {
          show: true,
          heading: "Account wirlich löschen?",
          text:
            "Sie sind im Begriff, den Account von " +
            user.profile.basics.firstname +
            " " +
            user.profile.basics.lastname +
            " (" +
            user.infos.handle +
            ")" +
            " vollständig zu löschen. Möchten Sie wirklich fortfahren?\n\nACHTUNG: Diese Aktion kann nicht rückgängig gemacht werden.",
          acceptText: "Ja, Profil löschen",
          acceptColor: "red",
          cancelText: "abbrechen",
          type: "check",
          onSuccess: () => {
            this.setState({ notification: { show: true, type: "loading" } });
            // set timer for 4 seconds
            setTimeout(() => {
              this.setState({
                notification: {
                  show: true,
                  heading: user.infos.handle + "-Löschung bestätigen",
                  text:
                    "Bitte bestätigen Sie die vollständige Löschung des Profils von " +
                    user.profile.basics.firstname +
                    " " +
                    user.profile.basics.lastname +
                    ".\n\nACHTUNG: Diese Aktion kann nicht rückgängig gemacht werden.",
                  acceptText: "Profil unwiderruflich löschen",
                  acceptColor: "red",
                  cancelText: "Nein, doch nicht",
                  type: "warning",
                  onSuccess: () => {
                    console.log("delete account");
                    this.setState({
                      notification: { show: true, type: "loading" },
                    });
                    // send post request to delete account with handle
                    axios
                      .post("/admin/delete", {
                        handle: user.infos.handle,
                      })
                      .then((res) => {
                        this.setState({
                          notification: {
                            show: true,
                            heading: "Account gelöscht",
                            text: "Dieser Account wurde erfolgreich gelöscht.",
                            acceptText: "Alles klar",
                            type: "info",
                            onSuccess: () => {
                              this.setState({
                                notification: { show: false },
                              });
                            },
                          },
                        });
                      })
                      .catch((err) => {
                        const message = err.response.data.message;
                        this.setState({
                          notification: {
                            show: true,
                            heading: "Fehler",
                            text: message,
                            acceptText: "Alles klar",
                            type: "info",
                            onSuccess: () => {
                              this.setState({
                                notification: { show: false },
                              });
                            },
                          },
                        });
                      });
                  },
                },
              });
            }, 4000);
          },
        },
      });
    };

    //css
    const boxStyle = "bg-white box shadow-3xl shadow-[#1d1d1d29] p-14";
    const listItemStyle = "xs:flex mb-5 xs:mb-0";
    const listKeyWidth = "w-1/2";
    const relationBoxItemStyle = "flex flex-col gap-8 items-start";

    const adminNotesBoxColor = {
      default: "border-primary",
      false: "border-red-500",
      true: "border-green-500",
    };

    const formatedDate = (date) => {
      date = new Date(date);
      if (!isNaN(date.getTime())) {
        let day = String(date.getDate()).padStart(2, "0");
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let year = date.getFullYear();
        return day + "." + month + "." + year;
      } else {
        return "-";
      }
    };

    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            userImg={this.state.notification.userImg}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            okText={this.state.notification.okText}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
        <Navbar />
        <Container>
          {loading ? (
            <div className="w-full h-full flex items-center top-0 left-0">
              <ImSpinner8 className="animate-spin text-xl mx-auto" />
            </div>
          ) : (
            <div className="flex gap-10 pt-24 flex-col lg:flex-row">
              {this.state.user.approvalRequired && (
                <div className="fixed flex justify-center items-center gap-5 lg:block z-10 bg-secondary px-10 lg:pl-10 lg:pr-52 py-5 mx-auto lg:rounded-full bottom-0 lg:bottom-10 w-full lg:w-auto left-0 lg:left-1/2 lg:-translate-x-1/2">
                  <p>
                    <b>
                      Mitgliedschaft von {user.profile.basics.firstname}
                      {user.profile.basics.lastname !== ""
                        ? " " + user.profile.basics.lastname + " "
                        : " "}
                      akzeptieren?
                    </b>
                  </p>
                  <div
                    className="static lg:absolute bg-primary text-white h-full top-0 right-0 py-3 lg:py-5 px-7 lg:pr-32 lg:pl-10 rounded-full hover:cursor-pointer hover:bg-red-500 transition-colors duration-160"
                    onClick={() => {
                      let userName = user.profile.basics.firstname;
                      if (user.lastname) {
                        userName += " " + user.profile.basics.lastname;
                      }
                      this.updateApprovals(
                        "decline",
                        user.infos.handle,
                        userName,
                        user.profile.basics.imageUrl,
                        user.infos.membership,
                        user.infos.email,
                      );
                    }}
                  >
                    &#x2715;
                  </div>
                  <div
                    className="static lg:absolute bg-accent text-white h-full top-0 right-0 py-3 lg:py-5 px-7 lg:px-10 rounded-full hover:cursor-pointer hover:bg-green-500 transition-colors duration-160"
                    onClick={() => {
                      let userName = user.profile.basics.firstname;
                      if (user.lastname) {
                        userName += " " + user.profile.basics.lastname;
                      }
                      this.updateApprovals(
                        "approve",
                        user.infos.handle,
                        userName,
                        user.profile.basics.imageUrl,
                        user.infos.membership,
                        user.infos.email,
                      );
                    }}
                  >
                    &#10003;
                  </div>
                </div>
              )}
              <div id="leftColumn" className="w-full flex flex-col gap-10">
                <ProfileCard
                  key={user.infos.handle}
                  profile={{
                    basics: user.profile.basics,
                    info: user.profile.info,
                  }}
                />
                <div className={boxStyle}>
                  <h2 className="mb-5">Kontakt</h2>
                  <div className="flex flex-col gap-1">
                    <a
                      href={`mailto:${user.infos.email}`}
                      className={listItemStyle}
                    >
                      <div className={listKeyWidth}>
                        <p>E-Mail</p>
                      </div>
                      <div>
                        <p>
                          <b>{user.infos.email}</b>
                        </p>
                      </div>
                    </a>
                    {user.infos.telephone !== "" && (
                      <div className={listItemStyle}>
                        <div className={listKeyWidth}>
                          <p>Telefon</p>
                        </div>
                        <div>
                          <p>
                            <b>{user.infos.telephone}</b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div id="rightColumn" className="w-full flex flex-col gap-10">
                <div id="infosBox" className={boxStyle}>
                  <div
                    id="membershipInfo"
                    className="mb-10 relative -left-3 flex flex-col 2xs:flex-row items-start 2xs:items-center 2xs:gap-10 gap-5"
                  >
                    <div
                      className={`${
                        user.infos.membership === "gold"
                          ? "bg-accent border-accent"
                          : "bg-gray-400 border-gray-400"
                      } border-2 text-white rounded-full py-2 px-4`}
                    >
                      {user.infos.membership === "gold" ? "Gold" : "Silber"}{" "}
                      Mitgliedschaft
                    </div>
                    <div className="inline-flex items-center">
                      <div
                        className={`w-3 h-3 mr-3 rounded-full ${
                          !user.infos.active && "bg-red-500"
                        } ${
                          user.infos.active &&
                          user.infos.paused &&
                          "bg-yellow-500"
                        } ${
                          user.infos.active &&
                          !user.infos.paused &&
                          user.infos.canReceive &&
                          "bg-green-500"
                        } ${
                          user.infos.active &&
                          !user.infos.paused &&
                          !user.infos.canReceive &&
                          "bg-yellow-500"
                        } `}
                      />
                      {!user.infos.active && "inaktiv"}
                      {user.infos.active && user.infos.paused && "pausiert"}
                      {user.infos.active &&
                        !user.infos.paused &&
                        user.infos.canReceive &&
                        "aktiv"}
                      {user.infos.active &&
                        !user.infos.paused &&
                        !user.infos.canReceive &&
                        "aktiv, keine neuen Matches"}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>ID</p>
                      </div>
                      <div className={listKeyWidth}>
                        <p className="uppercase">
                          <b>{user.infos.handle}</b>
                        </p>
                      </div>
                    </div>
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>Status</p>
                      </div>
                      <div className={listKeyWidth}>
                        <p>
                          <b>{user.infos.status}</b>
                        </p>
                      </div>
                    </div>
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>Mitglied seit</p>
                      </div>
                      <div className={listKeyWidth}>
                        <p>
                          <b>{formatedDate(user.infos.createdAt)}</b>
                        </p>
                      </div>
                    </div>
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>aktiv seit</p>
                      </div>
                      <div className={listKeyWidth}>
                        <p>
                          <b>{formatedDate(user.infos.activationDate)}</b>
                        </p>
                      </div>
                    </div>
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>aktiv bis</p>
                      </div>
                      <div className={listKeyWidth}>
                        <p>
                          <b>{formatedDate(user.infos.activeUntil)}</b>
                        </p>
                      </div>
                    </div>
                    {user.infos.s_period && user.infos.s_period < 12 && (
                      <div className={listItemStyle}>
                        <div className={listKeyWidth}>
                          <p>Dauer</p>
                        </div>
                        <div className={listKeyWidth}>
                          <p>
                            <b>{user.infos.s_period + " Monate"}</b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={boxStyle}>
                  <div className="flex xs:hidden flex-col gap-1">
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>Geschlecht</p>
                      </div>
                      <div>
                        <p>
                          <b>
                            {user.profile.matching.gender === "m" && "männlich"}
                            {user.profile.matching.gender === "f" && "weiblich"}
                            {user.profile.matching.gender === "d" && "divers"}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>sucht</p>
                      </div>
                      <div>
                        {user.profile.matching.desiredGender.map(
                          (desiredGender, index) => {
                            return (
                              <p key={index}>
                                <b>
                                  {desiredGender === "m" && "männlich"}
                                  {desiredGender === "f" && "weiblich"}
                                  {desiredGender === "d" && "divers"}
                                </b>
                              </p>
                            );
                          },
                        )}
                        <p>und</p>
                        {user.profile.matching.desiredRelation.map(
                          (desiredRelation, index) => {
                            return (
                              <p key={index}>
                                <b>
                                  {desiredRelation === "firme" &&
                                    "feste Beziehung"}
                                  {desiredRelation === "loose" &&
                                    "langfristige Liaison"}
                                </b>
                              </p>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden xs:flex justify-between gap-1">
                    <div className={relationBoxItemStyle}>
                      <p>Geschlecht</p>
                      <p>sucht</p>
                    </div>
                    <div className={`${relationBoxItemStyle} relative`}>
                      <p className="absolute left-1/2 -translate-x-1/2 top-6 text-xl">
                        &darr;
                      </p>
                      <p>
                        <b>
                          {user.profile.matching.gender === "m" && "männlich"}
                          {user.profile.matching.gender === "f" && "weiblich"}
                          {user.profile.matching.gender === "d" && "divers"}
                        </b>
                      </p>
                      <div>
                        {user.profile.matching.desiredGender.map(
                          (desiredGender, index) => {
                            return (
                              <p key={index}>
                                <b>
                                  {desiredGender === "m" && "männlich"}
                                  {desiredGender === "f" && "weiblich"}
                                  {desiredGender === "d" && "divers"}
                                </b>
                              </p>
                            );
                          },
                        )}
                      </div>
                    </div>
                    <div className={relationBoxItemStyle}>
                      <p>&nbsp;</p>
                      <p>und</p>
                    </div>
                    <div className={relationBoxItemStyle}>
                      <p>&nbsp;</p>
                      <div>
                        {user.profile.matching.desiredRelation.map(
                          (desiredRelation, index) => {
                            return (
                              <p key={index}>
                                <b>
                                  {desiredRelation === "firme" &&
                                    "feste Beziehung"}
                                  {desiredRelation === "loose" &&
                                    "langfristige Liaison"}
                                </b>
                              </p>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={boxStyle}>
                  <h2 className="mb-5">Admin Infos</h2>
                  <div className="flex flex-col gap-1">
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>Wohnort</p>
                      </div>
                      <div>
                        <p>
                          <b>{user.profile.protected.city}</b>
                        </p>
                      </div>
                    </div>
                    <div className={listItemStyle}>
                      <div className={listKeyWidth}>
                        <p>höchster Bildungsabschluss</p>
                      </div>
                      <div>
                        <p>
                          <b>{user.profile.protected.education}</b>
                        </p>
                      </div>
                    </div>
                    {user.profile.protected.salary !== "" && (
                      <div className={listItemStyle}>
                        <div className={listKeyWidth}>
                          <p>Gehalt jährlich</p>
                        </div>
                        <div>
                          <p>
                            <b>{user.profile.protected.salary}</b>
                          </p>
                        </div>
                      </div>
                    )}
                    {user.profile.protected.money !== "" && (
                      <div className={listItemStyle}>
                        <div className={listKeyWidth}>
                          <p>Vermögen</p>
                        </div>
                        <div>
                          <p>
                            <b>{user.profile.protected.money}</b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <h2 className="mt-10 mb-5">Notizen</h2>
                  <textarea
                    className={`${
                      adminNotesBoxColor[this.state.savedAdminNotes]
                    } border-[1px] rounded-lg outline-none h-80 p-4 transition-colors duration-150 ease-in-out`}
                    onChange={(e) => this.handleAdminNoteChange(e)}
                    onBlur={() => this.updateAdminNotes()}
                    maxLength="1000"
                    value={this.state.adminNotes}
                  />
                </div>

                <div className={boxStyle}>
                  <h2 className="mb-10">
                    {user.gold
                      ? "Aktuelle Zuordnungen"
                      : "Aktuelle Date-Einladungen"}
                  </h2>
                  {this.state.relations.length > 0 ? (
                    <div className="flex gap-5 flex-wrap">
                      {this.state.relations.map((match, index) => {
                        return (
                          <Link
                            to={`/admin/${match.userId}`}
                            onClick={() => {
                              this.loadProfile(match.userId);
                              this.setState({ loading: true });
                            }}
                            key={index}
                            className="rounded-full max-w-[8rem] w-full overflow-hidden"
                          >
                            <img src={match.imageUrl} alt="relations" />
                          </Link>
                        );
                      })}
                    </div>
                  ) : (
                    <p>
                      {user.gold
                        ? "Aktuell keine Zuordnungen"
                        : "Aktuell keine Einladungen"}
                    </p>
                  )}
                </div>
                {this.state.user.approvalRequired && <div className="h-10" />}
                <div
                  className="text-right hover:text-accent hover:cursor-pointer"
                  onClick={() => this.downloadProfile()}
                >
                  &darr; Profil herunterladen
                </div>
                {user.infos.active === false && user.infos.paused === false && (
                  <div
                    className="text-right text-red-500 hover:text-red-400 hover:cursor-pointer"
                    onClick={() => this.requestDeletion()}
                  >
                    Profil löschen
                  </div>
                )}
              </div>
            </div>
          )}
        </Container>
        <BackgroundImg fixedPos="true" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapActionsToProps = { showMatchBar, hideMatchBar };

export default connect(mapStateToProps, mapActionsToProps)(AdminUser);
