import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";

// Components
import NotificationDialog from "../components/notificationDialog.jsx";

// Redux
import { connect } from "react-redux";

import { ImSpinner8 } from "react-icons/im";

export class AdminMatchesList extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      filter: "open",
      openMatches: [],
      closedMatches: [],
      loadedClosedMatches: false,
      displayedMatches: [],
      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  componentDidMount() {
    //load matches
    axios
      .post("/admin/matches", { collection: "openMatches" })
      .then((res) => {
        this.setState({
          openMatches: res.data,
          displayedMatches: res.data,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const matchesFilterItemStyle =
      "hover:cursor-pointer hover:text-accent transition duration-200";

    this.changeFilter = (filter) => {
      if (filter === "done" && !this.state.loadedClosedMatches) {
        this.setState({ loading: true });
        axios
          .post("/admin/matches", { collection: "closedMatches" })
          .then((res) => {
            this.setState(
              {
                closedMatches: res.data,
                displayedMatches: res.data,
                loadedClosedMatches: true,
              },
              () => {
                this.setState({
                  filter: filter,
                  loading: false,
                  displayedMatches: this.state.closedMatches,
                });
              }
            );
          })
          .catch((err) => console.log(err));
      } else {
        this.setState({
          filter: filter,
          displayedMatches:
            filter === "open"
              ? this.state.openMatches
              : this.state.closedMatches,
        });
      }
    };

    this.closeMatch = (matchId) => {
      this.setState({ loading: true });
      axios
        .post("/admin/matches/close", { matchId: matchId })
        .then((res) => {
          const match = this.state.openMatches.find(
            (match) => match.matchId === matchId
          );
          this.setState(
            {
              openMatches: this.state.openMatches.filter(
                (match) => match.matchId !== matchId
              ),
              displayedMatches: this.state.displayedMatches.filter(
                (match) => match.matchId !== matchId
              ),
              closedMatches: [match, ...this.state.closedMatches],
            },
            () => {
              this.setState({ loading: false });
            }
          );
        })
        .catch((err) => console.log(err));
    };

    this.reopenMatch = (matchId) => {
      this.setState({ loading: true });
      axios
        .post("/admin/matches/reopen", { matchId: matchId })
        .then((res) => {
          const match = this.state.closedMatches.find(
            (match) => match.matchId === matchId
          );
          this.setState(
            {
              closedMatches: this.state.closedMatches.filter(
                (match) => match.matchId !== matchId
              ),
              displayedMatches: this.state.displayedMatches.filter(
                (match) => match.matchId !== matchId
              ),
              openMatches: [match, ...this.state.openMatches],
            },
            () => {
              this.setState({ loading: false });
            }
          );
        })
        .catch((err) => console.log(err));
    };

    // get age from birthday
    this.getAge = function (birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    // format date
    this.formatDate = function (isoString) {
      const date = new Date(isoString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    return (
      <div>
        <div id="matchesFilterBar" className="flex gap-10 relative z-20">
          <div
            className={`${matchesFilterItemStyle} ${
              this.state.filter === "open" && "text-accent"
            }`}
            onClick={() => this.changeFilter("open")}
          >
            offen
          </div>
          <div
            className={`${matchesFilterItemStyle} ${
              this.state.filter === "done" && "text-accent"
            }`}
            onClick={() => this.changeFilter("done")}
          >
            abgeschlossen
          </div>
        </div>

        <div id="matchesList" className="mt-10">
          {this.state.loading ? (
            <ImSpinner8 className="animate-spin text-xl" />
          ) : (
            <div>
              {this.state.displayedMatches.length === 0 ? (
                <div>
                  {this.state.filter === "open"
                    ? "Aktuell sind keine Matches offen."
                    : "Es wurden noch keine Matches abgeschlossen."}
                </div>
              ) : (
                <div className="flex flex-wrap gap-10">
                  {this.state.displayedMatches.map((match) => (
                    <div
                      key={match.matchId}
                      className="bg-secondary relative mt-20 rounded-3xl w-full sm:w-auto"
                    >
                      <div className="text-center px-10 pb-11 mt-28 sm:min-w-[23rem]">
                        <Link to={`/admin/${match.silver.userId}`}>
                          <img
                            className="absolute hover:opacity-80 top-0 left-1/2 -translate-y-1/2 -translate-x-[10%] w-36 rounded-full mx-auto border-secondary border-4 mb-6"
                            src={match.silver.imageUrl}
                            alt="silver user profile"
                          ></img>
                        </Link>
                        <Link to={`/admin/${match.gold.userId}`}>
                          <img
                            className="absolute hover:opacity-80 top-0 left-1/2 -translate-y-1/2 -translate-x-[90%] w-36 rounded-full mx-auto border-secondary border-4 mb-6"
                            src={match.gold.imageUrl}
                            alt="gold user profile"
                          ></img>
                        </Link>
                        <Link to={`/admin/${match.gold.userId}`}>
                          <p className="hover:text-accent">
                            <b>
                              {match.gold.name},{" "}
                              {this.getAge(match.gold.birthday)}
                            </b>
                          </p>
                        </Link>
                        <p>&</p>
                        <Link to={`/admin/${match.silver.userId}`}>
                          <p className="hover:text-accent">
                            <b>
                              {match.silver.name},{" "}
                              {this.getAge(match.silver.birthday)}
                            </b>
                          </p>
                        </Link>
                        <p className="mt-6 mb-10">
                          {`${
                            this.state.filter === "open"
                              ? " Match am "
                              : "Abgeschlossen am "
                          } `}
                          {this.formatDate(match.matchDate)}
                        </p>
                        <div className="mb-10">
                          <a
                            className="outlineBtn hover:cursor-pointer inline"
                            href={`mailto:${match.gold.email}`}
                          >
                            E-Mail {match.gold.name}
                          </a>
                        </div>
                        <div>
                          <a
                            className="outlineBtn hover:cursor-pointer inline"
                            href={`mailto:${match.silver.email}`}
                          >
                            E-Mail {match.silver.name}
                          </a>
                        </div>
                      </div>
                      {this.state.filter === "open" && (
                        <div
                          className="p-4 bg-primary text-white text-center rounded-b-3xl hover:cursor-pointer hover:bg-accent"
                          onClick={() => this.closeMatch(match.matchId)}
                        >
                          Date abgeschlossen
                        </div>
                      )}
                      {this.state.filter === "done" && (
                        <div
                          className="p-4 bg-primary text-white text-center rounded-b-3xl hover:cursor-pointer hover:bg-red-500"
                          onClick={() => this.reopenMatch(match.matchId)}
                        >
                          Wiedereröffnen
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            userImg={this.state.notification.userImg}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            okText={this.state.notification.okText}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

AdminMatchesList.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(AdminMatchesList);
