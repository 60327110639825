import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";

//Redux
import { connect } from "react-redux";
import { showMatchBar, hideMatchBar } from "../redux/actions/userActions";

// Ui
import { ImSpinner8 } from "react-icons/im";

export class Users extends Component {
  constructor() {
    super();
    this.state = {
      membershipShown: "all",
      statusShown: "active",
      loadingUsers: true,
      maxUsersPerPage: 10,
      numberOfPages: 1,
      currentPage: 0,
      users: [],
      userIndexesToDisplay: [],
      usersOnPage: [],
      dragging: false,
      dragPreviewName: "",
      dragPreviewImg: "",
    };
  }

  componentDidMount() {
    axios
      .get("/admin/users")
      .then((res) => {
        this.setState(
          {
            users: res.data,
          },
          () => {
            this.updateUserIndexesToDisplay();
          },
        );
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    this.props.hideMatchBar();
  }

  handleDragStart(userId, imageUrl, firstname, lastname, event) {
    event.dataTransfer.setData("text/plain", userId);
    event.dataTransfer.setDragImage(
      this.state.dragPreview,
      this.state.dragPreview.offsetWidth / 2,
      this.state.dragPreview.offsetHeight / 2,
    );
  }

  render() {
    const filterBtnStyle = "hover:text-accent hover:cursor-pointer";

    // update drag preview
    this.updateDragPreview = (imageUrl, firstname, lastname) => {
      this.setState({
        dragPreviewName: firstname + " " + lastname,
        dragPreviewImg: imageUrl,
      });
    };

    // clear drag preview
    this.clearDragPreview = () => {
      this.setState({
        dragPreviewName: "",
        dragPreviewImg: "",
      });
    };

    // change page
    this.changePage = (newPage) => {
      const usersOnPage = this.state.userIndexesToDisplay.slice(
        newPage * this.state.maxUsersPerPage,
        newPage * this.state.maxUsersPerPage + this.state.maxUsersPerPage,
      );
      this.setState({ currentPage: newPage, usersOnPage: usersOnPage });
    };

    // change membership filter
    this.changeMembershipFilter = (e) => {
      this.setState({ membershipShown: e.target.getAttribute("value") }, () => {
        this.updateUserIndexesToDisplay();
      });
    };

    // change status filter
    this.changeStatusFilter = (e) => {
      this.setState({ statusShown: e.target.getAttribute("value") }, () => {
        this.updateUserIndexesToDisplay();
      });
    };

    // get indexes of users to display
    this.updateUserIndexesToDisplay = () => {
      this.setState({ loadingUsers: true }, () => {
        let userIndexesToDisplay = [];
        if (this.state.statusShown === "active") {
          this.props.showMatchBar();
        } else {
          this.props.hideMatchBar();
        }
        this.state.users.forEach((user, index) => {
          if (user.status === "active" && user.canReceive === false) {
            user.status = "inactive";
          }
          if (
            user.membership === this.state.membershipShown ||
            this.state.membershipShown === "all"
          ) {
            if (
              user.status === this.state.statusShown ||
              this.state.statusShown === "all"
            ) {
              userIndexesToDisplay.push(index);
            }
          }
        });
        this.setState(
          {
            userIndexesToDisplay: userIndexesToDisplay,
            loadingUsers: false,
          },
          () => {
            // get number of pages
            const numberOfPages = Math.ceil(
              this.state.userIndexesToDisplay.length /
                this.state.maxUsersPerPage,
            );
            // set new number of pages and reset current page
            this.setState(
              { numberOfPages: numberOfPages, currentPage: 0 },
              () => {
                this.changePage(0);
              },
            );
          },
        );
      });
    };

    // calculate age from birthday
    const getAge = (birthday) => {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        <Navbar />
        <Container>
          <h1 className="text-3xl mb-8">Users</h1>
          <div
            id="usersNavigation"
            className="flex flex-col sm:flex-row gap-5 sm:gap-0 relative z-20"
          >
            <div className="w-1/2 flex gap-8">
              <p
                className={`${filterBtnStyle} ${
                  this.state.membershipShown === "all"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeMembershipFilter}
                value="all"
              >
                alle
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.membershipShown === "gold"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeMembershipFilter}
                value="gold"
              >
                gold
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.membershipShown === "silver"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeMembershipFilter}
                value="silver"
              >
                silber
              </p>
            </div>
            <div className="w-1/2 flex gap-8 sm:justify-end">
              <p
                className={`${filterBtnStyle} ${
                  this.state.statusShown === "active"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeStatusFilter}
                value="active"
              >
                aktiv
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.statusShown === "paused"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeStatusFilter}
                value="paused"
              >
                pausiert
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.statusShown === "inactive"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeStatusFilter}
                value="inactive"
              >
                inaktiv
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.statusShown === "all"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeStatusFilter}
                value="all"
              >
                alle
              </p>
            </div>
          </div>
          <div id="usersList" className="mt-24 900:mt-12">
            {this.state.loadingUsers ? (
              <ImSpinner8 className="animate-spin text-xl mx-auto" />
            ) : (
              <div>
                {this.state.usersOnPage.map((userIndex) => {
                  const user = this.state.users[userIndex];
                  return (
                    <Link
                      to={"/admin/" + user.userId}
                      draggable={true}
                      onMouseEnter={() =>
                        this.updateDragPreview(
                          user.imageUrl,
                          user.firstname,
                          user.lastname,
                        )
                      }
                      onMouseLeave={() => this.clearDragPreview()}
                      onDragStart={this.handleDragStart.bind(
                        this,
                        user.userId,
                        user.imageUrl,
                        user.firstname,
                        user.lastname,
                      )}
                      key={user.userId}
                      className="flex flex-col 900:flex-row relative bg-secondary gap-2 900:gap-0 900:h-16 pt-20 pb-5 900:py-0 900:pl-28 900:pr-14 mb-16 900:mb-5 rounded-2xl 900:rounded-full justify-between items-center"
                    >
                      <img
                        src={user.imageUrl}
                        alt="Profilbild"
                        className="h-24 900:h-14 rounded-full absolute -top-10 900:left-1 900:top-1"
                      />
                      <div className="900:w-3/12 overflow-x-scroll hide-scrollbar">
                        <p className="whitespace-nowrap font-semibold">
                          <span>{user.firstname}</span>
                          {user.lastname && <span> {user.lastname}</span>}
                        </p>
                      </div>
                      <p
                        className={`uppercase 900:w-1/12 whitespace-nowrap overflow-x-scroll hide-scrollbar ${
                          user.membership === "gold"
                            ? "text-accent"
                            : "text-gray-400"
                        }`}
                      >
                        {user.membership === "gold" && "gold"}
                        {user.membership === "silver" && "silber"}
                      </p>
                      <div className="900:w-2/12">
                        <p className="whitespace-nowrap overflow-x-scroll hide-scrollbar">
                          <span className="mr-1">{`${
                            user.gender === "f" ? "w" : user.gender
                          }`}</span>
                          <span className="mr-1">&rarr;</span>
                          {user.desired.map((desiredGender, i) => {
                            return (
                              <span key={i}>
                                {`${
                                  desiredGender === "f" ? "w" : desiredGender
                                }${i < user.desired.length - 1 ? ", " : ""}`}
                              </span>
                            );
                          })}
                        </p>
                      </div>
                      {user.birthday && (
                        <p className="900:w-1/12 hidden 900:block whitespace-nowrap overflow-x-scroll hide-scrollbar">
                          {getAge(user.birthday)}
                        </p>
                      )}
                      {user.job && (
                        <p className="900:w-3/12 hidden 900:block whitespace-nowrap overflow-x-scroll hide-scrollbar">
                          {user.job}
                        </p>
                      )}
                      <p className="900:w-1/12 text-center 900:text-right uppercase">
                        {user.userId}
                      </p>
                    </Link>
                  );
                })}
                {this.state.numberOfPages > 1 && (
                  <div className="flex bg-white mt-20 mb-8 py-4 px-10 rounded-full justify-between">
                    <div>
                      <p>
                        {this.state.currentPage + 1} /{" "}
                        {this.state.numberOfPages}
                      </p>
                    </div>
                    <div className="xs:flex hidden gap-3">
                      {this.state.currentPage > 2 && <span>...</span>}
                      {Array.from(
                        { length: this.state.numberOfPages },
                        (_, i) =>
                          Math.abs(i - this.state.currentPage) <= 2 ? (
                            <p
                              key={i}
                              className={`hover:cursor-pointer ${
                                i === this.state.currentPage
                                  ? "text-accent"
                                  : ""
                              }`}
                              onClick={() => this.changePage(i)}
                            >
                              {i + 1}
                            </p>
                          ) : null,
                      )}
                      {this.state.currentPage + 2 <
                        this.state.numberOfPages - 1 && <span>...</span>}
                    </div>
                    <div className="xs:hidden flex gap-5 text-2xl">
                      {this.state.currentPage > 0 && (
                        <p
                          className="hover:cursor-pointer"
                          onClick={() =>
                            this.changePage(this.state.currentPage - 1)
                          }
                        >
                          &larr;
                        </p>
                      )}
                      {this.state.currentPage <
                        this.state.numberOfPages - 1 && (
                        <p
                          className="hover:cursor-pointer"
                          onClick={() =>
                            this.changePage(this.state.currentPage + 1)
                          }
                        >
                          &rarr;
                        </p>
                      )}
                    </div>
                    <div>
                      <select
                        value={this.state.currentPage}
                        onChange={(e) =>
                          this.changePage(parseInt(e.target.value))
                        }
                      >
                        {Array.from(
                          { length: this.state.numberOfPages },
                          (_, i) => (
                            <option key={i} value={i}>
                              {i + 1}
                            </option>
                          ),
                        )}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              ref={(el) => (this.state.dragPreview = el)}
              className="flex rounded-full h-12 items-center gap-6 bg-secondary absolute -top-[500vh] -left-[500vw]"
            >
              <img
                src={this.state.dragPreviewImg}
                className="rounded-full h-11 w-11 bg-slate-300 ml-1"
                alt="profile"
              />
              <p className="mr-6">{this.state.dragPreviewName}</p>
            </div>
          </div>
        </Container>
        <BackgroundImg />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapActionsToProps = { showMatchBar, hideMatchBar };

export default connect(mapStateToProps, mapActionsToProps)(Users);
