import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Navigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import ProfileCard from "../components/profileCard.jsx";
import NotificationDialog from "../components/notificationDialog.jsx";

import { ImSpinner8 } from "react-icons/im";

export class UsersProfile extends Component {
  constructor() {
    super();
    this.state = {
      loadingProfile: true,
      profile: {},
      pending: false,
      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  loadProfile = (profileHandle) => {
    axios
      .post("/user/loadinvitationprofile", { handle: profileHandle })
      .then((res) => {
        this.setState({
          profile: res.data,
          loadingProfile: false,
          pending: res.data.pending,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(
          "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut."
        );
        this.setState({
          navigateTo: "/invitations",
        });
      });
  };

  componentDidMount() {
    //get profile handle from url
    window.scrollTo(0, 0);
    const url = window.location.href;
    const profileHandle = url.substring(url.length - 6, url.length);
    this.loadProfile(profileHandle);
  }

  render() {
    const { profile, pending } = this.state;
    let userName = "";
    if (this.state.loadingProfile === false) {
      userName = profile.basics.firstname;
      if (profile.basics.lastname !== "") {
        userName += " " + profile.basics.lastname;
      }
    }

    this.acceptInvitationCheck = () => {
      this.setState({
        notification: {
          show: true,
          heading: "Date-Einladung annehmen?",
          text:
            "Möchten Sie die Date-Einladung von " +
            userName +
            " annehmen? Sobald Sie die Einladung angenommen haben wird " +
            userName +
            " informiert und wir werden uns bald mit Ihnen in Verbindung setzen.",
          acceptText: "Einladung annehmen",
          cancelText: "abbrechen",
          userImg: profile.basics.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.handleAcceptInvitation();
          },
        },
      });
    };

    this.handleAcceptInvitation = () => {
      this.setState(
        {
          notification: {
            show: true,
            type: "loading",
          },
        },
        () => {
          axios
            .post("/user/acceptInvitation", { handle: profile.handle })
            .then((res) => {
              this.setState({
                notification: {
                  show: true,
                  heading: "Herzlichen Glückwunsch!",
                  text:
                    "Sie werden auf ein Date mit " +
                    userName +
                    " gehen.\nWir werden uns bald mit weiteren Information und einer persönlichen Abstimmung zu Ihrem Date bei Ihnen melden.\n\nMöchten Sie weiterhin Date-Einladungen erhalten oder Ihre Mitgliedschaft vorerst pausieren? Sie können Ihre Mitgliedschaft jederzeit wieder aktivieren.",
                  acceptText: "Mitgliedschaft pausieren",
                  cancelText: "Ich bin offen für weitere Date-Einladungen",
                  type: "acceptedInvitation",
                  onSuccess: () => {
                    this.setState(
                      {
                        notification: {
                          show: true,
                          type: "loading",
                        },
                      },
                      () => {
                        axios.post("/user/pausemembership").then((res) => {
                          this.setState({
                            notification: {
                              show: true,
                              heading: "Vielen Dank!",
                              text: "Ihre Mitgliedschaft wurde erfolgreich pausiert. Sie können Ihre Mitgliedschaft jederzeit wieder aktivieren.",
                              acceptText: "Ok",
                              type: "info",
                              onSuccess: () => {
                                window.location.reload();
                              },
                            },
                          });
                        });
                      }
                    );
                  },
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                notification: {
                  show: false,
                },
              });
              alert("Es ein Fehler aufgetreten: " + err.response.data.error);
            });
        }
      );
    };

    this.declineInvitationCheck = () => {
      this.setState({
        notification: {
          show: true,
          heading: "Date-Einladung ablehnen?",
          text:
            "Möchten Sie die Date-Einladung von " +
            userName +
            " ablehnen? Sobald Sie die Einladung abgelehnt haben wird " +
            userName +
            " informiert und Sie werden keine Möglichkeit zur weiteren Kontaktaufnahme haben.",
          acceptText: "Einladung ablehnen",
          acceptColor: "red",
          cancelText: "abbrechen",
          userImg: profile.basics.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.handleDeclineInvitation(profile.handle);
          },
        },
      });
    };

    this.handleDeclineInvitation = () => {
      this.setState(
        {
          notification: {
            show: true,
            type: "loading",
          },
        },
        () => {
          axios
            .post("/user/declineinvitation", { handle: profile.handle })
            .then((res) => {
              this.setState({
                notification: {
                  show: true,
                  heading: "Einladung abgelehnt",
                  text:
                    "Sie haben die Einladung von " + userName + " abgelehnt.",
                  acceptText: "ok",
                  type: "info",
                  onSuccess: () => {
                    this.setState({
                      navigateTo: "/invitations",
                    });
                  },
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                notification: {
                  show: false,
                },
              });
              alert("Es ein Fehler aufgetreten: " + err.response.data.error);
            });
        }
      );
    };

    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        {this.state.navigateTo && (
          <Navigate replace to={this.state.navigateTo} />
        )}
        <Navbar />
        <Container>
          {this.state.loadingProfile ? (
            <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center">
              <ImSpinner8 className="animate-spin text-xl mx-auto" />
            </div>
          ) : (
            <div className="max-w-2xl mx-auto mt-16">
              <ProfileCard
                profile={{
                  basics: profile.basics,
                  info: profile.info,
                }}
              />
              {pending && (
                <div>
                  <div
                    onClick={() => this.acceptInvitationCheck()}
                    className="text-center bg-primary text-white hover:bg-accent px-10 py-4 mt-10 rounded-full text-lg shadow-3xl hover:cursor-pointer"
                  >
                    <p>Date-Einladung annehmen?</p>
                  </div>
                  <div
                    className="text-center mt-8 hover:text-red-400 hover:cursor-pointer"
                    onClick={() => this.declineInvitationCheck()}
                  >
                    <p>Date-Einladung ablehnen</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Container>
        <BackgroundImg />
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            userImg={this.state.notification.userImg}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            okText={this.state.notification.okText}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

UsersProfile.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(UsersProfile);
