import React, { Component } from "react";
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import EditProfilCard from "../components/editProfileCard.jsx";
import Container from "../components/container.jsx";

export class EditProfile extends Component {
  render() {
    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        <Navbar />
        <Container>
          <div className="mobileBreakContainer flex justify-center">
            <div className="max-w-2xl relative my-40">
              <EditProfilCard />
            </div>
          </div>
        </Container>
        <BackgroundImg fixedPos="true" opacity="0.4" />
      </div>
    );
  }
}

export default EditProfile;
