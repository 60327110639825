import React, { Component } from "react";

// Image import
import m01 from "../../assets/images/imgComponent/morning/c4y_m01.jpg";
import m02 from "../../assets/images/imgComponent/morning/c4y_m02.jpg";
import m03 from "../../assets/images/imgComponent/morning/c4y_m03.jpg";
import m04 from "../../assets/images/imgComponent/morning/c4y_m04.jpg";
import m05 from "../../assets/images/imgComponent/morning/c4y_m05.jpg";
import m06 from "../../assets/images/imgComponent/morning/c4y_m06.jpg";
import m07 from "../../assets/images/imgComponent/morning/c4y_m07.jpg";
import m08 from "../../assets/images/imgComponent/morning/c4y_m08.jpg";
import m09 from "../../assets/images/imgComponent/morning/c4y_m09.jpg";
import a01 from "../../assets/images/imgComponent/afternoon/c4y_a01.jpg";
import a02 from "../../assets/images/imgComponent/afternoon/c4y_a02.jpg";
import a03 from "../../assets/images/imgComponent/afternoon/c4y_a03.jpg";
import a04 from "../../assets/images/imgComponent/afternoon/c4y_a04.jpg";
import a05 from "../../assets/images/imgComponent/afternoon/c4y_a05.jpg";
import a06 from "../../assets/images/imgComponent/afternoon/c4y_a06.jpg";
import a07 from "../../assets/images/imgComponent/afternoon/c4y_a07.jpg";
import e01 from "../../assets/images/imgComponent/evening/c4y_e01.jpg";
import e02 from "../../assets/images/imgComponent/evening/c4y_e02.jpg";
import e03 from "../../assets/images/imgComponent/evening/c4y_e03.jpg";
import e04 from "../../assets/images/imgComponent/evening/c4y_e04.jpg";
import e05 from "../../assets/images/imgComponent/evening/c4y_e05.jpg";
import e06 from "../../assets/images/imgComponent/evening/c4y_e06.jpg";
import e07 from "../../assets/images/imgComponent/evening/c4y_e07.jpg";
import e08 from "../../assets/images/imgComponent/evening/c4y_e08.jpg";
import e09 from "../../assets/images/imgComponent/evening/c4y_e09.jpg";
import e10 from "../../assets/images/imgComponent/evening/c4y_e10.jpg";
import e11 from "../../assets/images/imgComponent/evening/c4y_e11.jpg";

export class C4yImageWidget extends Component {
  constructor() {
    super();
    this.state = {
      displayedImage: null,
      morningImages: [m01, m02, m03, m04, m05, m06, m07, m08, m09],
      afternoonImages: [a01, a02, a03, a04, a05, a06, a07],
      eveningImages: [e01, e02, e03, e04, e05, e06, e07, e08, e09, e10, e11],
    };
  }

  componentDidMount() {
    this.chooseRandomImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dayTime !== prevProps.dayTime) {
      this.chooseRandomImage();
    }
  }

  chooseRandomImage = () => {
    if (this.props.src) {
      this.setState({ displayedImage: this.props.src });
    } else if (this.props.dayTime) {
      if (this.props.dayTime === "morning") {
        // get random image
        let randomImage =
          this.state.morningImages[
            Math.floor(Math.random() * this.state.morningImages.length)
          ];
        this.setState({ displayedImage: randomImage });
      } else if (this.props.dayTime === "afternoon") {
        // get random image
        let randomImage =
          this.state.afternoonImages[
            Math.floor(Math.random() * this.state.afternoonImages.length)
          ];
        this.setState({ displayedImage: randomImage });
      } else if (this.props.dayTime === "evening") {
        // get random image
        let randomImage =
          this.state.eveningImages[
            Math.floor(Math.random() * this.state.eveningImages.length)
          ];
        this.setState({ displayedImage: randomImage });
      }
    } else {
      // merage all images
      let allImages = [
        ...this.state.morningImages,
        ...this.state.afternoonImages,
        ...this.state.eveningImages,
      ];
      // get random image
      let randomImage = allImages[Math.floor(Math.random() * allImages.length)];
      this.setState({ displayedImage: randomImage });
    }
  };

  render() {
    return (
      <div className={this.props.className + " relative overflow-hidden"}>
        <img
          src={this.state.displayedImage}
          alt={this.props.alt}
          className="absolute w-full h-full"
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </div>
    );
  }
}

export default C4yImageWidget;
