import axios from "axios";
import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  UPDATE_USER,
  UPDATE_USER_IMAGE,
  SET_SCHEDULED_TALK,
  SHOW_MATCH_BAR,
  HIDE_MATCH_BAR,
  SET_ACC_STATUS,
} from "../types";

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/login", userData)
    .then((res) => {
      const FBIdToken = `Bearer ${res.data.token}`;
      localStorage.setItem("FBIdToken", FBIdToken);
      axios.defaults.headers.common["Authorization"] = FBIdToken;
      dispatch(getUserData());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const updateUserData = (userData) => (dispatch) => {
  dispatch({ type: UPDATE_USER, payload: userData });
};
export const updateUserImage = (userData) => (dispatch) => {
  dispatch({ type: UPDATE_USER_IMAGE, payload: userData });
};
export const setScheduledTalk = (userData) => (dispatch) => {
  dispatch({ type: SET_SCHEDULED_TALK, payload: userData });
};
export const setAccStatus = (accStatus) => (dispatch) => {
  dispatch({ type: SET_ACC_STATUS, payload: accStatus });
};
export const showMatchBar = () => (dispatch) => {
  dispatch({ type: SHOW_MATCH_BAR });
};
export const hideMatchBar = () => (dispatch) => {
  dispatch({ type: HIDE_MATCH_BAR });
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get("/user")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: getUserStatus(res.data),
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => console.log(err));
};

const getUserStatus = (data) => {
  const user = data.credentials;
  let status = "";
  // gold?
  if (user.infos.membership === "gold") {
    status = "goldActiveNoMatches";
    // matches?
    if (user.gold.displayedSuggestions.length > 0) status = "goldActiveMatches";
    // sent invitation?
    if (user.gold.requestedDate.length > 0) status = "goldActiveSentInvitation";
    // date?
    // has date?
    if (user.gold.matched.length > 0 && user.infos.paused) {
      status = "goldActiveDatePaused";
    }
  }

  // silver?
  if (user.infos.membership === "silver") {
    status = "silverActiveNoInvitations";
    // invitations?
    if (Object.keys(user.silver.pendingInvitations).length > 0) {
      status = "silverActivePendingInvitations";
    }
    // paused ?
    if (user.infos.paused) {
      status = "silverActivePaused";
    }
  }

  // inactive?
  if (!user.infos.active) {
    // expired?
    if (user.infos.expired) {
      status = "expired";
    } else {
      status = "inactive";
    }
  }

  // admin?
  if (user.infos.membership === "admin") status = "admin";

  //status = "silverActiveNoInvitations";
  data["accStatus"] = status;
  return data;
};
