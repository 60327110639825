import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//Components
import ProfileImage from "../components/profileImage.jsx";

//Icons
import ProfileinfoIcon from "../assets/icons/profileinfoIcon.jsx";
import { HiOutlinePencil } from "react-icons/hi";

//Redux
import { connect } from "react-redux";

export class ProfileCard extends Component {
  constructor() {
    super();

    this.state = {
      ownProfile: false,
      profile: {},
      loadingProfile: true,
    };
  }

  componentDidMount() {
    if (this.props.profile) {
      this.setState({
        profile: this.props.profile,
        loadingProfile: false,
      });
    } else {
      this.setState({
        ownProfile: true,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.user.loading && props.user.authenticated && state.ownProfile) {
      return {
        profile: props.user.credentials.profile,
        loadingProfile: false,
      };
    }
    return null;
  }

  render() {
    const {
      user: { authenticated },
    } = this.props;

    function arryExists(arr) {
      if (arr) {
        if (arr.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    function isEmptyOrUndefined(string) {
      if (string) {
        if (typeof string === "string" || string instanceof String) {
          if (string.trim() === "") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    const getAgeFromBirthday = (birthday) => {
      if (birthday) {
        const today = new Date();
        const birthDate = new Date(birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      } else {
        return null;
      }
    };

    return (
      <div className="box bg-white shadow-3xl shadow-[#1d1d1d29] relative p-8 2xs:p-14 transition-all ease-in-out delay-700 duration-700">
        {!this.state.loadingProfile ? (
          authenticated ? (
            <div>
              {this.state.ownProfile ? (
                <ProfileImage
                  editable
                  size="15rem"
                  className="border-4 border-white absolute left-1/2 top-0 -translate-y-1/4 -translate-x-1/2"
                />
              ) : (
                <img
                  src={this.state.profile.basics.imageUrl}
                  alt="profile"
                  className="border-4 border-white absolute left-1/2 top-0 -translate-y-1/4 -translate-x-1/2 rounded-full w-[15rem] h-[15rem]"
                />
              )}
              <div className="h-40" />
              {this.state.ownProfile && (
                <Link to={"/profile/edit"}>
                  <HiOutlinePencil className="inline text-xl mb-[2px] mr-2" />
                  <p className="inline">Profil bearbeiten</p>
                  <div className="h-6" />
                </Link>
              )}
              <h1 className="inline">
                {this.state.profile.basics.firstname +
                  `${
                    isEmptyOrUndefined(this.state.profile.basics.lastname)
                      ? ""
                      : " " + this.state.profile.basics.lastname
                  }`}
              </h1>
              {!isEmptyOrUndefined(this.state.profile.basics.birthday) && (
                <div className="inline">
                  <p className="font-cabinet font-normal text-3xl inline">
                    , {getAgeFromBirthday(this.state.profile.basics.birthday)}
                  </p>
                </div>
              )}
              <div className="h-4" />
              {!isEmptyOrUndefined(this.state.profile.basics.job) && (
                <h2 className="mb-8">{this.state.profile.basics.job}</h2>
              )}
              {!isEmptyOrUndefined(this.state.profile.info) && (
                <div className="flex flex-wrap gap-4">
                  {Object.keys(this.state.profile.info).map((key, index) => {
                    if (!isEmptyOrUndefined(this.state.profile.info[key])) {
                      return (
                        <div
                          className=" border-primary border-2 px-4 py-2 rounded-full hover:cursor-default"
                          key={index}
                        >
                          <ProfileinfoIcon
                            icon={key}
                            className="inline mr-2 h-5"
                          />
                          <div className="inline">
                            {this.state.profile.info[key] +
                              `${key === "height" ? " cm" : ""}`}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
              <hr className="mt-12 mb-12 border-primary border-1" />
              <div className="flex flex-col gap-2">
                {!isEmptyOrUndefined(this.state.profile.basics.children) && (
                  <div className="mb-5 xs:mb-0 xs:flex capitalize">
                    <div className="w-1/2">
                      <p>
                        <b>Kinder</b>
                      </p>
                    </div>
                    <div>
                      <p>{this.state.profile.basics.children}</p>
                    </div>
                  </div>
                )}
                {!isEmptyOrUndefined(
                  this.state.profile.basics.wantChildren
                ) && (
                  <div className="mb-5 xs:mb-0 xs:flex capitalize">
                    <div className="w-1/2">
                      <p>
                        <b>Kinderwunsch</b>
                      </p>
                    </div>
                    <div>
                      <p>{this.state.profile.basics.wantChildren}</p>
                    </div>
                  </div>
                )}
                {!isEmptyOrUndefined(this.state.profile.basics.pets) && (
                  <div className="mb-5 xs:mb-0 xs:flex capitalize">
                    <div className="w-1/2">
                      <p>
                        <b>Haustiere</b>
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p>{this.state.profile.basics.pets}</p>
                    </div>
                  </div>
                )}
                {!isEmptyOrUndefined(this.state.profile.basics.sport) && (
                  <div className="mb-5 xs:mb-0 xs:flex capitalize">
                    <div className="w-1/2">
                      <p>
                        <b>Sportliche Interessen</b>
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p>{this.state.profile.basics.sport}</p>
                    </div>
                  </div>
                )}
              </div>
              {!isEmptyOrUndefined(this.state.profile.basics.languages) && (
                <div>
                  <p className="mt-10">
                    <b>Sprachen</b>
                  </p>
                  <p className="mt-4 capitalize">
                    {this.state.profile.basics.languages}
                  </p>
                </div>
              )}
              {arryExists(this.state.profile.basics.interests) && (
                <div>
                  <p className="mt-10 mb-4">
                    <b>Weitere Interessen</b>
                  </p>
                  <div className="flex flex-wrap gap-4">
                    {this.state.profile.basics.interests.map(
                      (interesst, index) => {
                        return (
                          <div
                            className=" border-primary border-2 px-4 py-2 rounded-full  hover:bg-primary hover:text-white hover:cursor-default transition-all ease-in-out duration-200"
                            key={index}
                          >
                            <p>{interesst}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {!isEmptyOrUndefined(this.state.profile.basics.bio) && (
                <div>
                  <p className="mt-10">
                    <b>Über mich</b>
                  </p>
                  <p className="mt-4">{this.state.profile.basics.bio}</p>
                </div>
              )}
            </div>
          ) : (
            <p>Please login...</p>
          )
        ) : (
          <p>loading...</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

ProfileCard.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ProfileCard);
