import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import axios from "axios";

// Redux
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userActions";

// UI
import { ImSpinner8 } from "react-icons/im";

export class GoldSentInvitationWidget extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      invited: {},
      invitationValidFor: "",
      invitationExpired: false,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateCountdown(), 30000);

    // load preview data
    axios
      .get("/user/invited")
      .then((res) => {
        this.setState(
          {
            loading: false,
            invited: res.data,
          },
          () => {
            this.updateCountdown();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  removeTimedOutInvitation() {
    axios
      .post("/user/declineinvitation", {
        handle: this.props.user.credentials.infos.handle,
        silverHandle: this.state.invited.handle,
      })
      .then((res) => {
        window.location.reload();
      });
  }

  updateCountdown() {
    if (this.state.loading === false) {
      const currentTimestamp = Date.now();
      const requestedAtTimestamp = new Date(
        this.state.invited.requestedAt
      ).getTime();
      const diffTimestamp = requestedAtTimestamp - currentTimestamp;

      // check if diffTimestamp is negative
      if (diffTimestamp < 0) {
        this.setState({
          invitationValidFor: "0d 0h 0m",
          invitationExpired: true,
        });
        this.removeTimedOutInvitation();
      } else {
        const days = Math.floor(diffTimestamp / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diffTimestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (diffTimestamp % (1000 * 60 * 60)) / (1000 * 60)
        );

        this.setState({
          invitationValidFor: `${days}d ${hours}h ${minutes}m`,
        });
      }
    }
  }

  render() {
    const { invited } = this.state;

    // get age from birthday
    this.getAge = function (birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    return (
      <div>
        <h1 className="text-3xl mb-8">Ihre Date-Einladung</h1>

        <div className="bg-secondary rounded-xl">
          {this.state.loading ? (
            <div className="p-5">
              <ImSpinner8 className="animate-spin text-xl mx-auto" />
            </div>
          ) : (
            <Link
              className="flex flex-col lg:flex-row relative text-center xs:text-left pb-8 xs:py-5 xs:pr-5 xs:pl-48 lg:!p-5 justify-between"
              to="/matches"
            >
              <img
                src={invited.basics.imageUrl}
                alt="profile"
                className="block xs:absolute top-0 left-0 h-full rounded-xl"
              />
              <div className="mb-3 mt-8 xs:mt-0 lg:mb-0 lg:ml-20 flex justify-center xs:justify-start items-center gap-5">
                <ImSpinner8 className="animate-spin text-xl lg:mx-auto" />{" "}
                <p>
                  <b>
                    {invited.basics.firstname}
                    {invited.basics.lastname
                      ? " " + invited.basics.lastname
                      : ""}
                  </b>
                </p>
              </div>
              <p>{this.getAge(invited.basics.birthday)} Jahre</p>
              <p>{invited.basics.job}</p>

              {this.state.invitationExpired ? (
                <p>Die Einladung ist abgelaufen</p>
              ) : (
                <p>
                  Einladung gilt noch <b>{this.state.invitationValidFor}</b>
                </p>
              )}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

GoldSentInvitationWidget.propTypes = {
  getUserData: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  getUserData,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(GoldSentInvitationWidget);
