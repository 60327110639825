import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Navigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { setAccStatus } from "../redux/actions/userActions";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container";
import ProfileCard from "../components/profileCard.jsx";
import NotificationDialog from "../components/notificationDialog.jsx";

// Icons
import { ImSpinner8 } from "react-icons/im";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";

export class Matches extends Component {
  constructor() {
    super();
    this.state = {
      loadingMatches: true,
      currentProfile: 0,
      suggestions: [],
      profilecardRefs: [],
      profilecardSectionHeight: 0,

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // load suggestion infos
    axios
      .get("/user/suggestions")
      .then((res) => {
        let currentProfile = 0;
        //check if handle is in url
        const url = window.location.href;
        const profileHandle = url.substring(url.length - 6, url.length);
        res.data.forEach((profile) => {
          if (profile.handle === profileHandle) {
            currentProfile = res.data.indexOf(profile);
          }
        });
        // remove handle from url
        window.history.replaceState(
          {},
          document.title,
          window.location.origin + "/matches",
        );
        this.setState(
          {
            suggestions: res.data,
            loadingMatches: false,
            currentProfile: currentProfile,
          },
          () => {
            setTimeout(() => {
              let profileCardsSectionHeight = 0;
              this.state.profilecardRefs.forEach((ref) => {
                const height = ref.offsetHeight;
                if (height > profileCardsSectionHeight) {
                  profileCardsSectionHeight = height;
                }
              });
              this.setState({
                profilecardSectionHeight: profileCardsSectionHeight,
              });
            }, 0);
          },
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { accStatus } = this.props.user;
    const canReceive = this.props.user?.credentials?.infos?.canReceive;

    const profileCardPositions = [
      "left-0 -translate-x-[200%]",
      "left-0 -translate-x-[100%] xl:-translate-x-[80%]",
      "left-1/2 -translate-x-1/2",
      "left-full xl:-translate-x-[20%]",
      "left-full translate-x-[200%]",
    ];

    this.discardSuggestion = (suggestion) => {
      axios
        .post("/user/discardsuggestion", {
          userId: suggestion.handle,
        })
        .then((res) => {
          if (res.data.success) {
            const newSuggestions = this.state.suggestions.filter(
              (s) => s.handle !== suggestion.handle,
            );
            if (newSuggestions.length === 0) {
              this.props.setAccStatus("goldActiveNoMatches");
            } else if (this.state.currentProfile >= newSuggestions.length) {
              this.setState({
                currentProfile: newSuggestions.length - 1,
              });
            }
            window.scrollTo(0, 0);
            this.setState({
              suggestions: newSuggestions,
              notification: {
                show: false,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    this.discardSuggestionCheck = (suggestion) => {
      this.setState({
        notification: {
          show: true,
          heading: "Match verwerfen?",
          text: `Möchten Sie das Match mit ${suggestion.basics.firstname} ${suggestion.basics.lastname} wirklich verwefen? Sie haben anschließend keine Möglichkeit mehr, ${suggestion.basics.firstname} zu kontaktieren.`,
          acceptText: "Match verwerfen",
          cancelText: "abbrechen",
          acceptColor: "red",
          userImg: suggestion.basics.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.discardSuggestion(suggestion);
          },
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
        },
      });
    };

    this.requestDate = (suggestion) => {
      axios
        .post("/user/requestDate", {
          userId: suggestion.handle,
        })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              notification: {
                show: true,
                heading: "Date-Einladung erfolgreich versendet",
                text: `Sie haben ${suggestion.basics.firstname} ${suggestion.basics.lastname} erfolgreich auf ein Date eingeladen. Sobald ${suggestion.basics.firstname} auf Ihre Einladung reagiert hat, werden Sie informiert.`,
                acceptText: "Alles klar",
                cancelText: "",
                userImg: suggestion.basics.imageUrl,
                type: "userRelated",
                onSuccess: () => {
                  this.props.setAccStatus("goldActiveSentInvitation");
                  this.setState({
                    suggestions: [suggestion],
                    notification: { show: false },
                  });
                },
                onCancel: () => {},
              },
            });
          } else {
            this.setState({ notification: { show: false } });
            alert(
              "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            );
          }
        })
        .catch((err) => {
          this.setState({ notification: { show: false } });
          console.log(err);
          alert("Es ist ein Fehler aufgetreten: " + err.response.data.error);
        });
    };

    this.requestDateCheck = (suggestion) => {
      // set notification
      this.setState({
        notification: {
          show: true,
          heading: "Date-Einladung absenden?",
          text: `Möchten Sie ${suggestion.basics.firstname} ${suggestion.basics.lastname} auf ein Date einladen? Sobald Sie die Einladung versenden hat ${suggestion.firstname} ${suggestion.lastname} drei Tage Zeit darauf zu reagieren. In Dieser Zeit erhalten Sie keine weiteren Matches.\n\nAchtung: Sie können die Einladung nicht mehr zurückziehen.`,
          acceptText: "Einladung absenden",
          cancelText: "abbrechen",
          userImg: suggestion.basics.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.setState({ notification: { type: "loading", show: true } });
            this.requestDate(suggestion);
          },
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
        },
      });
    };

    this.nextProfile = () => {
      if (this.state.currentProfile < this.state.suggestions.length - 1) {
        this.setState({
          currentProfile: this.state.currentProfile + 1,
        });
      }
    };
    this.prevProfile = () => {
      if (this.state.currentProfile > 0) {
        this.setState({
          currentProfile: this.state.currentProfile - 1,
        });
      }
    };

    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        {this.props.user.loading === false && (
          <div>
            {this.props.user.credentials.infos.membership !== "gold" && (
              <Navigate to="/" />
            )}
          </div>
        )}
        <Navbar />
        {accStatus !== "goldActiveMatches" ? (
          <div>
            {accStatus !== "goldActiveSentInvitation" ? (
              <Container>
                <h1 className="text-3xl mb-8">Ihre Matches</h1>

                <div className="bg-secondary p-6 rounded-xl">
                  {accStatus === "inactive" && (
                    <p>
                      Sobald Sie Ihren Account aktivieren, beginnen wir mit der
                      Suche nach den perfekten Matches für Sie, welche Sie
                      anschließen hier angezeigt bekommen. Seien Sie gespannt!
                    </p>
                  )}
                  {accStatus === "expired" && (
                    <p>
                      Ihre Mitgliedschaft ist leider abgelaufen. Sobald Sie Ihre
                      Mitgliedschaft verlängern, werden Ihre Matches wieder
                      angezeigt.
                    </p>
                  )}
                  {accStatus === "goldActiveNoMatches" && (
                    <div className="flex items-center">
                      {canReceive ? (
                        <>
                          <ImSpinner8 className="animate-spin text-xl mr-6 w-10" />
                          <p>
                            Haben Sie ein wenig Geduld. Wir sind derzeit auf der
                            Suche nach den perfekten Matches für Sie. Sobald es
                            Neuigkeiten gibt, werden Sie informiert.
                          </p>
                        </>
                      ) : (
                        <p>
                          Sie haben bereits alle Ihre Matches erhalten. Wenn Sie
                          bis zu drei weitere Matches erhalten möchten, können
                          Sie Ihre Mitgliedschaft upgraden.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </Container>
            ) : (
              <Container>
                {this.state.loadingMatches === true ? (
                  <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center">
                    <ImSpinner8 className="animate-spin text-xl mx-auto" />
                  </div>
                ) : (
                  <div>
                    <div className="text-center mt-5 md:mt-20 mb-36 md:mb-48">
                      <div className="text-4xl mb-10">
                        <IoMailOutline className="mx-auto" />
                      </div>
                      <h1 className="text-3xl mb-8">
                        Sie haben {this.state.suggestions[0].basics.firstname}
                        {this.state.suggestions[0].basics.lastname !== ""
                          ? " " + this.state.suggestions[0].basics.lastname
                          : ""}{" "}
                        auf ein Date eingeladen.
                      </h1>
                      <p>
                        Sie werden benarichtigt, sobald{" "}
                        {this.state.suggestions[0].basics.firstname} reagiert
                        hat.
                      </p>
                    </div>
                    <div className="max-w-2xl mx-auto">
                      <ProfileCard
                        profile={{
                          basics: this.state.suggestions[0].basics,
                          info: this.state.suggestions[0].info,
                        }}
                      />
                    </div>
                  </div>
                )}
              </Container>
            )}
          </div>
        ) : (
          <div>
            {this.state.loadingMatches ? (
              <div className="w-full h-screen flex justify-center items-center">
                <ImSpinner8 className="animate-spin text-xl mx-auto" />
              </div>
            ) : (
              <div>
                <div
                  className="max-w-full pt-64 relative overflow-hidden"
                  style={{
                    height: this.state.profilecardSectionHeight + 256 + 100,
                  }}
                >
                  <div className="hidden 900:flex fixed w-full  xl:w-[600px] text-5xl justify-evenly xl:justify-between left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <div
                      className={`hover:text-accent hover:cursor-pointer xl:-translate-x-full xl:pr-20 transition-all duration-500 ${
                        this.state.currentProfile === 0
                          ? "opacity-0"
                          : "opacity-100"
                      }`}
                      onClick={() => this.prevProfile()}
                    >
                      <BsChevronLeft />
                    </div>
                    <div className="w-[600px] xl:hidden" />
                    <div
                      className={`hover:text-accent hover:cursor-pointer xl:translate-x-full xl:pl-20 transition-all duration-500 ${
                        this.state.currentProfile ===
                        this.state.suggestions.length - 1
                          ? "opacity-0"
                          : "opacity-100"
                      }`}
                      onClick={() => this.nextProfile()}
                    >
                      <BsChevronRight />
                    </div>
                  </div>
                  {this.state.suggestions.map((suggestion, index) => (
                    <div
                      className={`w-10/12 900:w-[600px] flex flex-col justify-center mx-auto absolute transition-all duration-1000 ease-in-out ${
                        profileCardPositions[
                          index + (2 - this.state.currentProfile)
                        ]
                      }`}
                      key={index}
                      ref={(el) => (this.state.profilecardRefs[index] = el)}
                    >
                      <ProfileCard
                        key={suggestion.handle}
                        profile={{
                          basics: suggestion.basics,
                          info: suggestion.info,
                        }}
                      />
                      <div
                        className="w-1/2 top-0 left-0 absolute"
                        style={{
                          height: this.state.profilecardSectionHeight - 200,
                        }}
                        onClick={() => this.prevProfile()}
                      />
                      <div
                        className="w-1/2 top-0 right-0 absolute"
                        style={{
                          height: this.state.profilecardSectionHeight - 200,
                        }}
                        onClick={() => this.nextProfile()}
                      />
                      <div
                        onClick={() => this.requestDateCheck(suggestion)}
                        className="w-auto text-center bg-primary text-white hover:bg-accent px-10 py-4 mt-10 rounded-full text-lg shadow-3xl hover:cursor-pointer"
                      >
                        <p>
                          {suggestion.basics.firstname}{" "}
                          {suggestion.basics.lastname} zu einem Date einladen
                        </p>
                      </div>
                      <div
                        className="text-center mt-8 hover:text-red-400 hover:cursor-pointer"
                        onClick={() => this.discardSuggestionCheck(suggestion)}
                      >
                        <p>Match verwerfen</p>
                      </div>
                      <div className="mt-14 flex lg:hidden justify-between text-lg">
                        <p
                          className={`hover:text-accent hover:cursor-pointer ${
                            index === 0 && "opacity-0"
                          }`}
                          onClick={() => this.prevProfile()}
                        >
                          zurück
                        </p>
                        <p
                          className={`hover:text-accent hover:cursor-pointer ${
                            index === this.state.suggestions.length - 1 &&
                            "opacity-0"
                          }`}
                          onClick={() => this.nextProfile()}
                        >
                          weiter
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {this.state.notification.show && (
                  <NotificationDialog
                    type={this.state.notification.type}
                    userImg={this.state.notification.userImg}
                    userImg2={this.state.notification.userImg2}
                    heading={this.state.notification.heading}
                    text={this.state.notification.text}
                    okText={this.state.notification.okText}
                    cancelText={this.state.notification.cancelText}
                    acceptText={this.state.notification.acceptText}
                    acceptColor={this.state.notification.acceptColor}
                    onSuccess={this.state.notification.onSuccess}
                    onCancel={() =>
                      this.setState({
                        notification: { show: false, loadingMatch: false },
                      })
                    }
                  />
                )}
              </div>
            )}
          </div>
        )}
        <BackgroundImg fixedPos />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  setAccStatus,
};

Matches.protoTypes = {
  user: PropTypes.object.isRequired,
  setAccStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(Matches);
