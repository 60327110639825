import React from "react";
import VerifyEmail from "../pages/VerifyEmail";
import ResetPassword from "../pages/ResetPassword";

export default function Accouts() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const mode = params.mode;
  const actionCode = params.oobCode;

  switch (mode) {
    case "recoverEmail":
      return (
        <div>
          <h1>Recover Email goes here</h1>
        </div>
      );
    case "resetPassword":
      return <ResetPassword actionCode={actionCode} />;
    case "verifyEmail":
      return <VerifyEmail actionCode={actionCode} />;
    default:
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
}
