import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { setAccStatus } from "../redux/actions/userActions";
// Components
import NotificationDialog from "../components/notificationDialog.jsx";
// Ui
import { ImSpinner8 } from "react-icons/im";

export class GoldMatchesPreviewList extends Component {
  constructor() {
    super();

    this.state = {
      loadingSuggestions: true,
      suggestions: [],
      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
      },
    };
  }

  componentDidMount() {
    // load suggestion infos
    axios
      .get("/user/suggestions/preview")
      .then((res) => {
        this.setState({
          suggestions: res.data,
          loadingSuggestions: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    // get age from birthday
    const getAge = (birthday) => {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    this.discardSuggestion = (suggestion) => {
      axios
        .post("/user/discardsuggestion", {
          userId: suggestion.userId,
        })
        .then((res) => {
          if (res.data.success) {
            const newSuggestions = this.state.suggestions.filter(
              (s) => s.userId !== suggestion.userId
            );
            if (newSuggestions.length === 0) {
              this.props.setAccStatus("goldActiveNoMatches");
            }
            this.setState({
              suggestions: newSuggestions,
              notification: {
                show: false,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    this.discardSuggestionCheck = (suggestion) => {
      this.setState({
        notification: {
          show: true,
          heading: "Match verwerfen?",
          text: `Möchten Sie das Match mit ${suggestion.firstname} ${suggestion.lastname} wirklich verwefen? Sie haben anschließend keine Möglichkeit mehr, ${suggestion.firstname} zu kontaktieren.`,
          acceptText: "Match verwerfen",
          cancelText: "abbrechen",
          acceptColor: "red",
          userImg: suggestion.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.discardSuggestion(suggestion);
          },
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
        },
      });
    };

    this.requestDate = (suggestion) => {
      axios
        .post("/user/requestDate", {
          userId: suggestion.userId,
        })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              notification: {
                show: true,
                heading: "Date-Einladung erfolgreich versendet",
                text: `Sie haben ${suggestion.firstname} ${suggestion.lastname} erfolgreich auf ein Date eingeladen. Sobald ${suggestion.firstname} auf Ihre Einladung reagiert hat, werden Sie informiert.`,
                acceptText: "Alles klar",
                cancelText: "",
                userImg: suggestion.imageUrl,
                type: "userRelated",
                onSuccess: () => {
                  this.props.setAccStatus("goldActiveSentInvitation");
                  this.setState({ notification: { show: false } });
                },
                onCancel: () => {},
              },
            });
          } else if (res.data.success === false && res.data.paused === true) {
            this.setState({
              notification: {
                show: true,
                heading: "Date-Einladung aktuell nicht möglich",
                text: `${suggestion.firstname} ${suggestion.lastname} nimmt aktuell keine Date-Einladungen an. Sie können aber gerne versuchen ${suggestion.firstname} zu einem späteren Zeitpunkt einzuladen. Wir bitten Sie um Verständnis.`,
                acceptText: "Alles klar",
                cancelText: "",
                userImg: suggestion.imageUrl,
                type: "userRelated",
                onSuccess: () => {
                  this.setState({ notification: { show: false } });
                },
                onCancel: () => {},
              },
            });
          } else {
            this.setState({ notification: { show: false } });
            alert(
              "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut."
            );
          }
        })
        .catch((err) => {
          this.setState({ notification: { show: false } });
          console.log(err);
          alert("Es ist ein Fehler aufgetreten: " + err.response.data.error);
        });
    };

    this.requestDateCheck = (suggestion) => {
      // set notification
      this.setState({
        notification: {
          show: true,
          heading: "Date-Einladung absenden?",
          text: `Möchten Sie ${suggestion.firstname} ${suggestion.lastname} auf ein Date einladen? Sobald Sie die Einladung versenden hat ${suggestion.firstname} ${suggestion.lastname} drei Tage Zeit darauf zu reagieren. In Dieser Zeit erhalten Sie keine weiteren Matches.\n\nAchtung: Sie können die Einladung nicht mehr zurückziehen.`,
          acceptText: "Einladung absenden",
          cancelText: "abbrechen",
          userImg: suggestion.imageUrl,
          type: "userRelated",
          onSuccess: () => {
            this.setState({ notification: { type: "loading", show: true } });
            this.requestDate(suggestion);
          },
          onCancel: () => {
            this.setState({ notification: { show: false } });
          },
        },
      });
    };

    return (
      <div>
        {this.state.loadingSuggestions ? (
          <ImSpinner8 className="animate-spin text-xl mx-auto" />
        ) : (
          <div
            className={`flex pt-20 ${
              2 < this.state.suggestions.length ? "justify-between" : "gap-16"
            }`}
          >
            {this.state.suggestions.map((suggestion, key) => {
              return (
                <div
                  key={key}
                  className="bg-secondary rounded-2xl p-6 max-w-sm text-center relative w-[32%] hover:scale-[1.02] transition-all ease-in-out duration-200"
                >
                  <Link to={`/matches/${suggestion.userId}`}>
                    <img
                      src={suggestion.imageUrl}
                      alt="match profile"
                      className="rounded-full w-40 absolute left-1/2 -translate-x-1/2 -translate-y-1/2 border-secondary border-4"
                    />
                  </Link>
                  <Link to={`/matches/${suggestion.userId}`}>
                    <p className="pt-28">
                      <b>
                        {suggestion.firstname}
                        {suggestion.lastname === ""
                          ? ""
                          : " " + suggestion.lastname}
                        , {getAge(suggestion.birthday)}
                      </b>
                    </p>
                    <p className="mt-2">{suggestion.job}</p>
                  </Link>
                  <p
                    className="text-xs mt-8 mb-12 cursor-pointer hover:text-red-400 transition-colors"
                    onClick={() => this.discardSuggestionCheck(suggestion)}
                  >
                    Match verwerfen
                  </p>
                  <button
                    className="absolute left-0 bottom-0 w-full py-4 rounded-b-2xl bg-primary text-white hover:bg-accent transition-colors"
                    onClick={() => this.requestDateCheck(suggestion)}
                  >
                    Date anfragen
                  </button>
                </div>
              );
            })}
          </div>
        )}
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            userImg={this.state.notification.userImg}
            userImg2={this.state.notification.userImg2}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            okText={this.state.notification.okText}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false, loadingMatch: false },
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  setAccStatus,
};

GoldMatchesPreviewList.protoTypes = {
  user: PropTypes.object.isRequired,
  setAccStatus: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(GoldMatchesPreviewList);
