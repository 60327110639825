import React, { Component } from "react";

export class Footer extends Component {
  render() {
    // get current year
    const currentYear = new Date().getFullYear();

    const linkStyle = "hover:text-accent";

    return (
      <div className="f-full bg-white border-t-8 border-secondary shadow-2xl">
        <div className="text-primary text-sm text-center sm:text-left py-5 md:px-14 px-8 flex flex-col gap-5 sm:flex-row justify-between">
          <div>&copy; Chosen GbR {currentYear}</div>
          <div>
            <div className="flex gap-5 justify-center sm:justify-end">
              <a
                className={linkStyle}
                href="https://www.chosenforyou.de/rechtliches/impressum"
                target="_blank"
                rel="noreferrer"
              >
                Impressum
              </a>
              <a
                className={linkStyle}
                href="https://www.chosenforyou.de/rechtliches/datenschutz"
                target="_blank"
                rel="noreferrer"
              >
                Datenschutz
              </a>
              <a
                className={linkStyle}
                href="https://www.chosenforyou.de/rechtliches/agb"
                target="_blank"
                rel="noreferrer"
              >
                AGB
              </a>
              <a
                className={linkStyle}
                href="https://www.chosenforyou.de/kontakt"
                target="_blank"
                rel="noreferrer"
              >
                Kontakt
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
