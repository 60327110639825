import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";

import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";

// Redux
import { connect } from "react-redux";

//UI
import { ImSpinner8 } from "react-icons/im";
import { BsArrowRightShort } from "react-icons/bs";

export class ResetPassword extends Component {
  state = {
    validCode: null,
    verifiedCode: false,
    changedPassword: false,
    errors: {},
    newPassword: "",
    confirmPassword: "",
    loading: false,
  };

  componentDidMount() {
    this.adjustResetPasswordContainerHeight();
    const resetPasswordData = {
      actionCode: this.props.actionCode,
    };
    axios
      .post("/accounts/verifyPasswordResetCode", resetPasswordData)
      .then((res) => {
        this.setState({
          errors: {},
          validCode: true,
          verifiedCode: true,
        });
      })
      .catch((err) => {
        this.setState({
          errors: {
            err: err.response.data,
          },
          verifiedCode: true,
          validCode: false,
        });
      });
  }

  handleChange = (errors, event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    delete errors[event.target.name];
  };
  handleSubmit = (event) => {
    this.setState({
      loading: true,
    });
    event.preventDefault();
    const newPasswordData = {
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
      actionCode: this.props.actionCode,
    };
    axios
      .post("/accounts/resetPassword", newPasswordData)
      .then((res) => {
        this.setState({
          errors: {},
          changedPassword: true,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data,
          loading: false,
        });
      });
  };

  render() {
    const { errors, loading, validCode, verifiedCode } = this.state;

    const rpccId = "resetPasswordContainerContent";
    const rpccClass =
      "w-full px-8 pt-10 pb-14 2xs:px-14 2xs:pt-14 overflow-hidden";

    let component;
    if (!verifiedCode) {
      component = (
        <div id={rpccId} className={`text-center ${rpccClass}`}>
          <ImSpinner8 className="animate-spin text-3xl inline" />
        </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div id={rpccId} className={rpccClass}>
          {this.state.changedPassword ? (
            <div>
              <h1>Passwort geändert</h1>
              <p className="mt-6">
                Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit
                Ihren neuen Login-Daten einloggen.
              </p>
              <div className="mt-8 hover:text-accent transition hover:translate-x-3">
                <BsArrowRightShort className="inline text-2xl" />
                <Link to={"/login"}>
                  {this.props.user.authenticated ? "fertig" : "Zum Login"}
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <motion.div
                className="absolute w-[400%] h-[400%] bg-secondary"
                initial={{ opacity: 1 }}
                animate={{ opacity: 0, transitionEnd: { display: "none" } }}
                transition={{ ease: "easeInOut", duration: 1, delay: 0.5 }}
              />

              <h1>Passwort zurücksetzen</h1>
              <p className="mt-6">Legen Sie nun ein neues Passwort fest.</p>
              <form noValidate onSubmit={this.handleSubmit}>
                <div className="mt-8 flex flex-col gap-8">
                  <div className="input-wrapper">
                    <label htmlFor="newPassword">Neues Passwort</label>
                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      className={`loginInput ${
                        errors.newPassword ? "border-error" : ""
                      }`}
                      value={this.state.newPassword}
                      onChange={(e) => this.handleChange(errors, e)}
                    />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="confirmPassword">
                      Passwort wiederholen
                    </label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      className={`loginInput ${
                        errors.confirmPassword ? "border-error" : ""
                      }`}
                      value={this.state.confirmPassword}
                      onChange={(e) => this.handleChange(errors, e)}
                    />
                  </div>
                </div>

                {errors.message && (
                  <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                    <p>{errors.message}</p>
                  </div>
                )}

                <button
                  type="submit"
                  className={`mt-12 outlineBtn ${
                    loading ? "hover:text-primary hover:border-primary" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <ImSpinner8 className="animate-spin text-xl inline" />
                      <p className="inline ml-5">Passwort wird geändert</p>
                    </span>
                  ) : (
                    "Passwort ändern"
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div id={rpccId} className={rpccClass}>
          <h1>Schade...</h1>
          <p className="mt-6">
            Leider ist etwas schiefgelaufen und dieser Link ungültig.
          </p>
        </div>
      );
    }

    return (
      <div className="min-h-fit relative">
        {this.props.user.authenticated ? (
          <Navbar type={this.props.user.credentials.infos.membership} />
        ) : (
          <Navbar type="signup" />
        )}
        <div className="w-full min-h-screen flex justify-center items-center">
          <div
            id="resetPasswordContainer"
            className="centeredBox bg-secondary overflow-hidden transition-all ease-in-out delay-700 duration-700"
          >
            <div>{component}</div>
          </div>
          <BackgroundImg />
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.adjustResetPasswordContainerHeight();
  }
  adjustResetPasswordContainerHeight() {
    const resetPasswordContainer = document.getElementById(
      "resetPasswordContainer"
    );
    if (resetPasswordContainer != null) {
      const content = document.getElementById("resetPasswordContainerContent");
      if (content != null) {
        resetPasswordContainer.style.height = content.offsetHeight + "px";
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ResetPassword);
