import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

import ToDoWidget from "./widgets/toDoWidget";
import NotificationDialog from "../components/notificationDialog.jsx";

// Redux
import { connect } from "react-redux";
import { setScheduledTalk } from "../redux/actions/userActions";

export class ToDoList extends Component {
  constructor() {
    super();
    this.state = {
      navigateTo: "",
      paymentLoading: false,

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        type: "",
        onSuccess: () => {},
        onCancel: () => {},
      },
    };
  }

  handlePayment = () => {
    this.setState({ paymentLoading: true });
    axios
      .post("/payment")
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        this.setState({ navigateTo: "/dashboard/notif?error" });
        this.setState({ paymentLoading: false });
      });
  };
  handleExtensionPayment = (months) => {
    this.setState({ paymentLoading: true });
    axios
      .post("/payment", {
        duration: months,
        renewalPayment: true,
      })
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        this.setState({ navigateTo: "/dashboard/notif?error" });
        this.setState({ paymentLoading: false });
      });
  };
  handleTalk = () => {
    window
      .open("https://calendly.com/chosenforyou/30-minuten-meeting", "_blank")
      .focus();
    this.setTalkTrue();
  };
  handleTalkGold = () => {
    this.setState({
      notification: {
        show: true,
        heading: "Wir möchten Sie kennenlernen",
        text: "Damit wir Ihr perfektes Match besser finden können, möchten wir Sie als Person besser kennenlernen. Als Gold-Mitglied können Sie entweder ein persönliches Gespräch mit uns vereinbaren oder sich uns bequem per WhatsApp oder E-Mail vorstellen. Sie haben die Wahl. Wir freuen uns Sie kennenzulernen!\n\nE-Mail: kontakt@chosenforyou.de\nWhatsApp: +49 (0) 176 36309208",
        acceptText: "Termin vereinbaren",
        cancelText: "Ich stelle mich per Nachricht vor",
        type: "check",
        onSuccess: () => {
          this.setState({ notification: { show: false } });
          this.handleTalk();
        },
        onCancel: () => {
          window.open("mailto:kontakt@chosenforyou.de", "_self");
          this.setTalkTrue();
          this.setState({ notification: { show: false } });
        },
      },
    });
  };
  handleRescheduleGold = () => {
    this.setState({
      notification: {
        show: true,
        heading: "Wir möchten Sie kennenlernen",
        text: "Führen Sie Ihr persönliches Gespräch mit uns. Sollten Sie noch keinen Termin vereinbart haben, können Sie dies hier tun. Alternativ können Sie sich als Gold-Mitglied auch bequem per WhatsApp oder E-Mail vorstellen. Sie haben die Wahl. Wir freuen uns Sie kennenzulernen!\n\nE-Mail: kontakt@chosenforyou.de\nWhatsApp: +49 (0) 176 36309208",
        acceptText: "Termin vereinbaren",
        cancelText: "Ich stelle mich per Nachricht vor",
        type: "check",
        onSuccess: () => {
          window
            .open(
              "https://calendly.com/chosenforyou/30-minuten-meeting",
              "_blank",
            )
            .focus();
          this.setState({ notification: { show: false } });
        },
        onCancel: () => {
          window.open("mailto:kontakt@chosenforyou.de", "_self");
          this.setState({ notification: { show: false } });
        },
      },
    });
  };

  // set scheduledTalk to true
  setTalkTrue = () => {
    if (this.props.user.credentials.infos.scheduledTalk === false) {
      axios
        .post("/user/scheduledcall", { scheduledTalk: true })
        .then((res) => {
          this.props.setScheduledTalk({ scheduledTalk: true });
        })
        .catch((err) => {
          this.setState({ navigateTo: "/dashboard/notif?error" });
        });
    }
  };

  render() {
    const {
      loading,
      credentials: { infos },
    } = this.props.user;

    //const itemsStyle = "max-w-[32%]";
    const itemsStyle = "min-h-[330px] lg:w-[412.16px] w-full";

    const completeProfile = {
      title: "Profil vervollständigen",
      text: "Ihr Profil ist das erste, was pozenzielle Partner von Ihnen sehen. Vervollständigen Sie es jetzt.",
      icon: "type",
    };

    const pay = {
      title: "Zahlung abschließen",
      text: "Schließen Sie die Zahlung Ihres Mitgliedbeitrags bequem und wann Sie wollen online ab.",
      icon: "pay",
    };

    let price3 = 29;
    let price6 = 19;
    if (infos.membership === "gold") {
      price3 = 399;
      price6 = 299;
    }
    const extend3 = {
      title: "Mitgliedschaft um drei Monate verlängern",
      text: price3 + "€ / Monat",
      icon: "3months",
    };
    const extend6 = {
      title: "Mitgliedschaft um sechs Monate verlängern",
      text: price6 + "€ / Monat",
      icon: "6months",
    };

    const scheduleTalkGold = {
      title: "Wir möchten Sie kennenlernen",
      text: "Vereinbaren Sie hierfür ein persönlichen Beratungsgespräch oder schreiben Sie uns bequem per Messenger.",
      icon: "talk",
    };
    const scheduleTalkSilver = {
      title: "Beratungsgespräch vereinbaren",
      text: "Vereinbaren Sie ein persönliches Beratungsgespräch mit uns, damit wir Sie besser kennenlernen können.",
      icon: "talk",
    };
    const scheduledTalkSilver = {
      title: "Führen Sie Ihr Beratungsgespräch",
      text: "Wir möchten Sie kennenlernen! Sollten Sie noch keinen Termin vereinbart haben, können Sie dies hier tun.",
      icon: "talk",
    };
    const scheduledTalkGold = {
      title: "Führen Sie Ihr Beratungsgespräch",
      text: "Sollten Sie noch keinen Termin vereinbart haben, können Sie dies hier tun. Alternativ können Sie sich auch bequem per Messenger vorstellen.",
      icon: "talk",
    };

    return (
      <div className="mt-14">
        {this.state.navigateTo && (
          <Navigate to={this.state.navigateTo} replace={true} />
        )}
        {infos.membership !== "admin" ? (
          <h1 className="text-3xl mb-8">
            {infos.expired
              ? "Verlängern Sie jetzt Ihre Mitgliedschaft"
              : "Ihre nächsten Schritte"}
          </h1>
        ) : (
          <h1 className="text-3xl mb-8">Gehe zu</h1>
        )}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="flex gap-[25.76px] flex-wrap">
            {infos.scheduledTalk &&
              !infos.adminApproved &&
              infos.membership === "silver" && (
                <div onClick={this.handleTalk} className={itemsStyle}>
                  <ToDoWidget
                    title={scheduledTalkSilver.title}
                    text={scheduledTalkSilver.text}
                    icon={scheduledTalkSilver.icon}
                  />
                </div>
              )}
            {infos.scheduledTalk &&
              !infos.adminApproved &&
              infos.membership === "gold" && (
                <div onClick={this.handleRescheduleGold} className={itemsStyle}>
                  <ToDoWidget
                    title={scheduledTalkGold.title}
                    text={scheduledTalkGold.text}
                    icon={scheduledTalkGold.icon}
                  />
                </div>
              )}
            {!infos.completedProfile && infos.membership !== "admin" && (
              <Link
                to={"/profile/edit"}
                onClick={() => window.scrollTo(0, 0)}
                className={itemsStyle}
              >
                <ToDoWidget
                  title={completeProfile.title}
                  text={completeProfile.text}
                  icon={completeProfile.icon}
                />
              </Link>
            )}
            {!infos.paid &&
              infos.expired === false &&
              (infos.membership === "gold" ||
                (infos.membership === "silver" &&
                  infos.adminApproved === true)) && (
                <div onClick={this.handlePayment} className={itemsStyle}>
                  <ToDoWidget
                    title={pay.title}
                    text={pay.text}
                    icon={pay.icon}
                    loading={this.state.paymentLoading}
                  />
                </div>
              )}
            {!infos.paid && infos.expired === true && (
              <div
                onClick={() => this.handleExtensionPayment(3)}
                className={itemsStyle}
              >
                <ToDoWidget
                  title={extend3.title}
                  text={extend3.text}
                  icon={extend3.icon}
                  loading={this.state.paymentLoading}
                />
              </div>
            )}
            {!infos.paid && infos.expired === true && (
              <div
                onClick={() => this.handleExtensionPayment(6)}
                className={itemsStyle}
              >
                <ToDoWidget
                  title={extend6.title}
                  text={extend6.text}
                  icon={extend6.icon}
                  loading={this.state.paymentLoading}
                />
              </div>
            )}

            {!infos.scheduledTalk && infos.membership === "silver" && (
              <div onClick={this.handleTalk} className={itemsStyle}>
                <ToDoWidget
                  title={scheduleTalkSilver.title}
                  text={scheduleTalkSilver.text}
                  icon={scheduleTalkSilver.icon}
                />
              </div>
            )}

            {!infos.scheduledTalk && infos.membership === "gold" && (
              <div onClick={this.handleTalkGold} className={itemsStyle}>
                <ToDoWidget
                  title={scheduleTalkGold.title}
                  text={scheduleTalkGold.text}
                  icon={scheduleTalkGold.icon}
                />
              </div>
            )}

            {infos.membership === "admin" && (
              <Link
                to={"/admin/approvals"}
                onClick={() => window.scrollTo(0, 0)}
                className={itemsStyle}
              >
                <ToDoWidget
                  title="Neue Nutzer verwalten (Approvals)"
                  text="Neue Nutzer müssen manuell freigeschaltet werden, damit ihr Account aktiviert wird."
                  icon={scheduleTalkGold.icon}
                />
              </Link>
            )}
            {infos.membership === "admin" && (
              <Link
                to={"/admin/users"}
                onClick={() => window.scrollTo(0, 0)}
                className={itemsStyle}
              >
                <ToDoWidget
                  title="Mix & Match (Users)"
                  text="Jetzt zur Übersicht aller Nutzer wechseln und Nutzer untereinander matchen."
                  icon={scheduleTalkGold.icon}
                />
              </Link>
            )}
            {infos.membership === "admin" && (
              <Link
                to={"/admin/matches"}
                onClick={() => window.scrollTo(0, 0)}
                className={itemsStyle}
              >
                <ToDoWidget
                  title="Matches anzeigen"
                  text="Alle Matches anzeigen, Nutzer kontaktieren und Dates organisieren."
                  icon={scheduleTalkGold.icon}
                />
              </Link>
            )}
          </div>
        )}
        {this.state.notification.show && (
          <NotificationDialog
            type={this.state.notification.type}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={this.state.notification.onCancel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = { setScheduledTalk };

ToDoList.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(ToDoList);
