import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";

//UI
import { ImSpinner8 } from "react-icons/im";
import { BsArrowRightShort } from "react-icons/bs";
import successImg from "../assets/images/signup05.jpg";

export default class VerifyEmail extends Component {
  state = {
    validCode: null,
    verifiedCode: false,
    errors: {},
    imgHeight: 256,
  };

  componentDidMount() {
    const checkMailData = {
      actionCode: this.props.actionCode,
    };
    axios
      .post("/accounts/verifyemail", checkMailData)
      .then((res) => {
        this.setState({
          errors: {},
          validCode: true,
          verifiedCode: true,
        });
      })
      .catch((err) => {
        this.setState({
          errors: {
            err: err.response.data,
            message: "Leider ist etwas schief gelaufen.",
          },
          verifiedCode: true,
          validCode: false,
        });
      });
  }

  render() {
    const { validCode, verifiedCode } = this.state;

    let component;
    if (!verifiedCode) {
      component = (
        <div className="text-center">
          <ImSpinner8 className="animate-spin text-3xl inline" />
        </div>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div>
          <h1>Herzlichen Glückwunsch!</h1>
          <p className="mt-6">
            Ihre E-Mail Adresse ist nun bestätigt und Ihre Bewerbung bei Chosen
            For You somit vollständig abgeschlossen. Sie können Sich nun
            einloggen.
          </p>
          <div className="mt-8 hover:text-accent transition hover:translate-x-3">
            <BsArrowRightShort className="inline text-2xl" />
            <Link to={"/login"}>Zum Login</Link>
          </div>
        </div>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div>
          <h1>Schade...</h1>
          <p className="mt-6">
            Leider ist etwas schiefgelaufen und dieser Link ungültig.
          </p>
        </div>
      );
    }

    return (
      <div className="min-h-fit relative">
        <Navbar type="signup" />
        <div className="w-full min-h-screen flex justify-center items-center">
          <div className="centeredBox bg-secondary overflow-hidden">
            <div className="relative">
              <div style={{ height: this.state.imgHeight + "px" }} />
              <div
                style={{ height: this.state.imgHeight + "px" }}
                className={`w-full overflow-hidden absolute top-0 left-0 flex content-center items-center`}
              >
                <img src={successImg} alt=""></img>
              </div>
            </div>
            <div className="w-full px-8 pt-10 pb-14 2xs:px-14 2xs:pt-14 overflow-hidden">
              {component}
            </div>
          </div>
          <BackgroundImg />
        </div>
      </div>
    );
  }
}
