import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";

import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";

//UI Stuff
import { ImSpinner8 } from "react-icons/im";
import { HiOutlineChevronDown } from "react-icons/hi";
import a02 from "../assets/images/imgComponent/afternoon/c4y_a02.jpg";
import C4yImageWidget from "../components/widgets/c4yImageWidget.jsx";

//box height: 16rem + part.offsetHeight

export class Signup extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      email: "",
      password: "",
      confirmPassword: "",
      firstname: "",
      gender: "f",
      desired: [],
      relationForm: [],
      membership: "",
      agb_accepted: false,

      progress: "0%",
      loading: false,
      signedUp: false,
      errors: {},
      imgHeight: 256,
      formStartTop: 130,

      pricing: { gold: 9999999, silver: 999999999 },
    };
  }

  handleCheckSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    axios
      .post("/signup/check", newUserData)
      .then((res) => {
        this.adjustSignupHeight(this.state.step);
        this.setState(
          {
            loading: false,
            step: 2,
            progress: "25%",
            errors: {},
            pricing: res.data.pricing,
          },
          () => {
            window.scrollTo({
              top: this.state.formStartTop,
              behavior: "smooth",
            });
            this.adjustSignupHeight(this.state.step);
          },
        );
      })
      .catch((err) => {
        this.setState(
          {
            errors: err.response.data,
            loading: false,
          },
          () => {
            this.adjustSignupHeight(this.state.step);
          },
        );
      });
  };
  handleStep2 = (event) => {
    event.preventDefault();
    //check if all fields are filled
    let errors = {};
    if (this.isEmpty(this.state.firstname)) {
      errors.firstname = true;
      errors.message = "Bitte geben Sie Ihren Vornamen oder ein Alias ein.";
    }
    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          step: 3,
          progress: "50%",
          errors: {},
        },
        () => {
          window.scrollTo({
            top: this.state.formStartTop,
            behavior: "smooth",
          });
          this.adjustSignupHeight(this.state.step);
        },
      );
    } else {
      console.log("error");
      this.setState(
        {
          errors: errors,
        },
        () => {
          this.adjustSignupHeight(this.state.step);
        },
      );
    }
  };
  handleStep3 = (event) => {
    event.preventDefault();
    //check if all fields are filled
    let errors = {};
    if (this.state.relationForm.length === 0) {
      errors.relationForm = true;
      errors.message = "Bitte geben Sie die gewünschte Beziehungsform an.";
    }
    if (this.state.desired.length === 0) {
      errors.desired = true;
      errors.message = "Bitte geben Sie an, nach was Sie auf der Suche sind.";
    }
    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          step: 4,
          progress: "75%",
          errors: {},
        },
        () => {
          window.scrollTo({
            top: this.state.formStartTop,
            behavior: "smooth",
          });
          this.adjustSignupHeight(this.state.step);
        },
      );
    } else {
      console.log("error");
      this.setState(
        {
          errors: errors,
        },
        () => {
          this.adjustSignupHeight(this.state.step);
        },
      );
    }
  };
  handleStep4 = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    //check if all fields are filled
    let errors = {};
    if (this.state.agb_accepted === false) {
      errors.agb_accepted = true;
      errors.message = "Bitte akzeptieren Sie die AGB.";
    }
    if (this.isEmpty(this.state.membership)) {
      errors.membership = true;
      errors.message = "Bitte wählen Sie Ihre gewünschte Mitgliedschaft.";
    }
    if (Object.keys(errors).length === 0) {
      const newUserData = {
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
        firstname: this.state.firstname,
        gender: this.state.gender,
        desired: this.state.desired,
        relationForm: this.state.relationForm,
        membership: this.state.membership,
        agb_accepted: this.state.agb_accepted,
      };
      axios
        .post("/signup", newUserData)
        .then((res) => {
          this.adjustSignupHeight(this.state.step);
          this.setState(
            {
              loading: false,
              step: 5,
              progress: "100%",
              errors: {},
            },
            () => {
              window.scrollTo({
                top: this.state.formStartTop,
                behavior: "smooth",
              });
              this.adjustSignupHeight(this.state.step);
            },
          );
        })
        .catch((err) => {
          this.setState(
            {
              errors: err.response.data,
              loading: false,
            },
            () => {
              this.adjustSignupHeight(this.state.step);
            },
          );
        });
    } else {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        () => {
          this.adjustSignupHeight(this.state.step);
        },
      );
    }
  };
  handleChange = (errors, event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    delete errors[event.target.name];
  };
  handleAgb = (errors, event) => {
    this.setState({
      agb_accepted: event.target.checked,
    });
    delete errors[event.target.name];
  };
  handleDesireSelection = (event) => {
    var desireList = this.state[event.target.name];
    if (event.target.checked) {
      if (!desireList.includes(event.target.value)) {
        desireList.push(event.target.value);
      }
    } else {
      if (desireList.includes(event.target.value)) {
        desireList = desireList.filter((item) => item !== event.target.value);
      }
    }
    this.setState({
      [event.target.name]: desireList,
    });
  };

  render() {
    const { errors, loading } = this.state;

    this.toPreviousStep = () => {
      // get current progress in %
      const currentProgress = (this.state.step - 2) * 25 + "%";

      this.setState(
        {
          step: this.state.step - 1,
          progress: currentProgress,
        },
        () => {
          window.scrollTo({
            top: this.state.formStartTop,
            behavior: "smooth",
          });
          this.adjustSignupHeight(this.state.step);
        },
      );
    };

    //Ui Options
    const duration = 0.8;
    const ease = "easeInOut";
    const signupPartsContainer =
      "w-1/5 px-8 pt-10 pb-14 2xs:px-14 2xs:pt-14 overflow-hidden";

    return (
      <div className="min-h-fit relative">
        <Navbar type="signup" />

        <div className="w-full min-h-screen flex justify-center md:items-center">
          <div
            id="signupContainer"
            className="centeredBox bg-secondary mt-36 mb-20 md:my-40 transition-all ease-in-out delay-700 duration-700"
          >
            <div className="rounded-3xl overflow-hidden relative">
              <div style={{ height: this.state.imgHeight + "px" }} />
              <div
                style={{ height: this.state.imgHeight + "px" }}
                className={`w-full overflow-hidden absolute top-0 left-0 flex content-center items-center`}
              >
                <AnimatePresence>
                  {this.state.step === 1 && (
                    <motion.div
                      id="signupImg1"
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ ease: ease, duration: duration }}
                    >
                      <C4yImageWidget
                        className="h-full w-full !absolute -translate-y-1/2"
                        src={a02}
                        alt={"Anmeldesite Titelbild"}
                      />
                    </motion.div>
                  )}
                  ;
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.step === 2 && (
                    <motion.div
                      id="signupImg2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ ease: ease, duration: duration }}
                    >
                      <C4yImageWidget
                        className="h-full w-full !absolute -translate-y-1/2"
                        alt={"Anmeldesite Titelbild"}
                      />
                    </motion.div>
                  )}
                  ;
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.step === 3 && (
                    <motion.div
                      id="signupImg2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ ease: ease, duration: duration }}
                    >
                      <C4yImageWidget
                        className="h-full w-full !absolute -translate-y-1/2"
                        alt={"Anmeldesite Titelbild"}
                      />
                    </motion.div>
                  )}
                  ;
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.step === 4 && (
                    <motion.div
                      id="signupImg2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ ease: ease, duration: duration }}
                    >
                      <C4yImageWidget
                        className="h-full w-full !absolute -translate-y-1/2"
                        alt={"Anmeldesite Titelbild"}
                      />
                    </motion.div>
                  )}
                  ;
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.step === 5 && (
                    <motion.div
                      id="signupImg2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ ease: ease, duration: duration }}
                    >
                      <C4yImageWidget
                        className="h-full w-full !absolute -translate-y-1/2"
                        alt={"Anmeldesite Titelbild"}
                      />
                    </motion.div>
                  )}
                  ;
                </AnimatePresence>
              </div>
              <div
                id="progressBar"
                style={{ width: this.state.progress }}
                className={`h-2 bg-accent transition-all ease-in-out delay-150 duration-1000`}
              ></div>
              <motion.div
                className="flex justify-start items-start w-[500%]"
                initial={{ x: 0 }}
                animate={{ x: -(this.state.step - 1) * 20 + "%" }}
                transition={{ ease: ease, duration: duration }}
              >
                <div
                  id="part1"
                  className={signupPartsContainer}
                  exit={{ x: "-100%" }}
                  transition={{ ease: ease, duration: duration }}
                >
                  <h1>Jetzt bewerben</h1>
                  <p className="mt-5">
                    Bewerben Sie sich jetzt und werden Sie Teil der exklusiven
                    Chosen For You Community. Wir kümmern uns für Sie um alles
                    und finden einen perfekten Partner für Sie. Legen Sie jetzt
                    los.
                  </p>

                  <form noValidate onSubmit={this.handleCheckSubmit}>
                    <div className="mt-8 flex flex-col gap-8">
                      <div className="input-wrapper">
                        <label htmlFor="email">E-Mail Adresse *</label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className={`loginInput ${
                            errors.email ? "border-error" : ""
                          }`}
                          value={this.state.email}
                          onChange={(e) => this.handleChange(errors, e)}
                        />
                      </div>

                      <div className="input-wrapper">
                        <label htmlFor="password">
                          Legen Sie Ihr Passwort fest *
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          className={`loginInput ${
                            errors.password ? "border-error" : ""
                          }`}
                          value={this.state.password}
                          onChange={(e) => this.handleChange(errors, e)}
                        />
                      </div>

                      <div className="input-wrapper">
                        <label htmlFor="confirmPassword">
                          Wiederholen Sie Ihr Passwort *
                        </label>
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className={`loginInput ${
                            errors.confirmPassword ? "border-error" : ""
                          }`}
                          value={this.state.confirmPassword}
                          onChange={(e) => this.handleChange(errors, e)}
                        />
                      </div>
                    </div>

                    {errors.message && (
                      <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                        <p>{errors.message}</p>
                      </div>
                    )}

                    <button
                      type="submit"
                      className={`mt-12 outlineBtn ${
                        loading ? "hover:text-primary hover:border-primary" : ""
                      }`}
                      disabled={loading}
                    >
                      {loading ? (
                        <span>
                          <ImSpinner8 className="animate-spin text-xl inline" />
                          <p className="inline ml-5">Angaben prüfen...</p>
                        </span>
                      ) : (
                        "Weiter"
                      )}
                    </button>
                  </form>

                  <div className="mt-8">
                    <p className="inline">Sie haben bereits ein Account? </p>
                    <Link
                      className="inline underline hover:text-accent transition"
                      to={"/login"}
                    >
                      Loggen Sie sich hier ein.
                    </Link>
                  </div>
                </div>

                <div
                  id="part2"
                  className={signupPartsContainer}
                  initial={{ x: 0 }}
                >
                  <h1>Bewerbung</h1>

                  <form noValidate onSubmit={this.handleStep2}>
                    <div className="mt-8 flex flex-col gap-8">
                      <div className="input-wrapper">
                        <label htmlFor="firstname">Vorname / Alias *</label>
                        <input
                          id="firstname"
                          name="firstname"
                          type="firstname"
                          className={`loginInput ${
                            errors.firstname ? "border-error" : ""
                          }`}
                          value={this.state.firstname}
                          onChange={(e) => this.handleChange(errors, e)}
                        />
                      </div>

                      <div className="input-wrapper">
                        <label htmlFor="gender">Geschlecht *</label>
                        <div className="relative">
                          <select
                            id="gender"
                            name="gender"
                            className="loginSelect"
                            value={this.state.gender}
                            onChange={(e) => this.handleChange(errors, e)}
                          >
                            <option value="f">weiblich</option>
                            <option value="m">männlich</option>
                            <option value="d">divers</option>
                          </select>
                          <HiOutlineChevronDown className="absolute right-6 top-1/2 -translate-y-1/2 text-xl" />
                        </div>
                      </div>
                    </div>

                    {errors.message && (
                      <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                        <p>{errors.message}</p>
                      </div>
                    )}
                    <div className="flex justify-between items-end">
                      <button
                        type="submit"
                        className={`mt-12 outlineBtn ${
                          loading
                            ? "hover:text-primary hover:border-primary"
                            : ""
                        }`}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <ImSpinner8 className="animate-spin text-xl inline" />
                            <p className="inline ml-5">Angaben prüfen...</p>
                          </span>
                        ) : (
                          "Weiter"
                        )}
                      </button>
                      <p
                        className="text-sm cursor-pointer hover:text-accent"
                        onClick={() => this.toPreviousStep()}
                      >
                        zurück
                      </p>
                    </div>
                  </form>
                </div>

                <div id="part3" className={signupPartsContainer}>
                  <h1>Bewerbung</h1>
                  <p className="mt-5 mb-12">Nach was bist du auf der Suche?</p>

                  <form noValidate onSubmit={this.handleStep3}>
                    <div className="mt-8 flex flex-col gap-8">
                      <div className="checkboxBtns">
                        <li>
                          <input
                            name="desired"
                            id="female"
                            value="f"
                            type="checkbox"
                            onChange={(e) => this.handleDesireSelection(e)}
                          />
                          <label htmlFor="female">weiblich</label>
                        </li>

                        <li>
                          <input
                            name="desired"
                            id="male"
                            value="m"
                            type="checkbox"
                            onChange={(e) => this.handleDesireSelection(e)}
                          />
                          <label htmlFor="male">männlich</label>
                        </li>

                        <li>
                          <input
                            name="desired"
                            id="diverse"
                            value="d"
                            type="checkbox"
                            onChange={(e) => this.handleDesireSelection(e)}
                          />
                          <label htmlFor="diverse">divers</label>
                        </li>
                      </div>

                      <hr className="border-primary mb-1 -mt-5 w-full" />

                      <div className="checkboxBtns">
                        <li className="w-full">
                          <input
                            name="relationForm"
                            id="firme"
                            value="firme"
                            type="checkbox"
                            onChange={(e) => this.handleDesireSelection(e)}
                          />
                          <label
                            htmlFor="firme"
                            className="w-full mb-5 text-center"
                          >
                            feste Beziehung
                          </label>
                        </li>
                        <li className="w-full">
                          <input
                            name="relationForm"
                            id="loose"
                            value="loose"
                            type="checkbox"
                            onChange={(e) => this.handleDesireSelection(e)}
                          />
                          <label
                            htmlFor="loose"
                            className="w-full mb-5 text-center"
                          >
                            langfristige Liaison
                          </label>
                        </li>
                      </div>
                    </div>

                    {errors.message && (
                      <div className="bg-error text-white rounded-lg p-6 mt-10 mt- mb-[-1rem]">
                        <p>{errors.message}</p>
                      </div>
                    )}
                    <div className="flex justify-between items-end">
                      <button
                        type="submit"
                        className={`mt-16 outlineBtn ${
                          loading
                            ? "hover:text-primary hover:border-primary"
                            : ""
                        }`}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <ImSpinner8 className="animate-spin text-xl inline" />
                            <p className="inline ml-5">Angaben prüfen...</p>
                          </span>
                        ) : (
                          "Weiter"
                        )}
                      </button>
                      <p
                        className="text-sm cursor-pointer hover:text-accent"
                        onClick={() => this.toPreviousStep()}
                      >
                        zurück
                      </p>
                    </div>
                  </form>
                </div>

                <div id="part4" className={signupPartsContainer}>
                  <h1>Bewerbung</h1>
                  <form noValidate onSubmit={this.handleStep4}>
                    <div className="flex flex-col gap-8">
                      <div>
                        <p>
                          Wählen Sie Ihre Mitgliedschaft{" "}
                          <span
                            className="inline-block w-6 h-6 rounded-full border-[1.6px] border-primary text-center align-middle cursor-pointer m-3 hover:text-accent hover:border-accent"
                            onClick={function () {
                              window.open(
                                "https://chosen-for-you.webflow.io/membership",
                              );
                            }}
                          >
                            <span className="relative bottom-[2px] left-[0.4px]">
                              ?
                            </span>
                          </span>
                        </p>
                        <p className="text-xs">
                          Sie erhalten bis zu drei Partnervorschläge je
                          Mitgliedschaft.
                        </p>
                      </div>

                      <AnimatePresence>
                        {this.state.step === 4 && window.innerWidth <= 550 && (
                          <motion.div
                            className="overflow-hidden bg-[#99B2CA] rounded-md"
                            initial={{ height: 0 }}
                            animate={{
                              height: "auto",
                              transition: { delay: 4, duration: 1 },
                            }}
                          >
                            <motion.div
                              className="p-4"
                              initial={{ opacity: 0, y: "-100%" }}
                              animate={{
                                opacity: 1,
                                y: 0,
                                transition: { delay: 4, duration: 1 },
                              }}
                              onAnimationComplete={() => {
                                this.adjustSignupHeight(this.state.step);
                              }}
                            >
                              <p className="text-white">
                                Es wird keine sofortige Zahlung fällig! Führen
                                Sie erst Ihr persönliches Gespräch mit uns.
                              </p>
                            </motion.div>
                          </motion.div>
                        )}
                      </AnimatePresence>

                      <div className="radioBtns">
                        <li className="w-full">
                          <input
                            name="membership"
                            id="gold"
                            value="gold"
                            type="radio"
                            onChange={(e) => this.handleChange(errors, e)}
                          />
                          <label
                            htmlFor="gold"
                            className="w-full !border-0 mb-5 bg-gradient-to-br from-accent to-[#FFA24D] !p-[2.5px]"
                          >
                            <div
                              className={`py-2 px-6 rounded-full ${
                                this.state.membership === "gold"
                                  ? "bg-primary"
                                  : "bg-secondary"
                              }`}
                            >
                              <div className="flex justify-between ">
                                <p>Gold</p>
                                <p>
                                  {this.state.pricing.gold / 100}€{" "}
                                  <span className="text-xs text-gray-400">
                                    einmalig
                                  </span>
                                </p>
                              </div>
                            </div>
                          </label>
                        </li>
                        <li className="w-full">
                          <input
                            name="membership"
                            id="silver"
                            value="silver"
                            type="radio"
                            onChange={(e) => this.handleChange(errors, e)}
                          />
                          <label
                            htmlFor="silver"
                            className={`w-full !border-0 mb-8 bg-gradient-to-br from-[#D2D2D2] to-[#939393] !p-[2.5px] ${
                              this.state.membership === "silver"
                                ? "hover:!text-[#ffffff]"
                                : "hover:!text-[#7e7e7e]"
                            } `}
                          >
                            <div
                              className={`py-2 px-6 rounded-full ${
                                this.state.membership === "silver"
                                  ? "bg-primary"
                                  : "bg-secondary"
                              }`}
                            >
                              <div className="flex justify-between">
                                <p>Silber</p>
                                <p>
                                  {this.state.pricing.silver / 100}€{" "}
                                  <span className="text-xs text-gray-400">
                                    einmalig
                                  </span>
                                </p>
                              </div>
                            </div>
                          </label>
                        </li>
                      </div>
                    </div>

                    <div className="flex gap-4 items-start relative z-50 mb-5">
                      <input
                        name="agb_accepted"
                        id="agb_checkbox"
                        type="checkbox"
                        className="w-7 h-7"
                        onChange={(e) => this.handleAgb(errors, e)}
                      />
                      <p htmlFor="agb_checkbox" className="text-xs leading-6">
                        Durch den Abschluss der Bewerbung erkläre mich mit den{" "}
                        <span
                          className="cursor-pointer hover:text-accent underline"
                          onClick={() =>
                            window.open(
                              "https://www.chosenforyou.de/rechtliches/agb",
                              "MyWindow",
                              "width=450,height=600",
                            )
                          }
                        >
                          AGB der Chosen GbR
                        </span>{" "}
                        einverstanden und eröffne ein Benutzerkonto.
                      </p>
                    </div>

                    {errors.message && (
                      <div className="bg-error text-white rounded-lg p-6 mt-6 mb-2">
                        <p>{errors.message}</p>
                      </div>
                    )}
                    <div className="flex justify-between items-end flex-row xs:flex-col xs:items-start xs:justify-start">
                      <button
                        type="submit"
                        className={`mt-10 outlineBtn ${
                          loading
                            ? "hover:text-primary hover:border-primary"
                            : ""
                        }`}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <ImSpinner8 className="animate-spin text-xl inline" />
                            <p className="inline ml-5">
                              Bewerbung fertigstellen...
                            </p>
                          </span>
                        ) : (
                          "Bewerbung abschließen"
                        )}
                      </button>
                      <p
                        className="text-sm cursor-pointer hover:text-accent relative top-6"
                        onClick={() => this.toPreviousStep()}
                      >
                        zurück
                      </p>
                    </div>
                  </form>
                </div>

                <div id="part5" className={signupPartsContainer}>
                  <h1>Bewerbung abgeschlossen</h1>
                  <p className="mt-8 mb-9">
                    Vielen Dank für Ihr Vertrauen. Wir hoffen Sie bald als
                    exklusives Mitglied begrüßen zu dürfen. Hierfür haben wir
                    soeben eine E-Mail an {this.state.email} versendet. Bitte
                    bestätigen Sie als nächstes Ihre E-Mail Adresse, indem Sie
                    auf den Link in dieser Mail klicken.
                  </p>
                </div>
              </motion.div>
            </div>
            <AnimatePresence>
              {this.state.step === 4 && 550 < window.innerWidth && (
                <motion.div
                  className="absolute p-12 bg-[#99B2CA] rounded-full bottom-0 right-0 translate-x-1/4 translate-y-1/3 h-64 w-64"
                  initial={{ scale: 0.4, opacity: 0, x: "25%", y: "33%" }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: { delay: 2, duration: 1 },
                  }}
                  exit={{
                    scale: 0.1,
                    opacity: 0,
                    transition: { delay: 0, duration: 0.5 },
                  }}
                >
                  <p className="text-white text-center">
                    Es wird keine sofortige Zahlung fällig! Führen Sie erst Ihr
                    persönliches Gespräch mit uns.
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <BackgroundImg />
        </div>
      </div>
    );
  }

  adjustSignupHeight(step) {
    const signupContainer = document.getElementById("signupContainer");
    if (signupContainer != null) {
      const part = document.getElementById("part" + step);
      if (part != null) {
        const partHeight = part.offsetHeight;
        const signupHeight = this.state.imgHeight + partHeight;
        signupContainer.style.height = signupHeight + "px";
      }
    }
  }

  isEmpty = (string) => {
    if (string.trim() === "") return true;
    else return false;
  };
}

export default Signup;
