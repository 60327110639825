import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";

//Components
import ProfileImage from "../components/profileImage.jsx";

//Icons
import ProfileinfoIcon from "../assets/icons/profileinfoIcon.jsx";
import { ImSpinner8 } from "react-icons/im";
import { HiOutlineChevronDown } from "react-icons/hi";

//Redux
import { connect } from "react-redux";
import { updateUserData } from "../redux/actions/userActions";

export class EditProfileCard extends Component {
  constructor() {
    super();
    this.state = {
      loadedData: false,
      updatingProfile: false,
      errors: {},
      updatedProfile: false,

      interestsSelectionOpen: false,
      interests: [],
      suggestedInterests: [
        "Kochen",
        "Musik",
        "Instrumente",
        "Reisen",
        "Kunst",
        "Politik",
        "Oper",
        "Wellness",
        "Wandern",
        "Charity",
        "Fotografie",
        "Filme",
        "Bücher",
        "Fachzeitschriften",
        "Architektur",
      ],
      additionalInterests: [],
      newAdditionalInterest: "",
      interestsError: {},

      maxLength: {
        firstname: 20,
        lastname: 20,
        job: 30,
        bio: 500,
        telephone: 20,
        city: 20,
        interests: 15,
        interest: 20,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const getCurrentDateString = () => {
      // get current date in format yyyy-mm-dd
      const date = new Date();
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      return year + "-" + month + "-" + day;
    };

    if (
      !props.user.loading &&
      props.user.authenticated &&
      state.loadedData === false
    ) {
      const {
        basics: {
          firstname = "",
          lastname = "",
          job = "",
          birthday = getCurrentDateString(),
          children = "",
          wantChildren = "",
          pets = "",
          sport = "",
          languages = "",
          bio = "",
          interests = [],
        },
        info: {
          height = "",
          shape = "",
          relationship = "",
          smoking = "",
          drinking = "",
          eyes = "",
          hair = "",
          type = "",
        },
        protected: { city = "", education = "", salary = "", money = "" },
      } = props.user.credentials.profile;
      const telephone = props.user.credentials.infos.telephone;

      //check if interests are in this.state.suggestedInterests
      let additionalInterests = [];
      for (let i = 0; i < interests.length; i++) {
        if (!state.suggestedInterests.includes(interests[i]))
          additionalInterests.push(interests[i]);
      }

      return {
        loadedData: true,
        firstname: firstname,
        lastname: lastname,
        job: job,
        birthday: birthday,

        telephone: telephone,

        height: height,
        shape: shape,
        relationship: relationship,
        smoking: smoking,
        drinking: drinking,
        eyes: eyes,
        hair: hair,
        type: type,

        children: children,
        wantChildren: wantChildren,
        pets: pets,
        sport: sport,
        languages: languages,
        interests: interests,
        additionalInterests: additionalInterests,
        bio: bio,

        city: city,
        education: education,
        salary: salary,
        money: money,
      };
    }
    return null;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (this.state.errors[event.target.name]) {
      delete this.state.errors[event.target.name];
    } else if (event.target.name === "salary") {
      delete this.state.errors["money"];
    }
  };

  handleInterestSelection = (event) => {
    var selectedInterestes = this.state.interests;
    let error = {};
    if (event.target.checked) {
      if (selectedInterestes.length < this.state.maxLength.interests) {
        if (!selectedInterestes.includes(event.target.value)) {
          selectedInterestes.push(event.target.value);
        }
      } else {
        error.maxItems =
          "Es können maximal " +
          this.state.maxLength.interests +
          " Interessen ausgewählt werden";
      }
    } else {
      if (selectedInterestes.includes(event.target.value)) {
        selectedInterestes = selectedInterestes.filter(
          (item) => item !== event.target.value,
        );
      }
    }
    this.setState({
      interests: selectedInterestes,
      interestsError: error,
    });
  };

  handleAdditionalInterestSubmit = (event) => {
    event.preventDefault();
    const newInterest = this.state.newAdditionalInterest;
    if (newInterest.trim() !== "") {
      var updatedInterests = this.state.interests;
      if (updatedInterests.length < this.state.maxLength.interests) {
        var updatedAdditionalInterests = this.state.additionalInterests;
        if (!updatedInterests.includes(newInterest)) {
          updatedInterests.push(newInterest);
          if (!this.state.suggestedInterests.includes(newInterest)) {
            updatedAdditionalInterests.push(newInterest);
          }
        }
        this.setState({
          newAdditionalInterest: "",
        });
      } else {
        this.setState({
          interestsError: {
            maxItems:
              "Es können maximal " +
              this.state.maxLength.interests +
              " Interessen ausgewählt werden",
          },
        });
      }
    }
  };

  handleSubmit = (event) => {
    this.setState({ updatingProfile: true });
    event.preventDefault();
    //check if required fields are filled
    const required = [
      "firstname",
      "job",
      "birthday",
      "height",
      "shape",
      "relationship",
      "eyes",
      "smoking",
      "drinking",
      "hair",
      "type",
      "children",
      "wantChildren",
      "pets",
      "sport",
      "languages",
      "bio",
      "city",
      "education",
    ];
    let errors = {};

    required.forEach((field) => {
      if (
        this.state[field].trim() === "" ||
        this.state[field] === null ||
        this.state[field] === undefined
      ) {
        errors[field] = "Bitte füllen Sie alle erforderlichen Felder aus.";
      }
    });

    if (this.state.height < 100 || this.state.height > 250) {
      errors.height = "Bitte geben Sie eine gültige Größe ein.";
    }

    if (
      this.state.telephone.trim() !== "" &&
      this.state.telephone !== null &&
      this.state.telephone !== undefined
    ) {
      //var phoneRegex = /^(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))$/;
      var phoneRegex = /^(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))$/;
      if (!phoneRegex.test(this.state.telephone)) {
        errors.telephone = "Bitte geben Sie eine gültige Telefonnummer ein.";
      }
    }

    if (
      (this.state["salary"] === "" ||
        this.state["salary"] === null ||
        this.state["salary"] === undefined) &&
      (this.state["money"] === "" ||
        this.state["money"] === null ||
        this.state["money"] === undefined)
    ) {
      errors["money"] =
        "Bitte geben Sie mindestens Ihr Gehalt oder Ihr Vermögen an.";
    }
    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      const profileData = {
        userInfos: {
          telephone: this.state.telephone,
        },
        basics: {
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          job: this.state.job,
          birthday: this.state.birthday,
          children: this.state.children,
          wantChildren: this.state.wantChildren,
          pets: this.state.pets,
          sport: this.state.sport,
          languages: this.state.languages,
          interests: this.state.interests,
          bio: this.state.bio,
        },
        info: {
          height: this.state.height,
          shape: this.state.shape,
          relationship: this.state.relationship,
          smoking: this.state.smoking,
          drinking: this.state.drinking,
          eyes: this.state.eyes,
          hair: this.state.hair,
          type: this.state.type,
        },
        protected: {
          city: this.state.city,
          education: this.state.education,
          salary: this.state.salary,
          money: this.state.money,
        },
      };
      axios
        .post("/user", profileData)
        .then((res) => {
          const updatedProfileInformation = {
            profileData: profileData,
            completedProfile: res.data.completedProfile,
          };
          this.props.updateUserData(updatedProfileInformation);
          this.setState({ updatedProfile: true });
        })
        .catch((err) => {
          this.setState({
            updatingProfile: false,
            errors: {
              error:
                "Leider ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut.",
            },
          });
        });
    } else {
      this.setState({ updatingProfile: false });
    }
  };

  render() {
    const {
      user: { authenticated, loading },
    } = this.props;

    const { errors } = this.state;

    //Styles
    const editProfilInfoBoxStyle = "xs:flex items-center justify-between";
    const editProfilInfoInfosBoxStyle = "xs:my-0 my-2";
    const editProfileInputStyle = "loginInput mt-0 xs:w-1/2 text-center";
    const editProfileSelectStyle =
      "loginSelect focus:border-primary mt-0 text-center";
    const editProfileSelectArrow =
      "absolute right-4 top-1/2 -translate-y-1/2 text-xl";
    const infoIconsStyle = "inline object-left mr-4 absolute left-0";
    const infosLabelStyle = "ml-14";
    const infosLabelContainerStyle = "mb-6 xs:mb-0";
    const editProfilInfoBoxLabelStyle = "mb-3 xs:mb-0 block xs:inline-block";

    return (
      <div className="box bg-white shadow-3xl shadow-[#1d1d1d29] relative p-8 2xs:p-14 transition-all ease-in-out delay-700 duration-700">
        {this.state.updatedProfile && <Navigate to="/profile" />}
        {this.state.interestsSelectionOpen && (
          <div id="interestSelectionLayover" className="darkOverlay">
            <div className="bg-white w-full max-w-4xl max-h-full overflow-y-scroll rounded-lg shadow-2xl p-8 relative">
              <h1 className="text-2xl mb-3">Wählen Sie Ihre Interessen</h1>
              <form
                noValidate
                onSubmit={(e) => this.handleAdditionalInterestSubmit(e)}
              >
                <div className="mt-8 flex flex-col mb-16">
                  <div className="checkboxBtns">
                    {this.state.suggestedInterests.map((interest, index) => (
                      <li key={index}>
                        <input
                          name="interest"
                          id={interest}
                          checked={this.state.interests.includes(interest)}
                          value={interest}
                          type="checkbox"
                          onChange={(e) => this.handleInterestSelection(e)}
                        />
                        <label htmlFor={interest}>{interest}</label>
                      </li>
                    ))}
                    {this.state.additionalInterests.map((interest, index) => (
                      <li key={index}>
                        <input
                          name="additionalInterest"
                          id={interest}
                          checked={this.state.interests.includes(interest)}
                          value={interest}
                          type="checkbox"
                          onChange={(e) => this.handleInterestSelection(e)}
                        />
                        <label htmlFor={interest}>{interest}</label>
                      </li>
                    ))}

                    <div className="inline-flex w-full 3xs:w-[17rem] border-2 border-primary focus:border-accent rounded-full  focus:outline-none focus:text-primary text-primary transition-colors justify-between">
                      <input
                        id="addInterst"
                        name="addInterst"
                        maxLength={this.state.maxLength.interest}
                        type="text"
                        className="w-full h-full mx-6 my-2 focus:outline-none"
                        placeholder="Interesse hinzufügen"
                        onChange={(e) =>
                          this.setState({
                            newAdditionalInterest: e.target.value,
                          })
                        }
                        value={this.state.newAdditionalInterest}
                      />
                      <div
                        onClick={(e) => this.handleAdditionalInterestSubmit(e)}
                        className="bg-primary w-20 rounded-r-full relative hover:bg-accent cursor-pointer"
                      >
                        <p className="text-white text-center text-3xl absolute left-1/2 -translate-x-1/2">
                          +
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {0 < Object.keys(this.state.interestsError).length && (
                <div className="bg-error text-white rounded-lg p-6 mb-10 -mt-10">
                  <p>{Object.values(this.state.interestsError)[0]}</p>
                </div>
              )}

              <div
                onClick={() => this.setState({ interestsSelectionOpen: false })}
                className="outlineBtn inline hover:cursor-pointer"
              >
                fertig
              </div>
            </div>
          </div>
        )}
        {!loading ? (
          authenticated ? (
            <div>
              <ProfileImage
                editable
                size="15rem"
                className="border-4 border-white absolute left-1/2 top-0 -translate-y-1/4 -translate-x-1/2"
              />
              <div className="h-40" />
              <form noValidate onSubmit={this.handleSubmit}>
                <div className="input-wrapper mb-7">
                  <label htmlFor="firstname">Vorname / Alias</label>
                  <input
                    id="firstname"
                    name="firstname"
                    type="text"
                    maxLength={this.state.maxLength.firstname}
                    className={`loginInput ${
                      errors.firstname ? "border-error" : ""
                    }`}
                    value={this.state.firstname}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="input-wrapper mb-7">
                  <label htmlFor="lastname">Nachname / Alias (optional)</label>
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    maxLength={this.state.maxLength.lastname}
                    className={`loginInput ${
                      errors.lastname ? "border-error" : ""
                    }`}
                    value={this.state.lastname}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="input-wrapper mb-7">
                  <label htmlFor="job">Beruf</label>
                  <input
                    id="job"
                    name="job"
                    type="text"
                    maxLength={this.state.maxLength.job}
                    className={`loginInput ${errors.job ? "border-error" : ""}`}
                    value={this.state.job}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div className="input-wrapper mb-7">
                  <label htmlFor="birthday">Geburtsdatum</label>
                  <input
                    id="birthday"
                    name="birthday"
                    type="date"
                    className={`loginInput inline-flex ${
                      errors.birthday ? "border-error" : ""
                    }`}
                    value={this.state.birthday}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div
                  id="editInfo"
                  className="mt-14 flex flex-col gap-5 relative"
                >
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon
                        icon="height"
                        className={infoIconsStyle}
                      />
                      <label htmlFor="height" className={infosLabelStyle}>
                        Größe
                      </label>
                    </div>
                    <input
                      id="height"
                      name="height"
                      type="number"
                      className={`${editProfileInputStyle} + hover:border-accent hidde-appearance + ${
                        errors.height ? "border-error" : ""
                      }`}
                      value={this.state.height}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <p className="absolute right-5">cm</p>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon
                        icon="shape"
                        className={infoIconsStyle}
                      />
                      <label htmlFor="shape" className={infosLabelStyle}>
                        Figur
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="shape"
                        name="shape"
                        className={`${editProfileSelectStyle} + ${
                          errors.shape ? "border-error" : ""
                        }`}
                        value={this.state.shape}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="sportlich">sportlich</option>
                        <option value="schlank">schlank</option>
                        <option value="normal">normal</option>
                        <option value="mollig">mollig</option>
                        <option value="übergewichtig">übergewichtig</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon
                        icon="relationship"
                        className={infoIconsStyle}
                      />
                      <label htmlFor="relationship" className={infosLabelStyle}>
                        Familienstand
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="relationship"
                        name="relationship"
                        className={`${editProfileSelectStyle} + ${
                          errors.relationship ? "border-error" : ""
                        }`}
                        value={this.state.relationship}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="single">single</option>
                        <option value="offene Beziehung">
                          offene Beziehung
                        </option>
                        <option value="in einer Beziehung">
                          in einer Beziehung
                        </option>
                        <option value="verheiratet">verheiratet</option>
                        <option value="verwitwet">verwitwet</option>
                        <option value="getrennt lebend">getrennt lebend</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon icon="eyes" className={infoIconsStyle} />
                      <label htmlFor="eyes" className={infosLabelStyle}>
                        Augenfarbe
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="eyes"
                        name="eyes"
                        className={`${editProfileSelectStyle} + ${
                          errors.eyes ? "border-error" : ""
                        }`}
                        value={this.state.eyes}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="braun">braun</option>
                        <option value="blau">blau</option>
                        <option value="grün">grün</option>
                        <option value="grau">grau</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon
                        icon="smoking"
                        className={infoIconsStyle}
                      />
                      <label htmlFor="smoking" className={infosLabelStyle}>
                        Rauchen
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="smoking"
                        name="smoking"
                        className={`${editProfileSelectStyle} + ${
                          errors.smoking ? "border-error" : ""
                        }`}
                        value={this.state.smoking}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="ja">ja</option>
                        <option value="nein">nein</option>
                        <option value="gelegentlich">gelegentlich</option>
                        <option value="in Gesellschaft">in Gesellschaft</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon
                        icon="drinking"
                        className={infoIconsStyle}
                      />
                      <label htmlFor="drinking" className={infosLabelStyle}>
                        Alkohol
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="drinking"
                        name="drinking"
                        className={`${editProfileSelectStyle} + ${
                          errors.drinking ? "border-error" : ""
                        }`}
                        value={this.state.drinking}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="ja">ja</option>
                        <option value="nein">nein</option>
                        <option value="gelegentlich">gelegentlich</option>
                        <option value="in Gesellschaft">in Gesellschaft</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon icon="hair" className={infoIconsStyle} />
                      <label htmlFor="hair" className={infosLabelStyle}>
                        Haarfarbe
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="hair"
                        name="hair"
                        className={`${editProfileSelectStyle} + ${
                          errors.hair ? "border-error" : ""
                        }`}
                        value={this.state.hair}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="hellbraun">hellbraun</option>
                        <option value="dunkelbraun">dunkelbraun</option>
                        <option value="blond">blond</option>
                        <option value="schwarz">schwarz</option>
                        <option value="rot">rot</option>
                        <option value="grau">grau</option>
                        <option value="weiß">weiß</option>
                        <option value="andere">andere</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div
                    className={`${editProfilInfoBoxStyle} ${editProfilInfoInfosBoxStyle}`}
                  >
                    <div className={infosLabelContainerStyle}>
                      <ProfileinfoIcon icon="type" className={infoIconsStyle} />
                      <label htmlFor="type" className={infosLabelStyle}>
                        Aussehen
                      </label>
                    </div>
                    <div className="relative xs:w-1/2">
                      <select
                        id="type"
                        name="type"
                        className={`${editProfileSelectStyle} + ${
                          errors.type ? "border-error" : ""
                        }`}
                        value={this.state.type}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="westeuropäisch">westeuropäisch</option>
                        <option value="kaukasisch">kaukasisch</option>
                        <option value="asiatisch">asiatisch</option>
                        <option value="afrikanisch">afrikanisch</option>
                        <option value="südländisch">südländisch</option>
                        <option value="osteuropäisch">osteuropäisch</option>
                        <option value="andere">andere</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                </div>
                <hr className="mt-16 mb-12 border-primary border-1" />
                <div
                  id="moreInfos"
                  className="mt-14 flex flex-col gap-5 relative"
                >
                  <div className={editProfilInfoBoxStyle}>
                    <label
                      className={editProfilInfoBoxLabelStyle}
                      htmlFor="children"
                    >
                      Kinder
                    </label>
                    <div className="relative xs:w-1/2">
                      <select
                        id="children"
                        name="children"
                        className={`${editProfileSelectStyle} + ${
                          errors.children ? "border-error" : ""
                        }`}
                        value={this.state.children}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="ja">ja</option>
                        <option value="nein">nein</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div className={editProfilInfoBoxStyle}>
                    <label
                      className={editProfilInfoBoxLabelStyle}
                      htmlFor="wantChildren"
                    >
                      Kinderwunsch
                    </label>
                    <div className="relative xs:w-1/2">
                      <select
                        id="wantChildren"
                        name="wantChildren"
                        className={`${editProfileSelectStyle} + ${
                          errors.wantChildren ? "border-error" : ""
                        }`}
                        value={this.state.wantChildren}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="">keine Angabe</option>
                        <option value="ja">ja</option>
                        <option value="nein">nein</option>
                      </select>
                      <HiOutlineChevronDown
                        className={editProfileSelectArrow}
                      />
                    </div>
                  </div>
                  <div className={editProfilInfoBoxStyle}>
                    <label
                      className={editProfilInfoBoxLabelStyle}
                      htmlFor="pets"
                    >
                      Haustiere
                    </label>
                    <input
                      id="pets"
                      name="pets"
                      type="text"
                      className={`loginInput mt-0 xs:w-7/12 ${
                        errors.pets ? "border-error" : ""
                      }`}
                      value={this.state.pets}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className={editProfilInfoBoxStyle}>
                    <label
                      className={editProfilInfoBoxLabelStyle}
                      htmlFor="sport"
                    >
                      sportliche Interessen
                    </label>
                    <input
                      id="sport"
                      name="sport"
                      type="text"
                      className={`loginInput mt-0 xs:w-7/12 ${
                        errors.sport ? "border-error" : ""
                      }`}
                      value={this.state.sport}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className={editProfilInfoBoxStyle}>
                    <label
                      className={editProfilInfoBoxLabelStyle}
                      htmlFor="languages"
                    >
                      Sprachen
                    </label>
                    <input
                      id="languages"
                      name="languages"
                      type="text"
                      className={`loginInput mt-0 xs:w-7/12 ${
                        errors.languages ? "border-error" : ""
                      }`}
                      value={this.state.languages}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>

                <div>
                  <p className="mt-10">
                    <b>Weitere Interessen</b>
                  </p>
                  {0 < Object.keys(this.state.interests).length && (
                    <div className="flex flex-wrap gap-4 pt-4">
                      {this.state.interests.map((interesst, index) => {
                        return (
                          <div
                            className=" border-primary border-2 px-4 py-2 rounded-full hover:bg-primary hover:text-white cursor-default"
                            key={index}
                            onClick={() =>
                              this.setState({ interestsSelectionOpen: true })
                            }
                          >
                            <p>{interesst}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <p
                    onClick={() =>
                      this.setState({ interestsSelectionOpen: true })
                    }
                    className="inline-block mt-5 hover:cursor-pointer hover:text-accent"
                  >
                    auswählen...
                  </p>
                </div>

                <div>
                  <p className="mt-10 mb-4">
                    <b>Über mich</b>
                  </p>
                  <textarea
                    id="bio"
                    name="bio"
                    maxLength={this.state.maxLength.bio}
                    className={`loginInput p-4 mt-0 h-40 rounded-2xl ${
                      errors.bio ? "border-error" : ""
                    }`}
                    value={this.state.bio}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>

                <div>
                  <p className="mt-20 mb-4">
                    <b>Nicht öffentliche Informationen</b>
                    <br />
                    Die nachstehenden Informationen werden nicht an andere
                    Nutzer weitergegeben. Sie dienen lediglich dazu, die
                    Partnervermittlung zu optimieren.
                  </p>
                  <div
                    id="protectedInfos"
                    className="mt-10 flex flex-col gap-5 relative"
                  >
                    <div className={editProfilInfoBoxStyle}>
                      <label
                        className={editProfilInfoBoxLabelStyle}
                        htmlFor="telephone"
                      >
                        Telefonnummer
                      </label>
                      <input
                        id="telephone"
                        name="telephone"
                        maxLength={this.state.maxLength.telephone}
                        type="text"
                        className={`loginInput mt-0 xs:w-7/12 ${
                          errors.telephone ? "border-error" : ""
                        }`}
                        value={this.state.telephone}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className={editProfilInfoBoxStyle}>
                      <label
                        className={editProfilInfoBoxLabelStyle}
                        htmlFor="city"
                      >
                        Wohnort
                      </label>
                      <input
                        id="city"
                        name="city"
                        maxLength={this.state.maxLength.city}
                        type="text"
                        className={`loginInput mt-0 xs:w-7/12 ${
                          errors.city ? "border-error" : ""
                        }`}
                        value={this.state.city}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className={editProfilInfoBoxStyle}>
                      <label
                        className={editProfilInfoBoxLabelStyle}
                        htmlFor="education"
                      >
                        Bildungsabschluss
                      </label>
                      <div className="relative xs:w-1/2">
                        <select
                          id="education"
                          name="education"
                          className={`${editProfileSelectStyle} + ${
                            errors.education ? "border-error" : ""
                          }`}
                          value={this.state.education}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value="">keine Angabe</option>
                          <option value="keiner">keiner</option>
                          <option value="Hauptschulabschluss">
                            Hauptschulabschluss
                          </option>
                          <option value="mittlere Reife">mittlere Reife</option>
                          <option value="Berufsschulabschluss">
                            Berufsschulabschluss
                          </option>
                          <option value="Abitur">Abitur</option>
                          <option value="Hochschulabschluss">
                            Hochschulabschluss
                          </option>
                        </select>
                        <HiOutlineChevronDown
                          className={editProfileSelectArrow}
                        />
                      </div>
                    </div>
                    <div className={editProfilInfoBoxStyle}>
                      <label
                        className={editProfilInfoBoxLabelStyle}
                        htmlFor="salary"
                      >
                        Gehalt jährlich
                      </label>
                      <div className="relative xs:w-1/2">
                        <select
                          id="salary"
                          name="salary"
                          className={`${editProfileSelectStyle} + ${
                            errors.money ? "border-error" : ""
                          }`}
                          value={this.state.salary}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value="">keine Angabe</option>
                          <option value="unter 25.000€">unter 25.000€</option>
                          <option value="25 - 50.000€">25 - 50.000€</option>
                          <option value="50 - 100.000€">50 - 100.000€</option>
                          <option value="über 100.000€">über 100.000€</option>
                        </select>
                        <HiOutlineChevronDown
                          className={editProfileSelectArrow}
                        />
                      </div>
                    </div>
                    <div className={editProfilInfoBoxStyle}>
                      <label
                        className={editProfilInfoBoxLabelStyle}
                        htmlFor="money"
                      >
                        Vermögen
                      </label>
                      <div className="relative xs:w-1/2">
                        <select
                          id="money"
                          name="money"
                          className={`${editProfileSelectStyle} + ${
                            errors.money ? "border-error" : ""
                          }`}
                          value={this.state.money}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value="">keine Angabe</option>
                          <option value="unter 50.000€">unter 50.000€</option>
                          <option value="50 - 100.000€">50 - 100.000€</option>
                          <option value="100 - 500.000€">100 - 500.000€</option>
                          <option value="500.000 - 1 Mio €">
                            500.000 - 1 Mio €
                          </option>
                          <option value="1 - 10 Mio €">1 - 10 Mio €</option>
                          <option value="über 10 Mio €">über 10 Mio €</option>
                        </select>
                        <HiOutlineChevronDown
                          className={editProfileSelectArrow}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {0 < Object.keys(errors).length && (
                  <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                    <p>{Object.values(errors)[0]}</p>
                  </div>
                )}

                <button
                  type="submit"
                  className={`mt-20 outlineBtn ${
                    this.state.updatingProfile
                      ? "hover:text-primary hover:border-primary"
                      : ""
                  }`}
                  disabled={this.state.updatingProfile}
                >
                  {this.state.updatingProfile ? (
                    <span>
                      <ImSpinner8 className="animate-spin text-xl inline" />
                      <p className="inline ml-5">Profil bearbeiten...</p>
                    </span>
                  ) : (
                    "Speichern"
                  )}
                </button>
                <Link
                  to={"/profile"}
                  className="2xs:inline-block 2xs:mt-0 block mt-4"
                >
                  <p className="inline hover:text-accent 2xs:ml-10">
                    abbrechen
                  </p>
                </Link>
              </form>
            </div>
          ) : (
            <p>Please login...</p>
          )
        ) : (
          <p>loading...</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  updateUserData,
};

EditProfileCard.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(EditProfileCard);
