import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { connect } from "react-redux";

function AuthRoute(props) {
  let membership = "";
  if (props.authenticated && !props.user.loading) {
    membership = props.user.credentials.infos.membership;
  }

  if (props.reqAuth && !props.authenticated) {
    return <Navigate to="/login" />;
  } else if (!props.reqAuth && props.authenticated) {
    return <Navigate to="/" />;
  } else if (props.userRoute && membership === "admin") {
    return <Navigate to="/" />;
  } else if (props.adminRoute && membership !== "admin") {
    if (props.authenticated) {
      return <Navigate to="/" />;
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return <Outlet />;
  }
}

const mapStateToProp = (state) => ({
  authenticated: state.user.authenticated,
  user: state.user,
});

export default connect(mapStateToProp)(AuthRoute);
