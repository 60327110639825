import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { motion } from "framer-motion";

import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";

//UI Stuff
import { ImSpinner8 } from "react-icons/im";

//Redux
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      loginErrors: {},
      forgotPasswordErrors: {},
      authenticated: false,
      forgotPasswordEmail: "",
      forgotPasswordLoading: false,
      state: 1,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.UI.errors) {
      return {
        loginErrors: props.UI.errors,
      };
    } else if (props.user.authenticated) {
      return {
        authenticated: true,
        loginErrors: {},
      };
    }
    return null;
  }

  handleLoginSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData);
  };
  handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    this.setState({ forgotPasswordLoading: true });
    const userData = {
      email: this.state.email,
    };
    axios
      .post("/accounts/requestPasswordReset", userData)
      .then((res) => {
        this.setState({
          forgotPasswordErrors: {},
          forgotPasswordLoading: false,
          state: 3,
        });
      })
      .catch((err) => {
        this.setState({
          forgotPasswordErrors: {
            err: err.response.data,
            message: "Leider ist etwas schief gelaufen.",
          },
          forgotPasswordLoading: false,
        });
      });
  };
  handleChange = (errors, event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    delete errors[event.target.name];
  };
  switchState = (state) => {
    this.setState({
      state: state,
      password: "",
    });
  };

  render() {
    const {
      UI: { loading },
    } = this.props;
    const { loginErrors, forgotPasswordErrors, forgotPasswordLoading } =
      this.state;

    const loginPartsContainer = "w-1/3 p-8 2xs:p-14";

    return (
      <div className="min-h-fit relative">
        <Navbar type="login" />

        <div className="w-full min-h-screen flex justify-center md:items-center">
          <div
            id="loginContainer"
            className="centeredBox bg-secondary overflow-hidden mt-60 mb-20 md:my-40 transition-all ease-in-out delay-700 duration-700"
          >
            <motion.div
              className="flex justify-start items-center w-[300%]"
              initial={{ x: 0 }}
              animate={{ x: (-(this.state.state - 1) * 100) / 3 + "%" }}
              transition={{ ease: "easeInOut", duration: 0.8 }}
            >
              <div id="part1" className={loginPartsContainer}>
                <h1>Login</h1>

                <form noValidate onSubmit={this.handleLoginSubmit}>
                  <div className="mt-8 flex flex-col gap-8">
                    <div className="input-wrapper">
                      <label htmlFor="email">E-Mail Adresse</label>
                      <input
                        id="loginEmail"
                        name="email"
                        type="email"
                        className={`loginInput ${
                          loginErrors.email ? "border-error" : ""
                        }`}
                        value={this.state.email}
                        onChange={(e) => this.handleChange(loginErrors, e)}
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="password">Passwort</label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className={`loginInput ${
                          loginErrors.password ? "border-error" : ""
                        }`}
                        value={this.state.password}
                        onChange={(e) => this.handleChange(loginErrors, e)}
                      />
                    </div>
                  </div>
                  <p
                    className="text-sm cursor-pointer hover:text-accent mt-4 block"
                    onClick={() => this.switchState(2)}
                  >
                    Passwort vergessen?
                  </p>

                  {loginErrors.message && (
                    <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                      <p>{loginErrors.message}</p>
                    </div>
                  )}

                  <button
                    type="submit"
                    className={`mt-12 outlineBtn ${
                      loading ? "hover:text-primary hover:border-primary" : ""
                    }`}
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <ImSpinner8 className="animate-spin text-xl inline" />
                        <p className="inline ml-5">einloggen...</p>
                      </span>
                    ) : (
                      "Login"
                    )}
                  </button>
                </form>
                <div className="mt-8">
                  <p className="inline">Sie haben noch keinen Account? </p>
                  <Link
                    className="inline underline hover:text-accent transition"
                    to={"/signup"}
                  >
                    Bewerben Sie sich jetzt.
                  </Link>
                </div>
              </div>
              <div id="part2" className={loginPartsContainer}>
                <h1>Passwort vergessen?</h1>
                <p className="mt-8">
                  Falls Sie Ihr Passwort vergessen haben, geben Sie nachfolgend
                  Ihre E-Mail Adresse an. Wir werden Ihnen umgehend eine E-Mail
                  mit einem Link zum Zurücksetzen Ihres Passworts zusenden.
                  Sollten Sie auch Ihre E-Mail Adresse nicht mehr wissen,
                  kontaktieren Sie uns bitte persönlich.
                </p>

                <form noValidate onSubmit={this.handleForgotPasswordSubmit}>
                  <div className="mt-8 flex flex-col gap-8">
                    <div className="input-wrapper">
                      <label htmlFor="email">E-Mail Adresse</label>
                      <input
                        id="forgotPasswordEmail"
                        name="email"
                        type="email"
                        className={`loginInput ${
                          forgotPasswordErrors.forgotPasswordEmail
                            ? "border-error"
                            : ""
                        }`}
                        value={this.state.email}
                        onChange={(e) =>
                          this.handleChange(forgotPasswordErrors, e)
                        }
                      />
                    </div>
                  </div>

                  {forgotPasswordErrors.message && (
                    <div className="bg-error text-white rounded-lg p-6 mt-10 mb-[-1rem]">
                      <p>{forgotPasswordErrors.message}</p>
                    </div>
                  )}

                  <div className="relative">
                    <button
                      type="submit"
                      className={`mt-12 outlineBtn ${
                        forgotPasswordLoading
                          ? "hover:text-primary hover:border-primary"
                          : ""
                      }`}
                      disabled={forgotPasswordLoading}
                    >
                      {forgotPasswordLoading ? (
                        <span>
                          <ImSpinner8 className="animate-spin text-xl inline" />
                          <p className="inline ml-5">Anfrage bearbeiten...</p>
                        </span>
                      ) : (
                        "Passwort zurücksetzen"
                      )}
                    </button>
                    <p
                      className="text-sm cursor-pointer hover:text-accent inline absolute right-0 bottom-0"
                      onClick={() => this.switchState(1)}
                    >
                      zurück
                    </p>
                  </div>
                </form>
              </div>
              <div id="part3" className={loginPartsContainer}>
                <h1>Sie haben Post</h1>
                <p className="mt-8">
                  Wir haben soeben eine E-Mail an {this.state.email} mit einem
                  Link zum Zurücksetzen des Passworts gesendet. Sehen Sie ggf.
                  bitte auch in Ihrem Spam-Ordner nach.
                </p>
                <p
                  className="text-sm cursor-pointer hover:text-accent mt-8 block"
                  onClick={() => this.switchState(1)}
                >
                  zurück
                </p>
              </div>
            </motion.div>
          </div>
          <BackgroundImg />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.adjustLoginContainerHeight();
  }
  componentDidUpdate(prevProps, prevState) {
    this.adjustLoginContainerHeight();
  }
  adjustLoginContainerHeight() {
    const loginContainer = document.getElementById("loginContainer");
    if (loginContainer != null) {
      const part = document.getElementById("part" + this.state.state);
      if (part != null) {
        loginContainer.style.height = part.offsetHeight + "px";
      }
    }
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
