import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import NotificationDialog from "../components/notificationDialog.jsx";

// Ui
import { ImSpinner8 } from "react-icons/im";
import { BiUndo } from "react-icons/bi";

export class Approvals extends Component {
  constructor() {
    super();
    this.state = {
      membershipShown: "all",
      statusShown: "open",
      loadingUsers: true,
      maxUsersPerPage: 10,
      numberOfPages: 1,
      currentPage: 0,
      openApprovals: [],
      loadedDeclinedApprovals: false,
      declinedApprovals: [],
      users: [],
      userIndexesToDisplay: [],
      usersOnPage: [],

      // notification
      notification: {
        show: false,
        heading: "",
        text: "",
        acceptText: "",
        cancelText: "",
        userImg: "",
        onSuccess: () => {},
      },
    };
  }

  componentDidMount() {
    axios
      .get("/admin/approvals")
      .then((res) => {
        this.setState(
          {
            openApprovals: res.data,
          },
          () => {
            console.log(this.state.openApprovals);
            this.updateUserIndexesToDisplay();
            this.setState({ users: this.state.openApprovals });
          },
        );
      })
      .catch((err) => console.log(err));
  }

  render() {
    const filterBtnStyle = "hover:text-accent hover:cursor-pointer";

    // update approvals
    this.updateApprovals = (
      status,
      userId,
      userName,
      userImg,
      membership,
      email,
    ) => {
      const acceptNotification = {
        heading: userName + " wirklich freischalten?",
        text:
          "Möchten Sie " +
          userName +
          " wirklich freischalten? " +
          userName +
          " kann ab dann Mitglied bei Chosen For You werden. \n\nDieser Vorgang kann nicht rückgängig gemacht werden.",
        acceptText: "akzeptieren",
        acceptColor: "green",
      };
      const declineNotification = {
        heading: userName + " wirklich ablehnen?",
        text:
          "Möchten Sie " +
          userName +
          " wirklich ablehnen? " +
          userName +
          " kann dann nicht mehr Mitglied bei Chosen For You werden. Siber-Mitglieder erhalten außerdem eine E-Mail, dass Ihre Bewerbung abgelehnt wurde. \n\nDieser Vorgang kann im Zweifel aber rückgängig gemacht werden.",
        acceptText: "ablehnen",
        acceptColor: "red",
      };
      const undoNotification = {
        heading: "Ablehnung wirklich zurückziehen?",
        text:
          "Möchten Sie die Absage an " +
          userName +
          " wirklich zurückziehen? " +
          userName +
          ' wird anschließend wieder in die "Approvals"-Liste aufgenommen und kann ggf. wieder akzeptiert werden.',
        acceptText: "Ablehnung zurückziehen",
      };
      let notificationText = acceptNotification;
      if (status === "decline") notificationText = declineNotification;
      else if (status === "undoRejection") notificationText = undoNotification;

      this.setState({
        notification: {
          show: true,
          heading: notificationText.heading,
          text: notificationText.text,
          acceptText: notificationText.acceptText,
          cancelText: "abbrechen",
          acceptColor: notificationText.acceptColor,
          userImg: userImg,
          onSuccess: () => {
            this.setState({
              loadingUsers: true,
              notification: { show: false },
            });
            let endpoint;
            if (status === "approve") endpoint = "/admin/approvals/approve";
            else if (status === "decline")
              endpoint = "/admin/approvals/decline";
            else if (status === "undoRejection")
              endpoint = "/admin/approvals/undoRejection";
            axios
              .post(endpoint, {
                userId: userId,
                membership: membership,
                email: email,
              })
              .then((res) => {
                let thisUser;
                let openApprovals = this.state.openApprovals;
                let declinedApprovals = this.state.declinedApprovals;
                let updatedUsers = this.state.users;
                if (status === "approve" || status === "decline") {
                  if (status === "decline") {
                    // add user to declined approvals
                    thisUser = openApprovals.filter((user) => {
                      return user.userId === userId;
                    });
                    declinedApprovals.push(thisUser[0]);
                  }
                  // remove user from open approvals
                  openApprovals = openApprovals.filter((user) => {
                    return user.userId !== userId;
                  });
                  updatedUsers = openApprovals;
                } else if (status === "undoRejection") {
                  // add user to open approvals
                  thisUser = declinedApprovals.filter((user) => {
                    return user.userId === userId;
                  });
                  openApprovals.push(thisUser[0]);
                  // remove user from declined approvals
                  declinedApprovals = declinedApprovals.filter((user) => {
                    return user.userId !== userId;
                  });
                  updatedUsers = declinedApprovals;
                }

                this.setState(
                  {
                    openApprovals: openApprovals,
                    declinedApprovals: declinedApprovals,
                    users: updatedUsers,
                  },
                  () => {
                    this.updateUserIndexesToDisplay();
                  },
                );
              })
              .catch((err) => {
                /*sendError*/
              });
          },
        },
      });
    };

    // change page
    this.changePage = (newPage) => {
      const usersOnPage = this.state.userIndexesToDisplay.slice(
        newPage * this.state.maxUsersPerPage,
        newPage * this.state.maxUsersPerPage + this.state.maxUsersPerPage,
      );
      this.setState({
        currentPage: newPage,
        usersOnPage: usersOnPage,
        loadingUsers: false,
      });
    };

    // change membership filter
    this.changeMembershipFilter = (e) => {
      this.setState({ membershipShown: e.target.getAttribute("value") }, () => {
        this.updateUserIndexesToDisplay();
      });
    };

    // change status filter
    this.changeStatusFilter = (e) => {
      this.setState(
        { statusShown: e.target.getAttribute("value"), loadingUsers: true },
        () => {
          if (this.state.statusShown === "open") {
            this.setState({ users: this.state.openApprovals }, () => {
              this.updateUserIndexesToDisplay();
            });
          } else if (this.state.statusShown === "declined") {
            if (!this.state.loadedDeclinedApprovals) {
              axios
                .get("/admin/approvals/declined")
                .then((res) => {
                  this.setState(
                    {
                      declinedApprovals: res.data,
                      loadedDeclinedApprovals: true,
                    },
                    () => {
                      this.setState(
                        { users: this.state.declinedApprovals },
                        () => {
                          this.updateUserIndexesToDisplay();
                        },
                      );
                    },
                  );
                })
                .catch((err) => console.log(err));
            } else {
              this.setState({ users: this.state.declinedApprovals }, () => {
                this.updateUserIndexesToDisplay();
              });
            }
          }
        },
      );
    };

    // get indexes of users to display
    this.updateUserIndexesToDisplay = () => {
      this.setState({ loadingUsers: true }, () => {
        let userIndexesToDisplay = [];
        this.state.users.forEach((user, index) => {
          if (
            user.membership === this.state.membershipShown ||
            this.state.membershipShown === "all"
          ) {
            userIndexesToDisplay.push(index);
          }
        });
        this.setState(
          {
            userIndexesToDisplay: userIndexesToDisplay,
          },
          () => {
            // get number of pages
            const numberOfPages = Math.ceil(
              this.state.userIndexesToDisplay.length /
                this.state.maxUsersPerPage,
            );
            // set new number of pages and reset current page
            this.setState(
              {
                numberOfPages: numberOfPages,
                currentPage: 0,
              },
              () => {
                this.changePage(0);
              },
            );
          },
        );
      });
    };

    // calculate time since date
    this.timeSince = (date) => {
      const seconds = Math.floor((new Date() - date) / 1000);
      const days = Math.floor(seconds / 86400);
      const hours = Math.floor((seconds % 86400) / 3600);
      return `${days} T, ${hours} Std`;
    };

    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        {this.state.notification.show && (
          <NotificationDialog
            type="userRelated"
            userImg={this.state.notification.userImg}
            heading={this.state.notification.heading}
            text={this.state.notification.text}
            okText={this.state.notification.okText}
            cancelText={this.state.notification.cancelText}
            acceptText={this.state.notification.acceptText}
            acceptColor={this.state.notification.acceptColor}
            onSuccess={this.state.notification.onSuccess}
            onCancel={() =>
              this.setState({
                notification: { show: false },
              })
            }
          />
        )}
        <Navbar />
        <Container>
          <h1 className="text-3xl mb-8">Approvals</h1>
          <div
            id="usersNavigation"
            className="flex flex-col sm:flex-row gap-5 sm:gap-0 relative z-20"
          >
            <div className="w-1/2 flex gap-8">
              <p
                className={`${filterBtnStyle} ${
                  this.state.membershipShown === "all"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeMembershipFilter}
                value="all"
              >
                alle
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.membershipShown === "gold"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeMembershipFilter}
                value="gold"
              >
                gold
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.membershipShown === "silver"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeMembershipFilter}
                value="silver"
              >
                silber
              </p>
            </div>
            <div className="w-1/2 flex gap-8 sm:justify-end">
              <p
                className={`${filterBtnStyle} ${
                  this.state.statusShown === "open"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeStatusFilter}
                value="open"
              >
                offen
              </p>
              <p
                className={`${filterBtnStyle} ${
                  this.state.statusShown === "declined"
                    ? "text-accent"
                    : "text-primary"
                }`}
                onClick={this.changeStatusFilter}
                value="declined"
              >
                abgelehnt
              </p>
            </div>
          </div>
          <div id="usersList" className="mt-24 lg:mt-12">
            {this.state.loadingUsers ? (
              <ImSpinner8 className="animate-spin text-xl mx-auto" />
            ) : (
              <div>
                {this.state.usersOnPage.map((userIndex) => {
                  const user = this.state.users[userIndex];
                  return (
                    <div
                      key={user.userId}
                      className="flex flex-col lg:flex-row relative bg-secondary gap-2 lg:gap-1 lg:h-16 pt-20 pb-5 lg:py-0 lg:pl-28 lg:pr-0 mb-16 lg:mb-5 rounded-2xl lg:rounded-full justify-between items-center"
                    >
                      <img
                        src={user.imageUrl}
                        alt="Profilbild"
                        className="h-24 lg:h-14 rounded-full absolute -top-10 lg:left-1 lg:top-1"
                      />
                      <div className="lg:w-2/12 overflow-x-scroll hide-scrollbar">
                        <p className="whitespace-nowrap font-semibold">
                          <span>{user.firstname}</span>
                          {user.lastname && <span> {user.lastname}</span>}
                        </p>
                      </div>
                      <p
                        className={`uppercase lg:w-1/12 whitespace-nowrap overflow-x-scroll hide-scrollbar ${
                          user.membership === "gold"
                            ? "text-accent"
                            : "text-gray-400"
                        }`}
                      >
                        {user.membership === "gold" && "gold"}
                        {user.membership === "silver" && "silber"}
                      </p>
                      <p className="lg:w-1/12 hidden lg:block whitespace-nowrap overflow-x-scroll hide-scrollbar">
                        {user.gender === "m" && "männlich"}
                        {user.gender === "f" && "weiblich"}
                        {user.gender === "d" && "divers"}
                      </p>
                      <p className="lg:w-1/12 whitespace-nowrap overflow-x-scroll hide-scrollbar">
                        {this.timeSince(new Date(user.waiting))}
                      </p>
                      <Link
                        to={"/admin/" + user.userId}
                        className="lg:w-2/12 whitespace-nowrap overflow-x-scroll hide-scrollbar lg:mb-0 mb-20"
                      >
                        &rarr; zum Profil
                      </Link>
                      {this.state.statusShown === "open" && (
                        <div
                          className="absolute left-0 bottom-0 h-16 w-1/2 lg:w-auto lg:static flex items-center justify-center lg:h-full bg-primary text-white hover:bg-red-500 rounded-bl-2xl lg:rounded-full px-8 hover:cursor-pointer lg:pr-20 transition-colors duration-160"
                          onClick={() => {
                            let userName = user.firstname;
                            if (user.lastname) {
                              userName += " " + user.lastname;
                            }
                            this.updateApprovals(
                              "decline",
                              user.userId,
                              userName,
                              user.imageUrl,
                              user.membership,
                              user.email,
                            );
                          }}
                        >
                          <p>ablehnen</p>
                        </div>
                      )}
                      {this.state.statusShown === "open" && (
                        <div
                          className={`absolute right-0 bottom-0 h-16 w-1/2 lg:w-auto lg:static flex items-center justify-center lg:h-full ${
                            user.membership === "gold"
                              ? "bg-accent"
                              : "bg-gray-500"
                          } text-white hover:bg-green-500 rounded-br-2xl lg:rounded-full px-8 hover:cursor-pointer lg:-ml-24 transition-colors duration-160`}
                          onClick={() => {
                            let userName = user.firstname;
                            if (user.lastname) {
                              userName += " " + user.lastname;
                            }
                            this.updateApprovals(
                              "approve",
                              user.userId,
                              userName,
                              user.imageUrl,
                              user.membership,
                              user.email,
                            );
                          }}
                        >
                          <p>akzeptieren</p>
                        </div>
                      )}
                      {this.state.statusShown === "declined" && (
                        <div
                          className={`absolute right-0 bottom-0 h-16 w-1/2 lg:w-auto lg:static flex items-center justify-center lg:h-full ${
                            user.membership === "gold"
                              ? "bg-accent"
                              : "bg-gray-500"
                          } text-white hover:bg-green-500 rounded-br-2xl lg:rounded-full px-8 hover:cursor-pointer lg:-ml-24 transition-colors duration-160`}
                          onClick={() => {
                            let userName = user.firstname;
                            if (user.lastname) {
                              userName += " " + user.lastname;
                            }
                            this.updateApprovals(
                              "undoRejection",
                              user.userId,
                              userName,
                              user.imageUrl,
                              user.membership,
                              user.email,
                            );
                          }}
                        >
                          <BiUndo className="text-2xl" />
                        </div>
                      )}
                    </div>
                  );
                })}
                {this.state.numberOfPages > 1 && (
                  <div className="flex bg-white mt-20 mb-8 py-4 px-10 rounded-full justify-between">
                    <div>
                      <p>
                        {this.state.currentPage + 1} /{" "}
                        {this.state.numberOfPages}
                      </p>
                    </div>
                    <div className="xs:flex hidden gap-3">
                      {this.state.currentPage > 2 && <span>...</span>}
                      {Array.from(
                        { length: this.state.numberOfPages },
                        (_, i) =>
                          Math.abs(i - this.state.currentPage) <= 2 ? (
                            <p
                              key={i}
                              className={`hover:cursor-pointer ${
                                i === this.state.currentPage
                                  ? "text-accent"
                                  : ""
                              }`}
                              onClick={() => this.changePage(i)}
                            >
                              {i + 1}
                            </p>
                          ) : null,
                      )}
                      {this.state.currentPage + 2 <
                        this.state.numberOfPages - 1 && <span>...</span>}
                    </div>
                    <div className="xs:hidden flex gap-5 text-2xl">
                      {this.state.currentPage > 0 && (
                        <p
                          className="hover:cursor-pointer"
                          onClick={() =>
                            this.changePage(this.state.currentPage - 1)
                          }
                        >
                          &larr;
                        </p>
                      )}
                      {this.state.currentPage <
                        this.state.numberOfPages - 1 && (
                        <p
                          className="hover:cursor-pointer"
                          onClick={() =>
                            this.changePage(this.state.currentPage + 1)
                          }
                        >
                          &rarr;
                        </p>
                      )}
                    </div>
                    <div>
                      <select
                        value={this.state.currentPage}
                        onChange={(e) =>
                          this.changePage(parseInt(e.target.value))
                        }
                      >
                        {Array.from(
                          { length: this.state.numberOfPages },
                          (_, i) => (
                            <option key={i} value={i}>
                              {i + 1}
                            </option>
                          ),
                        )}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Container>
        <BackgroundImg />
      </div>
    );
  }
}

export default Approvals;
