import React, { Component } from "react";
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import ProfilCard from "../components/profileCard.jsx";
import Container from "../components/container.jsx";

export class Profile extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        <Navbar />
        <Container>
          <div className="mobileBreakContainer flex justify-center">
            <div className="max-w-xl relative my-40">
              <ProfilCard />
            </div>
          </div>
        </Container>
        <BackgroundImg fixedPos="true" opacity="0.4" />
      </div>
    );
  }
}

export default Profile;
