import React, { Component } from "react";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import SettingsBox from "../components/settingsBox.jsx";

export class Settings extends Component {
  render() {
    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        <Navbar />
        <Container>
          <SettingsBox />
        </Container>
        <BackgroundImg />
      </div>
    );
  }
}

export default Settings;
