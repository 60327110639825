import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import WelcomeWidget from "../components/widgets/welcomeWidget.jsx";
import ToDoList from "../components/toDoList.jsx";
import GoldMatchesPreviewList from "../components/goldMatchesPreviewList.jsx";
import GoldSentInvitationWidget from "../components/widgets/goldSentInvitationWidget.jsx";
import { GoldInvitationResponses } from "../components/widgets/goldInvitationResponses.jsx";

// Redux
import { connect } from "react-redux";

// Icons
import { IoMailOutline } from "react-icons/io5";
import { ImSpinner8 } from "react-icons/im";

export class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      navigateTo: "",
      paymentLoading: false,
    };
  }

  render() {
    const canReceive = this.props.user?.credentials?.infos?.canReceive;
    const { accStatus } = this.props.user;
    const user = this.props.user;

    const handlePayment = () => {
      this.setState({ paymentLoading: true });
      axios
        .post("/payment")
        .then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          }
        })
        .catch((err) => {
          this.setState({ navigateTo: "/dashboard/notif?error" });
          this.setState({ paymentLoading: false });
        });
    };

    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        {this.state.navigateTo && (
          <Navigate to={this.state.navigateTo} replace={true} />
        )}
        <Navbar />
        <Container>
          <div>
            <WelcomeWidget />
            <div className="mt-14" />

            {(accStatus === "inactive" ||
              accStatus === "expired" ||
              accStatus === "admin") && <ToDoList />}

            {!user.loading &&
              user.credentials.infos.active &&
              user.credentials.gold &&
              0 < user.credentials.gold.matched.length && (
                <GoldInvitationResponses user={user.credentials} />
              )}

            {accStatus === "goldActiveNoMatches" && canReceive === true && (
              <p className="text-xl leading-9 mb-8 max-w-3xl">
                Wir sind derzeit auf der Suche nach den perfekten Matches für
                Sie. Seien Sie gespannt. Sobald wir passende Mitglieder für Sie
                gefunden haben, melden wir uns bei Ihnen.
              </p>
            )}

            {accStatus === "goldActiveMatches" && (
              <div>
                <h1 className="text-3xl mb-8">Ihre Matches:</h1>
                <GoldMatchesPreviewList />
              </div>
            )}

            {accStatus === "goldActiveSentInvitation" && (
              <GoldSentInvitationWidget />
            )}

            {accStatus === "silverActiveNoInvitations" &&
              canReceive === true && (
                <p className="text-xl leading-9 mb-8 max-w-3xl">
                  Wir sind derzeit auf der Suche nach den perfekten Matches für
                  Sie. Seien Sie gespannt. Sobald wir passende Mitglieder für
                  Sie gefunden haben, melden wir uns bei Ihnen.
                </p>
              )}

            {accStatus === "silverActivePaused" && (
              <p className="text-xl leading-9 mb-8 max-w-3xl">
                Ihre Mitgliedschaft ist derzeit pausiert und Sie erhalten keine
                weiteren Date&#8209;Einladugen.
                <br />
                <br />
                Sie können Ihre Mitgliedschaft jederzeit fortsetzen.
              </p>
            )}

            {accStatus === "silverActivePendingInvitations" && (
              <div>
                <h1 className="text-3xl mb-8">Ihre nächsten Schritte:</h1>
                <div className="md:max-w-sm">
                  <Link to="/invitations">
                    <div className="bg-secondary text-center rounded-xl p-10 hover:scale-[1.02] transition-all ease-in-out duration-200">
                      <div className="text-2xl mb-4">
                        <IoMailOutline className="mx-auto" />
                      </div>
                      <p className="mb-4">
                        <b>Neue Date Einladungen</b>
                      </p>
                      <p>
                        Sehen sich sich an, wer Sie zu einem Date eingeladen hat
                        und nehmen Sie es an oder lehnen es ab.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            {!canReceive &&
              accStatus !== "admin" &&
              user.credentials?.infos?.active && (
                <div className="mt-20 bg-secondary p-8 rounded-xl">
                  <h1 className="text-3xl mb-3">Weitere Matches erhalten</h1>
                  <p className="text-xl leading-9 mb-8 max-w-3xl">
                    Sie haben bereits alle Ihre Matches erhalten. Wenn Sie bis
                    zu drei weitere Matches erhalten möchten, können Sie Ihre
                    Mitgliedschaft upgraden.
                  </p>
                  <button
                    className="bg-primary text-white py-3 px-6 hover:bg-accent transition-colors text-xl rounded-md"
                    onClick={(event) => {
                      event.preventDefault();
                      handlePayment();
                    }}
                  >
                    {this.state.paymentLoading ? (
                      <ImSpinner8 className="animate-spin" />
                    ) : (
                      "Jetzt upgraden"
                    )}
                  </button>
                </div>
              )}
          </div>
        </Container>
        <BackgroundImg />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

Dashboard.protoTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Dashboard);
