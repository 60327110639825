import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Components
import BackgroundImg from "../components/backgroundImg.jsx";
import Navbar from "../components/navbar.jsx";
import Container from "../components/container.jsx";
import SilverInvitationsList from "../components/silverInvitationsList.jsx";
import { PauseResumeMembershipBtn } from "../components/minis/pauseResumeMembershipBtn.jsx";

export class Invitations extends Component {
  render() {
    return (
      <div className="min-h-screen" style={{ position: "relative" }}>
        <Navbar />
        <Container>
          <h1 className="mb-7 mt-7 text-3xl">Ihre Date-Einladungen</h1>
          <p
            className={`max-w-4xl text-md ${
              this.props.user.accStatus === "silverActivePaused"
                ? "mb-5"
                : "mb-14"
            }`}
          >
            Hier finden Sie alle Ihre Date-Einladungen. Sie haben pro Einladung
            drei Tage Zeit, diese anzunehmen oder abzulehnen. Danach wird die
            Einladung automatisch abgesagt.
          </p>

          {this.props.user.accStatus === "silverActivePaused" && (
            <div>
              <p className="max-w-4xl text-md mb-10">
                Ihre Mitgliedschaft ist aktuell pausiert. Sie erhalten deshalb
                keine weiteren Date-Einladungen. Wenn Sie möchten, können Sie
                Ihre Mitgliedschaft jedoch jederzeit fortsetzen.
              </p>
              <PauseResumeMembershipBtn resumeMembership className="mb-14" />
            </div>
          )}

          <SilverInvitationsList />
        </Container>
        <BackgroundImg />
      </div>
    );
  }
}

Invitations.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Invitations);
